<template>
  <HeroLoader :empty="!trading" :loading="isLoading" :key="$route.fullPath">
    <div class="area-content" v-if="trading">
      <BasePageHeader shadow type="light" class="wrapper no-space" :headline="trading.name">
        <router-link :to="companyLink" class="promotion__title">
          <CompanyLogo v-if="trading.company?.logo" :src="trading.company?.logo" size="small"/>
        </router-link>
      </BasePageHeader>
      <div class="wrapper">
        <div class="grid">
          <div class="col-12">
            <h4 class="subtitle page-subtitle">Beskrivelse</h4>
            <p v-html="trading.manchet"/>
          </div>
          <div class="col-12 col-lg-8">
            <section class="grid">
              <div class="col-12">
                <Box class="box-padding">
                  <GallerySlider
                    v-if="trading.images.length > 0"
                    :images="trading.images"
                    :items="1"
                    :loop="true"
                    :autoheight="true"
                  />

                  <div class="tradingDescription" v-html="trading.body"></div>

                  <div v-if="trading.links?.readMore" class="mt-2 moreLink">
                    <p>Læs mere ved at gå til linket:</p>
                    <a :href="trading.links.readMore" target="_blank">
                      {{ trading.links.readMore }}
                    </a>
                  </div>

                  <div class="mt-2">
                    <LikeCounter
                      :type="modelType"
                      :id="trading.id"
                      :count="trading.likeCounter"
                      :liked="trading.liked"
                      @liked="onLike"
                    />
                  </div>
                </Box>
              </div>
              <div class="col-12 tradingInfoMobile">
                <TradingSidebarInformation :trading="trading"/>
              </div>
            </section>

            <div class="spacer">
              <h4 class="subtitle">Kommentarer</h4>
              <Box class="box-padding">
                <Comment
                  :commentable-type="modelType"
                  :commentable-id="trading.id"
                  @created="fetchComments"
                />

                <ul class="comments" v-if="comments.length > 0">
                  <Comments
                    v-for="comment in comments"
                    :key="comment.id"
                    :comment="comment"
                    :commentable="trading"
                    :comments="comment.comments"
                    :active-comment-id="activeCommentId"
                    :fetch-comments="fetchComments"
                    :toggle-form="onSetActiveComment"
                    :depth="0"
                    :commentable-type="modelType"
                  />
                </ul>

                <p v-if="comments.length === 0" class="commentsEmpty">
                  Der er endnu ingen kommentarer til denne ressource.
                </p>
              </Box>
            </div>

            <section class="promotion spacer" v-if="trading.company">
              <h4 class="subtitle">Annoncør</h4>
              <div class="grid">
                <div class="col-12 col-lg-7">
                  <Box style="padding: 1.5rem">
                    <router-link :to="companyLink" class="promotion__title">
                      <span>{{ trading.company.name }}</span>
                    </router-link>
                    <p v-if="trading.company.description"
                       v-html="trading.company.description"
                       class="companyDescription promotion__content"
                    ></p>
                  </Box>
                </div>
              </div>
            </section>
          </div>
          <div class="col-12 col-md-4 tradingInfoDesktop">
            <TradingSidebarInformation :trading="trading" sticky/>
          </div>
        </div>
      </div>
    </div>
  </HeroLoader>
</template>

<script>
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import Message from '@/helpers/Message';
import Box from '@/components/Box.vue';
import TradingService from '@/services/TradingService';
import CommentService from '@/services/CommentService';
import TradingSidebarInformation from '@/containers/TradingSidebarInformation.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import useAuth from '@/composables/useAuth';
import Modules from '@/helpers/Modules';
import GallerySlider from '@/components/GallerySlider.vue';
import Comment from '@/views/Comments/Comment.vue';
import Comments from '@/views/Comments/Comments.vue';
import LikeCounter from '@/components/LikeCounter.vue';

export default {
  name: 'CompanyView.vue',
  components: {
    GallerySlider,
    CompanyLogo,
    TradingSidebarInformation,
    Box,
    HeroLoader,
    BasePageHeader,
    Comment,
    Comments,
    LikeCounter
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      trading: null,
      comments: [],
      modelType: 'trading',
      activeCommentId: -1
    };
  },
  computed: {
    showCompanyField() {
      return this.hasSubscription(Modules.COMPANY);
    },
    companyLink() {
      if (!this.showCompanyField) {
        return '';
      }
      return {
        name: 'companies.show',
        params: { id: this.trading?.company?.id }
      };
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const { hasSubscription } = useAuth();

    return {
      startLoading,
      stopLoading,
      isLoading,
      startPageLoading,
      stopPageLoading,
      isLoadingPage,
      hasSubscription
    };
  },
  created() {
    this.startPageLoading();
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    onLike(data) {
      this.trading.likeCounter = data.count;
      this.trading.liked = data.liked;
    },
    initLoad() {
      this.startLoading('Henter annonce');
      this.fetchCompany();
      this.fetchComments();
      this.stopPageLoading();
    },
    onSetActiveComment(id) {
      if (this.activeCommentId === id) {
        this.activeCommentId = -1;
        return;
      }
      this.activeCommentId = id;
    },
    async fetchComments() {
      try {
        const payload = await CommentService.all(this.modelType, this.id);
        this.comments = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    },
    async fetchCompany() {
      try {
        const payload = await TradingService.show({
          id: this.id,
          relations: ['geocode', 'company', 'tradingType', 'images', 'tradingCategory', 'comments']
        });
        this.trading = payload?.data?.data;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
      }
    }
  },
  watch: {
    async $route() {
      this.trading = null;
      await this.$nextTick(async () => {
        await this.initLoad();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
@use "~@/styles/components/_comments.scss";

.tradingDescription {
  line-height: 1.7em;
}

.spacer {
  margin-top: 4rem;

  @include config.xl {
    margin-top: 3.5rem;
  }
}

.moreLink {
  font-size: 0.9em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;

  &::before {
    content: '';
    display: block;
    max-width: 350px;
    width: 100%;
    background: config.$brown;
    height: 2px;
    margin: 10px 0;
  }
}

.promotion {
  &__content {
    margin-top: 1rem;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    font-family: config.$font-headline;
    display: block;
  }

  &__img-wrapper {
    display: none;

    @include config.lg {
      display: block;
    }
  }
}

.tradingInfoMobile {
  @include config.lg {
    display: none;
  }
}

.tradingInfoDesktop {
  display: none;
  @include config.lg {
    display: block;
  }
}

.page-subtitle {
  margin-bottom: 0;
}
</style>
