<template>
  <div class="register">
    <div class="register__wallpaper"></div>
    <a :href="redirectUrl" class="register__readmore" target="_blank">
      Vil du vide mere om Byghåndværk?
    </a>
    <div class="register__box">
      <div class="register__content">
        <div class="register__logo">
          <img src="@/assets/logo.svg" alt=""/>
        </div>

        <div v-if="completed" class="completed">
          <Info>
            <h3>Tak for din anmodning.</h3>
            <br/>
            <p>Du modtager en bekræftelse på e-mail, og vi vender retur hurtigst muligt.</p>
            <br/>
            <p>Du bliver nu viderestillet til <a :href="redirectUrl">Byghåndværk.dk</a></p>
          </Info>
        </div>

        <div v-if="!completed">
          <h2 class="title">Anmod</h2>
          <p class="manchet">
            Bliv en del af den fælles interesse for traditionsbunde håndværksmetoder,
            bygningskultur og bæredygtig byggeskik.
          </p>

          <form class="form" @submit.prevent="onSubmit">
            <BaseColumn>
              <BaseField label="CVR nummer" :help="companyName">
                <BaseInput v-model="company.cvr"
                           type="tel"
                           name="vat3846"
                           placeholder="12345678"
                           icon="marker"
                           required
                           :disabled="isLoadingSubmit"
                           :loading="isLoadingCVR"/>
              </BaseField>
              <BaseField label="Abonnement">
                <BaseSelect v-model="subscriptionId" icon="align-justify">
                  <option v-for="subscription in subscriptions"
                          :key="subscription.id"
                          :value="subscription.id"
                  >
                    {{ subscription.name }}
                  </option>
                </BaseSelect>
              </BaseField>
            </BaseColumn>
            <BaseColumn>
              <BaseField label="Navn på kontaktperson">
                <BaseInput v-model="user.name"
                           type="text"
                           name="name34224"
                           placeholder="Indtast navn"
                           icon="user"
                           requried
                           :disabled="isLoadingSubmit"/>
              </BaseField>
              <BaseField label="Telefon">
                <BaseInput v-model="user.phone"
                           type="tel"
                           name="phone22"
                           placeholder="Indtast nummer"
                           icon="phone"
                           :disabled="isLoadingSubmit"
                           required/>
              </BaseField>
            </BaseColumn>
            <BaseField label="Email">
              <BaseInput v-model="user.email"
                         type="email"
                         name="email22"
                         placeholder="mail@example.com"
                         icon="at"
                         :disabled="isLoadingSubmit"
                         required/>
            </BaseField>

            <BaseField class="terms">
              <BaseCheckbox v-model="acceptSubscription">
                Accepter <a href="https://xn--byghndvrk-82ao.dk/abonnementsvilkaar/"
                            target="_blank"
                            class="link">abonnementsbetingelser</a>
              </BaseCheckbox>
              <BaseCheckbox v-model="acceptPayment" style="margin-top: 8px;">
                Accepter <a href="https://xn--byghndvrk-82ao.dk/handelsbetingelser/"
                            target="_blank"
                            class="link">handelsbetingelser</a>
              </BaseCheckbox>
            </BaseField>

            <BaseButton label="Send anmodning"
                        style="margin-top: 10px;"
                        :disabled="isLoadingSubmit"
                        :loading="isLoadingSubmit"/>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import CVRService from '@/services/CVRService';
import AuthService from '@/services/AuthService';
import Message from '@/helpers/Message';
import Info from '@/components/Info.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import SubscriptionService from '@/services/SubscriptionService';

export default {
  components: {
    BaseSelect,
    BaseCheckbox,
    Info,
    BaseButton,
    BaseField,
    BaseInput,
    BaseColumn
  },
  data() {
    return {
      isLoadingCVR: false,
      isLoadingSubmit: false,
      completed: false,
      redirectUrl: 'https://xn--byghndvrk-82ao.dk/',
      acceptSubscription: false,
      acceptPayment: false,
      subscriptionId: null,
      subscriptions: [],
      company: {
        cvr: null,
        name: '',
        address: '',
        zipcode: '',
        city: ''
      },
      user: {
        name: '',
        phone: '',
        email: ''
      }
    };
  },
  mounted() {
    this.fetchSubscriptions();
  },
  computed: {
    companyName() {
      if (this?.company?.cvr?.length === 8 && this?.company?.name) {
        return `${this.company.name} - ${this.company.address}, ${this.company.zipcode} ${this.company.city}`;
      }
      return '';
    }
  },
  methods: {
    async fetchSubscriptions() {
      try {
        const payload = await SubscriptionService.all();
        this.subscriptions = payload?.data?.data;
        if (this.$route?.query?.type) {
          const subscription = this.subscriptions.find((sub) => sub.name.toLocaleLowerCase() === this.$route?.query?.type.toLocaleLowerCase());
          if (subscription) {
            this.subscriptionId = subscription.id;
          }
        } else {
          this.subscriptionId = this.subscriptions?.length > 0 ? this.subscriptions[0].id : null;
        }
      } catch (e) {
        //
      }
    },
    resetUser() {
      this.user = {
        name: '',
        phone: '',
        email: ''
      };
    },
    resetCompany() {
      this.company = {
        cvr: this.company?.cvr?.toString(),
        name: '',
        address: '',
        zipcode: '',
        city: ''
      };
    },
    async onSearchCVR() {
      this.isLoadingCVR = true;

      try {
        const payload = await CVRService.query(this.company.cvr);
        const company = payload?.data?.data;
        if (company !== null) {
          this.company = {
            cvr: company.cvr?.toString(),
            name: company.name,
            address: company.address,
            city: company.city,
            zipcode: company.zipcode
          };

          if (!this.user.phone) {
            this.user.phone = company.phone;
          }
          if (!this.user.email) {
            this.user.email = company.email;
          }

          this.isLoadingCVR = false;
        }
      } catch (e) {
        Message.httpErrors(e);
        this.isLoadingCVR = false;
      }
    },
    async onSubmit() {
      if (this.acceptSubscription === false) {
        Message.error({ message: 'Abonnementsbetingelser skal accepteres.' });
        return;
      }
      if (this.acceptPayment === false) {
        Message.error({ message: 'Handelsbetingelser skal accepteres.' });
        return;
      }
      this.isLoadingSubmit = true;

      try {
        const payload = await AuthService.signup({
          company: this.company,
          user: this.user,
          subscriptionId: this.subscriptionId
        });
        if (payload?.data?.success) {
          this.completed = true;
          this.company.cvr = '';
          this.resetCompany();
          this.resetUser();

          setTimeout(() => {
            window.location.href = this.redirectUrl;
          }, 20000);
        } else {
          Message.httpSuccess(payload);
        }
        this.isLoadingSubmit = false;
      } catch (e) {
        Message.httpErrors(e);
        this.isLoadingSubmit = false;
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.cvr': function (newValue, oldValue) {
      if (newValue !== oldValue && newValue.length === 8) {
        this.onSearchCVR();
        return;
      }

      this.resetCompany();
    }
  }
};
</script>

<style lang="scss">
.terms {
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

$topHeight: 60px;
$edgePadding: 25px;

.link {
  text-decoration: underline;
  color: config.$brown-dark;
}

.title {
  text-align: center;
  margin-bottom: 10px;
}

.manchet {
  margin-bottom: 25px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.completed {
  margin-top: 50px;
}

.register {
  display: flex;
  position: relative;
  min-height: var(--app-height);
  background-image: url('../../assets/register-image.png');
  background-position: 100%;
  background-size: cover;

  @include config.md {
    background: none;
  }

  &__wallpaper {
    display: none;
    object-fit: cover;
    width: 100vw;
    height: var(--app-height);
    background-image: url('../../assets/register-image.png');
    background-position: 100%;
    background-size: cover;

    @include config.md {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 50vw;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $topHeight;
        background: config.$green-dark;
        content: '';
        opacity: 0.5;
      }
    }
  }

  &__readmore {
    position: fixed;
    top: 0;
    right: 0;
    background: #397050;
    height: $topHeight;
    width: 100%;
    color: config.$white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @include config.lg {
      width: 50%;
      justify-content: flex-end;
      padding-right: 30px;
    }
  }

  &__box {
    display: flex;
    margin-top: calc(#{$topHeight} + 20px);
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    align-items: flex-start;

    @include config.sm {
      margin-top: calc(#{$topHeight} + 50px);
    }

    @include config.md {
      align-items: center;
      margin-left: 50vw;
      overflow: auto;
    }
  }

  &__content {
    background: #fff;
    padding: 25px;
    width: 90vw;

    @media screen and (min-height: 700px) {
      max-height: 80vh;
    }

    @include config.md {
      max-width: 625px;
    }
  }

  &__logo {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;

      @include config.sm {
        max-width: 450px;
      }
    }
  }
}
</style>
