export default {
  tradingTypes() {
    return window.axios.get('/api/trading-types');
  },
  tradingCategories() {
    return window.axios.get('/api/trading-categories');
  },
  all({
    q = '',
    relations = [],
    regions = [],
    types = [],
    categories = [],
    page = 1,
    perPage = 21
  }) {
    let query = {
      page: page.toString(),
      per_page: perPage.toString()
    };
    if (q) {
      query = { ...query, q };
    }
    if (Array.isArray(relations) && relations.length > 0) {
      query = { ...query, relations: relations.join(',') };
    }
    if (Array.isArray(types) && types.length > 0) {
      query = { ...query, types: types.join(',') };
    }
    if (Array.isArray(categories) && categories.length > 0) {
      query = { ...query, categories: categories.join(',') };
    }
    if (Array.isArray(regions) && regions.length > 0) {
      query = { ...query, regions: regions.join(',') };
    }

    const queryFormatted = new URLSearchParams(query);

    return window.axios.get(`/api/trading?${queryFormatted.toString()}`);
  },
  show({
    id,
    relations = []
  }) {
    let query = {};
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);
    return window.axios.get(`/api/trading/${id}?${queryFormatted.toString()}`);
  }
};
