export default {
  update(companyId, {
    website,
    address,
    zipcode,
    phone,
    city,
    description,
    email
  }) {
    return window.axios.put(`/api/admin/company/${companyId}`, {
      website,
      address,
      zipcode,
      phone,
      city,
      description,
      email
    });
  },
  uploadLogo(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  removeLogo(companyId) {
    return window.axios.delete(`/api/admin/company/${companyId}/logo`);
  }
};
