<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Udbud">
        <BaseButton @click="showForm = true" v-if="!showForm">Opret nyt udbud</BaseButton>
        <a href="#" @click.prevent="goBackToListView" v-if="showForm">Tilbage til visningen</a>
      </BasePageHeader>

      <AdminTaskForm v-if="showForm" @created="onCreated" @updated="onUpdated" :edit-id="selectedTaskId"/>

      <BaseSection css="" headline="Udbud (sorteret efter svarfrist)" style="margin-top: 3rem;" v-if="!showForm">
        <div class="grid" v-if="tasks.length > 0">
          <TaskCard v-for="task in tasks"
                    :key="task.id"
                    :task="task"
                    class="col-12 col-sm-6 col-lg-4"
          >
            <div style="display: flex; gap: 1rem;">
              <BaseButton type="info" @click.stop="onEdit(task)">
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </BaseButton>
              <BaseButton type="danger" @click.stop="onDelete(task)">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </BaseButton>
            </div>
          </TaskCard>
        </div>
        <p v-else style="font-size: 0.9em">
          Der er ikke oprettet nogen udbud.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
import BaseSection from '@/components/BaseSection.vue';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import AdminCompanyTaskService from '@/services/Admin/AdminCompanyTaskService';
import HeroLoader from '@/components/HeroLoader.vue';
import AdminTaskForm from '@/views/Admin/components/AdminTaskForm.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import TaskCard from '@/containers/TaskCard.vue';

export default {
  components: {
    TaskCard,
    BaseButton,
    BasePageHeader,
    AdminTaskForm,
    HeroLoader,
    BaseSection
  },
  data() {
    return {
      tasks: [],
      showForm: false,
      selectedTaskId: null
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyTasks();
    this.stopPageLoading();
  },
  methods: {
    onEdit(task) {
      this.selectedTaskId = task.id;
      this.showForm = true;
    },
    async onDelete(task) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Vil du slette dette udbud?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter udbud');

      try {
        const payload = await AdminCompanyTaskService.destroy(this.company.id, task.id);
        await this.loadCompanyTasks();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreated() {
      this.tasks = [];
      this.showForm = false;
      await this.loadCompanyTasks();
    },
    async onUpdated() {
      this.tasks = [];
      this.showForm = false;
      await this.loadCompanyTasks();
    },
    goBackToListView() {
      this.showForm = false;
      this.selectedTaskId = null;
    },
    async loadCompanyTasks() {
      Message.clear();
      this.startLoading('Henter udbud');

      try {
        const payload = await AdminCompanyTaskService.all(this.company.id);
        this.tasks = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

</style>
