<template>
  <editor
    class="editor"
    initialValue=""
    api-key="eihysoi2bu1bupx8h49yibsxfpz6y8uysmllxjap1qax9pji"
    :init="init"
    v-model="modelState"
  >
  </editor>
</template>

<script>
/* eslint-disable */
import Editor from '@tinymce/tinymce-vue';

export default {
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false
    },
    advanced: Boolean
  },
  components: {
    editor: Editor
  },
  computed: {
    init() {
      return {
        height: 500,
        menubar: false,
        plugins: this.plugins,
        language: 'da',
        toolbar: this.toolbar,
        contextmenu: 'link image imagetools table',
      };
    },
    plugins() {
      return [
        'preview', 'importcss', 'searchreplace', 'autolink', 'autosave', 'save', 'directionality', 'code',
        'visualblocks', 'visualchars', 'fullscreen', 'image', 'link', 'media', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking', 'anchor', 'insertdatetime',
        'advlist', 'lists', 'wordcount', 'charmap', 'emoticons'
      ]
        .join(' ');
    },
    toolbar() {
      const t = [
        'undo redo',
        'bold underline',
        'blocks',
        'alignleft aligncenter alignright',
        'outdent indent',
        'numlist bullist removeformat',
      ];

      if (this.advanced === true) {
        t.push('charmap emoticons');
        t.push('fullscreen');
        t.push('insertfile image media link anchor');
      }

      return t.join(' | ');
    },
    modelState: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(`update:modelValue`, value);
      }
    }
  }
};
</script>

<style lang="scss">
.editor {
  + div[role="application"] {
    border-radius: 10px !important;
  }
}
</style>
