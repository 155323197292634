import DashboardLayout from '@/layouts/DashboardLayout.vue';
import Modules from '@/helpers/Modules';
import Trading from '@/views/Trading/Trading.vue';
import TradingView from '@/views/Trading/TradingView.vue';

export default [
  {
    path: '/ressourcer',
    name: 'trading',
    component: Trading,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.TRADING
    }
  },
  {
    path: '/ressourcer/:id',
    name: 'trading.show',
    component: TradingView,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.TRADING
    },
    props: true
  }
];
