<template>
  <div class="control" :class="{'has-left-icon': hasLeftIcon, 'has-right-icon': hasRightIcon}">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    hasLeftIcon: {
      type: Boolean,
      default: false
    },
    hasRightIcon: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.control {
  position: relative;
}
</style>
