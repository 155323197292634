<template>
  <BoxWithImage class="company" hover :label="company.name" :src="primaryImage || ''"
                :sticker="primaryIndustry" :link="link" stickerTitle>
    <div class="grid">
      <IconAndLabel icon="map-marker"
                    class="col-12 col-sm-6 col-md-12 col-xl-6"
                    :label="company.location.full_html"
      />
      <div class="col-12 col-sm-6 col-md-12 col-xl-6">
        <IconAndLabel icon="phone" v-if="company.contact.phone">
          <p>
            <a :href="`tel:${company.contact.phone}`">{{ company.contact.phone }}</a>
          </p>
        </IconAndLabel>
        <IconAndLabel icon="at" v-if="company.contact.email">
          <p>
            <a :href="`mailto:${company.contact.email}`" :title="company.contact.email">Send mail</a>
          </p>
        </IconAndLabel>
      </div>
    </div>
  </BoxWithImage>
</template>

<script>
import BoxWithImage from '@/containers/BoxWithImage.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';

export default {
  components: {
    IconAndLabel,
    BoxWithImage
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return {
        name: 'companies.show',
        params: {
          id: this.company?.id
        }
      };
    },
    primaryImage() {
      if (this.company?.images?.length > 0) {
        return this.company.images[0].path;
      }
      return null;
    },
    primaryIndustry() {
      const hasIndustires = this.company.industries.length > 0;
      if (!hasIndustires) {
        return null;
      }

      const primary = this.company.industries.find((i) => i.primary === true);
      if (primary) {
        return primary.name;
      }

      return this.company.industries[0].name;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.company {
  position: relative;
  font-size: 16px;
}
</style>
