import DashboardLayout from '@/layouts/DashboardLayout.vue';
import Admin from '@/views/Admin/Admin.vue';
import AdminCompany from '@/views/Admin/AdminCompany.vue';
import Modules from '@/helpers/Modules';
import AdminCompanyImages from '@/views/Admin/AdminCompanyImages.vue';
import AdminCompanySkills from '@/views/Admin/AdminCompanySkills.vue';
import AdminCompanyIndustry from '@/views/Admin/AdminCompanyIndustry.vue';
import AdminCompanyProjects from '@/views/Admin/AdminCompanyProjects.vue';
import AdminCompanyEvents from '@/views/Admin/AdminCompanyEvents.vue';
import AdminProfile from '@/views/Admin/AdminProfile.vue';
import AdminCompanyTasks from '@/views/Admin/AdminCompanyTasks.vue';
import AdminCompanyBlog from '@/views/Admin/AdminCompanyBlog.vue';
import AdminUsers from '@/views/Admin/AdminUsers.vue';
import AdminCompanyTrading from '@/views/Admin/Trading/AdminCompanyTrading.vue';

export default [
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requireAuth: true,
      layout: DashboardLayout
    },
    children: [
      {
        path: '',
        name: 'admin.company',
        component: AdminCompany,
        meta: {
          module: Modules.COMPANY
        }
      },
      {
        path: 'sikkerhed',
        name: 'admin.profile',
        component: AdminProfile
      },
      {
        path: 'virksomhedsbilleder',
        name: 'admin.companyImages',
        component: AdminCompanyImages,
        meta: {
          module: Modules.COMPANY
        }
      },
      {
        path: 'kompetencer',
        name: 'admin.skills',
        component: AdminCompanySkills,
        meta: {
          module: Modules.COMPANY
        }
      },
      {
        path: 'cases',
        name: 'admin.cases',
        component: AdminCompanyProjects,
        meta: {
          module: Modules.COMPANY
        }
      },
      {
        path: 'branche',
        name: 'admin.industries',
        component: AdminCompanyIndustry,
        meta: {
          module: Modules.COMPANY
        }
      },
      {
        path: 'events',
        name: 'admin.events',
        component: AdminCompanyEvents,
        meta: {
          module: Modules.EVENT
        }
      },
      {
        path: 'udbud',
        name: 'admin.tasks',
        component: AdminCompanyTasks,
        meta: {
          module: Modules.TASK
        }
      },
      {
        path: 'artikler',
        name: 'admin.blog',
        component: AdminCompanyBlog,
        meta: {
          module: Modules.BLOG
        }
      },
      {
        path: 'brugere',
        name: 'admin.users',
        component: AdminUsers,
        meta: {
          module: Modules.USER
        }
      },
      {
        path: 'ressourcer',
        name: 'admin.trading',
        component: AdminCompanyTrading,
        meta: {
          module: Modules.TRADING
        }
      }
    ]
  }
];
