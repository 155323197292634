<template>
  <Box class="activity" @click="onClick">
    <div class="image">
      <img v-if="activity.image" :src="activity.image" alt="" />
    </div>

    <div class="created">
      {{ activity.createdAt }}
    </div>

    <div class="content">
      <span class="headline">{{ activity.name }}</span>
      <span class="owner">{{ activity.causedBy }}</span>

      <div class="type">
        {{ type.name }}
        <font-awesome-icon :icon="type.icon"/>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from '@/components/Box.vue';

export default {
  components: {
    Box
  },
  data() {
    return {
      types: {
        article: {
          name: 'Artikler',
          icon: 'bullhorn',
          route: 'artikler'
        },
        event: {
          name: 'Event',
          icon: 'calendar-alt',
          route: 'events'
        },
        'forum-post': {
          name: 'Debat',
          icon: 'comments',
          route: 'forum'
        },
        project: {
          name: 'Case',
          icon: 'user-tie',
          route: 'virksomheder'
        },
        task: {
          name: 'Udbud',
          icon: 'bullseye',
          route: 'udbud'
        },
        trading: {
          name: 'Ressourcer',
          icon: 'handshake',
          route: 'ressourcer'
        }
      }
    };
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    type() {
      return this.types[this.activity.subjectType];
    }
  },
  methods: {
    onClick() {
      switch (this.activity.subjectType) {
        case 'article':
        case 'event':
        case 'task':
        case 'forum-post':
        case 'trading':
          this.$router.push(`/${this.type.route}/${this.activity.subjectId}`);
          break;
        case 'project':
          this.$router.push(`/${this.type.route}/${this.activity.companyId}`);
          break;
        default:
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
.activity {
  display: flex;
  position: relative;
  width: 100%;
  height: 200px;
  cursor: pointer;

  @include config.sm {
    width: 600px;
  }
}
.created {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  background-color: #BE6143;
  color: #fff;
  font-size: 0.7em;
  padding: 0.3em 1em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @include config.sm {
    font-size: 0.8em;
  }
}
.image {
  position: relative;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #CFD2CF;
}
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0%;
  padding: 0rem 1rem;
  gap: 0.5rem;
}
.headline {
  font-weight: 600;
  font-size: 0.9em;
  font-family: "Exo 2", sans-serif;
  text-decoration: none;
  color: inherit;
  word-break: break-word;

  @include config.sm {
    font-size: 1.2em;
  }
}
.owner {
  font-size: 0.7em;
  font-family: "Exo 2", sans-serif;
  text-decoration: none;
  color: inherit;
  word-break: break-word;

  @include config.sm {
    font-size: 0.9em;
  }
}
.type {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: fit-content;
  z-index: 1;
  background-color: #397050;
  color: #fff;
  font-size: 0.7em;
  padding: 0.3em 1em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include config.sm {
    font-size: 0.8em;
  }
}

</style>
