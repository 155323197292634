<template>
  <BoxWithImage
    class="article"
    :label="article.title"
    :src="primaryImage"
    :sticker="sticker"
    :link="link"
    @click="$router.push(link)"
    :highlighted="highlighted"
    nullable-image
    hover
  >
    <router-link :to="link" class="grid">
      <div class="col-12">
        <p>{{ article?.manchet }}</p>
      </div>
    </router-link>
    <slot/>
    <div class="card-footer">
      <hr :class="[{'not-large': highlighted}]" />
      <div :class="['interactions', {'interactions-highlighted': highlighted}]">
        <LikeCounter
          type="article"
          :id="article.id"
          :liked="article.liked"
          :count="article.likeCounter"
          @liked="onLike"
        />
        <CommentCount :count="article.totalComments" />
      </div>
    </div>
  </BoxWithImage>
</template>

<script>
import BoxWithImage from '@/containers/BoxWithImage.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  emits: ['liked'],
  components: {
    BoxWithImage,
    LikeCounter,
    CommentCount
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    highlighted: Boolean
  },
  computed: {
    sticker() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      if (this.article.isApproved) {
        return new Date(this.article?.meta?.publishedDisplay).toLocaleString('da-DK', options);
      }
      return `${new Date(this.article?.meta?.publishedDisplay).toLocaleString('da-DK', options)} - Afventer godkendelse`;
    },
    primaryImage() {
      const images = this.article?.images;
      if (images?.length > 0) {
        return images[0].path;
      }
      return null;
    },
    link() {
      return {
        name: 'blog.show',
        params: {
          id: this.article?.id
        }
      };
    }
  },
  methods: {
    onLike(data) {
      this.$emit('liked', data);
    }
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.article {
  position: relative;
  font-size: 16px;
  height: 100%;
}

.boxWithImage__sticker {
  font-weight: bold;
}
.card-footer {
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
hr {
  background-color: #CFD2CF;
  border: none;
  height: 1px;
  margin: 0.75rem 0;
  box-shadow: none;
}
.not-large {
  @include config.lg {
    display: none;
  }
}
.interactions {
  display: flex;
  justify-content: space-between;

  &-highlighted {
    @include config.lg {
      margin-top: 2rem;
      gap: 1rem;
      justify-content: flex-start;
    }
  }
}
</style>
