<template>
  <div :class="{ sticky }">
    <Box headline="Information">
      <GoogleMap :center="task.location?.geocode?.position" :locations="[{ item: null, location: task.location?.geocode?.position }]" style="height: 200px;"/>
      <div class="contact">
        <div class="grid">
          <div class="col-12 col-sm-6">
            <h4 class="contact__title">Svarfrist for ansøgning</h4>
            <IconAndLabel icon="calendar-alt" :label="task.meta.deadlineDisplay"/>
          </div>
          <div class="col-12 col-sm-6">
            <h4 class="contact__title">Publiceret</h4>
            <IconAndLabel icon="calendar-alt" :label="task.meta.publishedDisplay"/>
          </div>
        </div>
      </div>
      <div class="contact">
        <h4 class="contact__title">Lokation</h4>
        <div class="grid">
          <IconAndLabel icon="map-marker"
                        class="col-12 col-sm-6 col-xl-6"
                        :label="task.location.full_html"
          />
          <div class="col-12 col-sm-6 col-xl-6">
            <IconAndLabel icon="phone" v-if="task?.company?.contact.phone">
              <p>
                <a :href="`tel:${task.company.contact.phone}`">{{ task.company.contact.phone }}</a>
              </p>
            </IconAndLabel>
            <IconAndLabel icon="at" v-if="task?.company?.contact.email">
              <p>
                <a :href="`mailto:${task.company.contact.email}`"
                   :title="task.company.contact.email">Send
                  mail</a>
              </p>
            </IconAndLabel>
          </div>
        </div>
      </div>
      <div class="contact" v-if="task.contactDescription.html">
        <h4 class="contact__title">Kontaktoplysninger</h4>
        <div v-html="task.contactDescription.html"></div>
      </div>
      <div class="contact" v-if="task?.industries">
        <h4 class="contact__title">Brancher</h4>
        <IndustryTags :industries="task?.industries">
          <IndustryTag v-if="task?.type?.name" :name="task?.type?.name" />
        </IndustryTags>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import IndustryTags from '@/components/IndustryTags.vue';
import IndustryTag from '@/components/IndustryTag.vue';

export default {
  components: {
    IndustryTag,
    IndustryTags,
    IconAndLabel,
    GoogleMap,
    Box
  },
  props: {
    task: Object,
    sticky: Boolean
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.sticky {
  position: sticky;
  top: 20px;
}

.button {
  margin-top: 1rem;
}

.contact {
  margin: 1.5em;

  &__title {
    padding-bottom: 0.7em;
    font-weight: 500;
  }
}
</style>
