<template>
  <HeroLoader :empty="!task" :loading="isLoading" :key="$route.fullPath">
    <div class="area-content" v-if="task">
      <BasePageHeader shadow type="light" class="wrapper no-space" :headline="task.title">
        <router-link :to="companyLink" class="promotion__title">
          <CompanyLogo v-if="task?.company?.logo" :src="task?.company?.logo" size="small"/>
        </router-link>
      </BasePageHeader>
      <div class="wrapper">
        <div class="grid">
          <div class="col-12">
            <h4 class="subtitle page-subtitle">Beskrivelse</h4>
          </div>
          <div class="col-12 col-lg-8">
            <section class="grid">
              <div class="col-12">
                <Box style="padding: 3rem">
                  <div class="taskDescription" v-html="task.description.html"></div>

                  <div v-if="task.links.more" class="mt-2 moreLink">
                    <p>Læs mere om følgende udbud ved at gå til linket:</p>
                    <a :href="task.links.more" target="_blank">{{ task.links.more }}</a>
                  </div>
                </Box>
              </div>
              <div class="col-12">
                <section class="grid">
                  <div :class="[task.images.length > 1 ? 'col-6' : 'col-12']" v-for="image in task.images" :key="image.id">
                    <Box>
                      <BaseImage :src="image.image"/>
                    </Box>
                  </div>
                </section>
              </div>
              <div class="col-12 taskInfoMobile">
                <TaskInformation :task="task"/>
              </div>
            </section>

            <section class="promotion spacer" v-if="task?.company">
              <h4 class="subtitle">Udbyder</h4>
              <div class="grid">
                <div class="col-12">
                  <Box style="padding: 1.5rem">
                    <router-link :to="companyLink" class="promotion__title">
                      <span>{{ task.company.name }}</span>
                    </router-link>
                    <p v-if="task.company.description"
                       v-html="task.company.description"
                       class="companyDescription promotion__content"
                    ></p>
                  </Box>
                </div>
              </div>
            </section>

            <section class="spacer" v-if="filteredRelatedTasks.length > 0">
              <h4 class="subtitle">Andre udbud du kunne være interesseret i</h4>
              <div class="grid">
                <TaskCard v-for="task in filteredRelatedTasks"
                          :key="task.id"
                          :task="task"
                          class="col-12 col-sm-6"
                />
              </div>
            </section>
          </div>
          <div class="col-12 col-md-4 taskInfoDesktop">
            <TaskInformation :task="task" sticky/>
          </div>
        </div>
      </div>
    </div>
  </HeroLoader>
</template>

<script>
/* eslint-disable */
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import Message from '@/helpers/Message';
import CompanyContactCard from '@/containers/CompanyContactCard.vue';
import InfoBox from '@/components/InfoBox.vue';
import Box from '@/components/Box.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import EventCard from '@/components/EventCard.vue';
import TaskService from '@/services/TaskService';
import BaseButton from '@/components/BaseButton.vue';
import TaskInformation from '@/containers/TaskInformation.vue';
import BaseSection from '@/components/BaseSection.vue';
import BaseImage from '@/components/BaseImage.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import useAuth from '@/composables/useAuth';
import Modules from '@/helpers/Modules';
import TaskCard from '@/containers/TaskCard';

export default {
  name: 'CompanyView.vue',
  components: {
    TaskCard,
    CompanyLogo,
    BaseImage,
    BaseSection,
    TaskInformation,
    BaseButton,
    EventCard,
    IconAndLabel,
    GoogleMap,
    Box,
    InfoBox,
    CompanyContactCard,
    HeroLoader,
    BasePageHeader
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      task: null,
      relatedTasks: []
    };
  },
  computed: {
    showCompanyField() {
      return this.hasSubscription(Modules.COMPANY);
    },
    companyLink() {
      if (!this.showCompanyField) {
        return '';
      }
      return {
        name: 'companies.show',
        params: { id: this.task?.company?.id }
      };
    },
    filteredRelatedTasks() {
      return this.relatedTasks.filter((task) => task.id !== this.task.id);
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const { hasSubscription } = useAuth();

    return {
      startLoading,
      stopLoading,
      isLoading,
      startPageLoading,
      stopPageLoading,
      isLoadingPage,
      hasSubscription
    };
  },
  created() {
    this.startPageLoading();
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.startLoading('Henter task');
      this.fetchCompany();
      this.fetchRelatedEvents();
      this.stopPageLoading();
    },
    async fetchRelatedEvents() {
      try {
        const payload = await TaskService.all({
          page: 1,
          perPage: 4
        });
        this.relatedTasks = payload?.data?.data;
      } catch (e) {
        //
      }
    },
    async fetchCompany() {
      try {
        const payload = await TaskService.show({
          id: this.id,
          relations: ['geocode', 'company', 'industries', 'taskType', 'images']
        });
        this.task = payload?.data?.data;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
      }
    }
  },
  watch: {
    async $route() {
      this.task = null;
      await this.$nextTick(async () => {
        await this.initLoad();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.taskDescription {
  line-height: 1.7em;
}

.spacer {
  margin-top: 4rem;

  @include config.xl {
    margin-top: 3.5rem;
  }
}

.moreLink {
  font-size: 0.9em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;

  &::before {
    content: '';
    display: block;
    max-width: 350px;
    width: 100%;
    background: config.$brown;
    height: 2px;
    margin: 10px 0;
  }
}

.promotion {
  &__content {
    margin-top: 1rem;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    font-family: config.$font-headline;
    display: block;
  }

  &__img-wrapper {
    display: none;

    @include config.lg {
      display: block;
    }
  }
}

.taskInfoMobile {
  @include config.lg {
    display: none;
  }
}

.taskInfoDesktop {
  display: none;
  @include config.lg {
    display: block;
  }
}

.page-subtitle {
  margin-bottom: 0;
}
</style>
