import { computed, ref } from 'vue';
import AuthService from '@/services/AuthService';
import Message from '@/helpers/Message';

const state = ref({
  loading: false,
  user: {}
});

export default function useAuth() {
  const isAuthenticated = computed(() => Object.keys(state.value.user).length > 0);
  const user = computed(() => {
    if (!isAuthenticated.value) {
      return null;
    }
    return state.value.user;
  });

  function startLoading() {
    state.value.loading = true;
  }

  function stopLoading() {
    state.value.loading = false;
  }

  function hasSubscription(key) {
    return user.value?.subscriptions[key] === true;
  }

  async function sendResetPasswordLink({ email }) {
    Message.clear();
    try {
      startLoading();
      const payload = await AuthService.forgotPassword({ email });
      Message.httpSuccess(payload);
      stopLoading();
      return payload?.data?.success === true;
    } catch (e) {
      Message.httpError(e);
      stopLoading();
      return false;
    }
  }

  async function resetPassword({
    email,
    token,
    password,
    passwordConfirmation
  }) {
    Message.clear();
    try {
      startLoading();
      const payload = await AuthService.resetPassword({
        email,
        token,
        password,
        passwordConfirmation
      });
      Message.httpSuccess(payload);
      stopLoading();
      return payload?.data?.success === true;
    } catch (e) {
      Message.httpError(e);
      stopLoading();
      return false;
    }
  }

  async function logout() {
    Message.clear();
    startLoading();
    try {
      const payload = await AuthService.logout();
      state.value.user = {};
      stopLoading();
      return payload?.data?.success === true;
    } catch (e) {
      Message.httpError(e);
      stopLoading();
      return false;
    }
  }

  async function self({ silent = false }) {
    Message.clear();
    try {
      const payload = await AuthService.self();
      state.value.user = payload?.data?.data;
      return payload?.data?.data;
    } catch (e) {
      if (!silent) {
        Message.httpError(e);
      }
      return e;
    }
  }

  async function login({
    email,
    password
  }) {
    Message.clear();
    startLoading();
    try {
      const payload = await AuthService.login({
        email,
        password
      });
      await self({});
      stopLoading();
      return payload?.data?.success === true;
    } catch (e) {
      Message.httpErrors(e);
      stopLoading();
      return false;
    }
  }

  return {
    login,
    sendResetPasswordLink,
    resetPassword,
    logout,
    self,
    loading: computed(() => state.value.loading),
    isAuthenticated,
    user,
    isCompanyAdmin: computed(() => state.value.user?.isCompanyAdmin === true),
    company: computed(() => state.value.user?.company),
    hasSubscription,
    maxPinnedProjects: 3
  };
}
