<template>
  <div class="fileUpload">
    <div v-bind="getRootProps()" class="fileUpload__container">
      <input v-bind="getInputProps()"/>
      <p v-if="isDragActive">Placer filerne her ...</p>
      <div v-else>
        <p class="fileUpload__title">Træk og slip dine filer her</p>
        <p class="fileUpload__subtitle">...eller klik for at vælge</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone';
import { ref } from 'vue';
import Message from '@/helpers/Message';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 1000000
    },
    accept: {
      type: [String, Array],
      default: 'image/*'
    }
  },
  emits: ['upload'],
  setup(props, { emit }) {
    const uploadedFiles = ref([]);
    const saveFiles = async (files) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files[]', file);
      });

      emit('upload', formData);
    };

    function onDrop(acceptFiles, rejectReasons) {
      if (rejectReasons.length > 0) {
        rejectReasons.forEach((error) => {
          error.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              Message.error({ message: `Filen er for stor. Max filstørrelse er ${props.maxSize / 1000000} MB` }, false);
            } else {
              Message.error({ message: err.message });
            }
          });
        });
      } else {
        saveFiles(acceptFiles); // saveFiles as callback
      }
    }

    const {
      getRootProps,
      getInputProps,
      ...rest
    } = useDropzone({
      onDrop,
      accept: props.accept,
      // maxSize: props.maxSize,
      multiple: props.multiple
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
      uploadedFiles
    };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.fileUpload {
  &__container {
    background: config.$white;
    border: 2px dotted config.$brown-dark;
    border-radius: config.$field-border;
    padding: 10px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__title, &__subtitle {
    font-family: config.$font-headline;
    text-align: center;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 16px;
  }
}
</style>
