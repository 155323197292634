import Tasks from '@/views/Tasks/Tasks.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import TaskView from '@/views/Tasks/TaskView.vue';
import Modules from '@/helpers/Modules';

export default [
  {
    path: '/udbud',
    name: 'tasks',
    component: Tasks,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.TASK
    }
  },
  {
    path: '/udbud/:id',
    name: 'tasks.show',
    component: TaskView,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.TASK
    },
    props: true
  }
];
