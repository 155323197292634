<template>
  <BoxWithImage class="task" hover :label="task.title" :src="src"
                :sticker="task?.type?.name" :link="link">
    <div class="grid">
      <IconAndLabel icon="map-marker"
                    class="col-12 col-sm-6 col-md-12 col-xl-6"
                    :label="task.location.full_html"
      />
      <div class="col-12 col-sm-6 col-md-12 col-xl-6">
        <IconAndLabel icon="calendar-alt" v-if="task?.meta?.publishedDisplay">
          <p>
            Publiceret: {{ task?.meta?.publishedDisplay }}
          </p>
        </IconAndLabel>
        <IconAndLabel icon="calendar-alt" v-if="task?.meta?.deadlineDisplay">
          <p>
            Svarfrist: {{ task?.meta?.deadlineDisplay }}
          </p>
        </IconAndLabel>
      </div>
    </div>
    <slot/>
  </BoxWithImage>
</template>

<script>
import BoxWithImage from '@/containers/BoxWithImage.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';

export default {
  components: {
    IconAndLabel,
    BoxWithImage
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return {
        name: 'tasks.show',
        params: {
          id: this.task?.id
        }
      };
    },
    src() {
      return this.task?.images?.length > 0 ? this.task.images[0].image : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.task {
  position: relative;
  font-size: 16px;
}
</style>
