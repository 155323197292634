import { serialize } from 'object-to-formdata';

export default {
  fetchAllTaskTypes() {
    return window.axios.get('/api/public/task-types');
  },
  fetchAllIndustries() {
    return window.axios.get('/api/public/industries');
  },
  taskShow(token) {
    return window.axios.get(`/api/public/tasks/${token}`);
  },
  taskStore(data, images) {
    const payload = serialize(data);

    images.forEach((image) => payload.append('images[]', image.file, image.filename));

    return window.axios.post('/api/public/tasks', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  taskUpdate(token, data, images) {
    const payload = serialize(data);

    images.forEach((image) => payload.append('images[]', image.file, image.filename));

    return window.axios.post(`/api/public/tasks/${token}?_method=PUT`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
