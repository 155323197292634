// eslint-disable-next-line no-unused-vars
import { computed, ref } from 'vue';
// eslint-disable-next-line no-unused-vars
import { onBeforeRouteLeave } from 'vue-router';

const state = ref({
  loading: false,
  message: ''
});

export default function useGlobalLoader() {
  const stateInitialPage = ref({
    loading: false
  });

  function startLoading(loadingMessage = '') {
    state.value.loading = true;
    state.value.message = loadingMessage;
  }

  function stopLoading() {
    state.value.loading = false;
  }

  function startPageLoading() {
    stateInitialPage.value.loading = true;
  }

  function stopPageLoading() {
    stateInitialPage.value.loading = false;
  }
  //
  // onBeforeRouteLeave(async (to, from, next) => {
  //   stopLoading();
  //   stopPageLoading();
  //   next();
  // });

  return {
    startLoading,
    stopLoading,
    startPageLoading,
    stopPageLoading,
    isLoading: computed(() => state.value.loading === true),
    isLoadingPage: computed(() => stateInitialPage.value.loading === true),
    isLoadingMessage: computed(() => state.value.message)
  };
}
