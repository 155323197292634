export default {
  taskTypes() {
    return window.axios.get('/api/services/task-types');
  },
  all({
    q = '',
    relations = ['images'],
    regions = [],
    types = [],
    page = 1,
    perPage = 2000
  }) {
    let query = {
      page: page.toString(),
      per_page: perPage.toString()
    };
    if (q) {
      query = { ...query, q };
    }
    if (Array.isArray(relations) && relations.length > 0) {
      query = { ...query, relations: relations.join(',') };
    }
    if (Array.isArray(types) && types.length > 0) {
      query = { ...query, types: types.join(',') };
    }
    if (Array.isArray(regions) && regions.length > 0) {
      query = { ...query, regions: regions.join(',') };
    }

    const queryFormatted = new URLSearchParams(query);

    return window.axios.get(`/api/tasks?${queryFormatted.toString()}`);
  },
  show({
    id,
    relations = []
  }) {
    let query = {};
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);
    return window.axios.get(`/api/tasks/${id}?${queryFormatted.toString()}`);
  }
};
