export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/trading`);
  },
  show(companyId, projectId) {
    return window.axios.get(`/api/admin/company/${companyId}/trading/${projectId}`);
  },
  store(companyId, data) {
    return window.axios.post(`/api/admin/company/${companyId}/trading`, data);
  },
  update(companyId, caseId, data) {
    return window.axios.put(`/api/admin/company/${companyId}/trading/${caseId}`, data);
  },
  destroy(companyId, caseId) {
    return window.axios.delete(`/api/admin/company/${companyId}/trading/${caseId}`);
  },
  upload(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/trading/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
