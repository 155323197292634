import useAuth from '@/composables/useAuth';

export default function (to, from, next) {
  const { isAuthenticated } = useAuth();
  if (to.meta?.requireAuth === false) {
    next();
    return;
  }

  if (isAuthenticated.value === false) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
    return;
  }

  next();
}
