<template>
  <BaseSidebar>
    <FilterSection v-if="tradingCategories.length > 0" label="Kategori">
      <BaseField v-for="category in tradingCategories" :key="category.id">
        <BaseCheckbox
          v-model="selectedCategoryIds"
          :value="category.id"
          :label="category.name"
          @update:modelValue="fetchTasksByFilters"
        />
      </BaseField>
    </FilterSection>
    <FilterSection v-if="tradingTypes.length > 0" label="Annoncetype">
      <BaseField v-for="type in tradingTypes" :key="type.id">
        <BaseCheckbox
          v-model="selectedTypeIds"
          :value="type.id"
          :label="type.name"
          @update:modelValue="fetchTasksByFilters"
        />
      </BaseField>
    </FilterSection>
    <FilterSection v-if="regions.length > 0" label="Område">
      <BaseField v-for="region in regions" :key="region.id">
        <BaseCheckbox
          v-model="selectedRegionIds"
          :value="region.id"
          :label="region.name"
          @change="onSelectRegion"
        />
      </BaseField>
    </FilterSection>
  </BaseSidebar>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Ressourceportalen" class="mb-2" slot-grow>
        <BaseSearch
          v-model="searchterm"
          icon="search"
          placeholder="Søg efter annoncer"
          :loading="searchtermLoading"
          @input="onSearch"
        />
      </BasePageHeader>
      <HeroLoader :empty="tradingList.length === 0" :loading="isLoadingPage">
        <div class="grid highlightedTrading" v-if="highlightedTradingList.length > 0">
          <TradingCardHighlight
            v-for="trading in highlightedTradingList"
            :key="trading.id"
            class="col-12 col-sm-6 col-xl-3"
            :trading="trading"
            @liked="onLike(trading.id, $event)"
          />
        </div>
        <div class="grid trading-mobile" v-if="highlightedTradingList.length > 0">
          <TradingCardHighlight
            v-for="trading in tradingList"
            :key="trading.id"
            class="col-12 col-sm-6 col-xl-3"
            :trading="trading"
            @liked="onLike(trading.id, $event)"
          />
        </div>
        <div class="filteredList" v-if="filteredTradingList.length > 0">
          <Box class="box-padding">
            <div class="grid">
              <TradingCard
                v-for="trading in filteredTradingList"
                :key="trading.id"
                class="col-12 tradingItem"
                :trading="trading"
                @liked="onLike(trading.id, $event)"
              />
            </div>
          </Box>
        </div>

        <Pagination
          v-if="showPagination"
          v-model="page"
          :pages="meta.lastPage"
          scroll-top
          @update:modelValue="onPageChange"
        />
      </HeroLoader>
    </div>
  </div>
</template>

<script>
// /* eslint-disable */
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import TradingService from '@/services/TradingService';
import FilterSection from '@/containers/FilterSection.vue';
import BaseField from '@/components/BaseField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import { debounce } from 'lodash';
import BaseSidebar from '@/components/BaseSidebar.vue';
import useZipcode from '@/composables/useZipcode';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import TradingCard from '@/containers/TradingCard.vue';
import Pagination from '@/containers/Pagination.vue';
import TradingCardHighlight from '@/containers/TradingCardHighlight.vue';
import Box from '@/components/Box.vue';

export default {
  components: {
    Box,
    TradingCardHighlight,
    Pagination,
    TradingCard,
    HeroLoader,
    BaseSearch,
    BasePageHeader,
    BaseSidebar,
    BaseCheckbox,
    BaseField,
    FilterSection
  },
  data() {
    return {
      searchterm: '',
      searchtermLoading: false,
      tradingList: [],
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      },
      tradingCategories: [],
      tradingTypes: [],
      selectedCategoryIds: [],
      selectedRegionIds: [],
      selectedTypeIds: []
    };
  },
  computed: {
    showPagination() {
      return this.meta.total > this.meta.perPage;
    },
    highlightedTradingList() {
      if (this.page === 1) {
        return this.tradingList.slice(0, 4);
      }
      return [];
    },
    filteredTradingList() {
      if (this.page === 1) {
        return this.tradingList.slice(4, this.tradingList.length);
      }
      return this.tradingList;
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const {
      fetchRegions,
      regions
    } = useZipcode();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchRegions,
      regions
    };
  },
  async mounted() {
    this.startLoading();
    this.startPageLoading();
    await this.fetchTasksByFilters();
    await Promise.all([this.fetchRegions(), this.fetchTradingCategories(), this.fetchTradingTypes()]);
    this.stopPageLoading();
  },
  methods: {
    onLike(id, data) {
      const [trading] = this.tradingList.filter((item) => item.id === id);
      trading.liked = data.liked;
      trading.likeCounter = data.count;
    },
    onSelectRegion() {
      this.searchterm = '';
      this.fetchTasksByFilters();
    },
    onPageChange() {
      // this.fetchTasksByFilters();
    },
    // eslint-disable-next-line
    onSearch: debounce(function () {
      this.searchtermLoading = true;
      this.fetchTasksByFilters();
    }, 250),
    async fetchTradingCategories() {
      try {
        const payload = await TradingService.tradingCategories();
        this.tradingCategories = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async fetchTradingTypes() {
      try {
        const payload = await TradingService.tradingTypes();
        this.tradingTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async fetchTasksByFilters() {
      this.startLoading('Henter udbud');
      try {
        const payload = await TradingService.all({
          q: this.searchterm,
          relations: ['geocode', 'images'],
          regions: this.selectedRegionIds,
          types: this.selectedTypeIds,
          categories: this.selectedCategoryIds
        });
        this.tradingList = payload?.data?.data;
        this.meta.lastPage = payload?.data?.meta?.last_page;
        this.meta.perPage = payload?.data?.meta?.per_page;
        this.meta.total = payload?.data?.meta?.total;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
        this.searchtermLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.trading-mobile {
  display: grid;

  @include config.sm {
    display: none;
  }
}

.highlightedTrading {
  display: none;

  @include config.sm {
    display: grid;
    margin-bottom: 50px;
  }
}

.filteredList {
  display: none;

  @include config.sm {
    display: block;
    max-width: 840px;
    margin: 0 auto;
  }

  .tradingItem {
    @include config.not_last {
      &::after {
        content: '';
        display: block;
        margin-top: 1rem;
        height: 1px;
        background: config.$grey;
      }
    }
  }
}
</style>
