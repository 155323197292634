<template>
  <HeroLoader :loading="isLoadingPage">
    <form @submit.prevent="onSubmit" class="grid grid--start">
      <Box headline="Generelt" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Udbudstype">
            <BaseSelect v-model="selectedTaskTypeId">
              <option v-for="taskType in taskTypes" :key="taskType.id" :value="taskType.id">
                {{ taskType.name }}
              </option>
            </BaseSelect>
          </BaseField>
          <BaseField label="Overskrift">
            <BaseInput v-model="form.title"/>
          </BaseField>
          <BaseColumn>
            <BaseField label="Adresse">
              <BaseInput v-model="form.address"/>
            </BaseField>
            <BaseField label="Postnr & By">
              <ZipcodeSelector v-model="form.zipcode"/>
            </BaseField>
          </BaseColumn>
          <BaseColumn>
            <BaseField label="Svarfrist">
              <BaseInput type="date"
                         v-model="form.deadlineAt"
                         :min="minDate"
                         :max="maxDate"
              />
            </BaseField>
          </BaseColumn>
          <BaseField label="Upload billede">
            <ImageUpload :initial-images="initialImages" @updated="onImagesUpdated" />
          </BaseField>
        </div>
      </Box>
      <Box headline="Detaljer" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Brancher">
            <ul class="list">
              <li v-for="subindustry in selectedIndustries" :key="subindustry.id">
                <p>{{ subindustry.parentName }}: {{ subindustry.name }}</p>
                <button @click.prevent="removeSelectedSubindustry(subindustry.id)"
                        :disabled="isLoading">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
              </li>
            </ul>

            <div>
              <select :disabled="isLoading" v-model="tempSecondary" class="select"
                      style="width: 100%"
                      @change="onAddSecondaryIndustry"
                      v-if="selectedIndustries.length < 5"
              >
                <option :value="null" disabled>Tilføj bibranche</option>
                <optgroup v-for="industry in industries"
                          :key="industry.id"
                          :label="industry.name"
                >
                  <option v-for="subindustry in getSubIndustries(industry)"
                          :key="subindustry.id"
                          :value="subindustry"
                  >
                    {{ subindustry.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </BaseField>
          <BaseField label="Beskrivelse">
            <Editor v-model="form.description"/>
          </BaseField>
          <BaseField label="Tilbud fremsendes til kontaktoplysninger">
            <Editor v-model="form.contactDescription"/>
          </BaseField>
          <BaseField label="Link til yderligere oplysninger">
            <BaseInput v-model="form.moreLink"/>
          </BaseField>
          <BaseField>
            <BaseButton :label="buttonLabel"/>
          </BaseField>
        </div>
      </Box>
    </form>
  </HeroLoader>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import ImageUpload from '@/containers/ImageUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import AdminCompanyTaskService from '@/services/Admin/AdminCompanyTaskService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import dayjs from 'dayjs';
import Editor from '@/containers/Editor.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import TaskService from '@/services/TaskService';
import useIndustries from '@/composables/useIndustries';

export default {
  props: {
    editId: {
      type: Number,
      default: null
    }
  },
  emits: ['created', 'updated'],
  components: {
    BaseSelect,
    Editor,
    HeroLoader,
    BaseButton,
    ZipcodeSelector,
    BaseColumn,
    BaseInput,
    ImageUpload,
    BaseField,
    Box
  },
  data() {
    return {
      taskTypes: [],
      isDetailsOpen: false,
      maxDate: dayjs()
        .add(10, 'year')
        .format('YYYY-MM-DD'),
      createdAt: null,
      selectedTaskTypeId: null,
      selectedIndustries: [],
      tempSecondary: null,
      initialImages: [],
      form: {
        title: '',
        address: '',
        zipcode: '',
        description: '',
        contactDescription: '',
        publishedAt: dayjs().startOf('day').format('YYYY-MM-DD'),
        deadlineAt: dayjs().startOf('day').format('YYYY-MM-DD'),
        images: [],
        moreLink: ''
      }
    };
  },
  computed: {
    buttonLabel() {
      return this.editId ? 'Gem udbud' : 'Opret udbud';
    },
    minDate() {
      return this.createdAt ? this.createdAt : dayjs().format('YYYY-MM-DD');
    }
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      fetchIndustries,
      industries
    } = useIndustries();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      fetchIndustries,
      industries,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await Promise.all([this.fetchTaskTypes(), this.fetchIndustries()]);
    if (this.editId) {
      await this.loadTask();
    }
    this.stopPageLoading();
  },
  methods: {
    onImagesUpdated(files) {
      this.form.images = files;
    },
    async removeSelectedSubindustry(id) {
      this.selectedIndustries = this.selectedIndustries.filter((item) => item.id !== id);
    },
    getSubIndustries(industry) {
      return industry.children.filter((item) => {
        if (this.form.primaryId === item.id) {
          return false;
        }
        return this.selectedIndustries.findIndex((i) => i.id === item.id) === -1;
      });
    },
    async onAddSecondaryIndustry() {
      this.selectedIndustries.push(this.formatSecondaryIndustry(this.tempSecondary));
      this.tempSecondary = null;
    },
    formatSecondaryIndustry(subindustry) {
      return {
        id: subindustry.id,
        name: subindustry.name,
        parentName: subindustry.parent.name
      };
    },
    async fetchTaskTypes() {
      try {
        const payload = await TaskService.taskTypes();
        this.taskTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async loadTask() {
      Message.clear();
      this.startLoading('Henter udbud');

      try {
        const payload = await AdminCompanyTaskService.show(this.company.id, this.editId);
        const task = payload?.data?.data;
        if (task) {
          this.form.title = task.title;
          this.form.address = task.location.address;
          this.form.zipcode = task.location.zipcode;
          this.form.description = task.description.html;
          this.form.contactDescription = task.contactDescription.html;
          this.form.publishedAt = dayjs(task.publishedAt)
            .format('YYYY-MM-DD');
          this.form.deadlineAt = dayjs(task.deadlineAt)
            .format('YYYY-MM-DD');
          this.createdAt = dayjs(task.createdAt)
            .format('YYYY-MM-DD');
          this.form.moreLink = task.links.more;
          this.selectedTaskTypeId = task.type.id;
          this.initialImages = task.images;
          this.selectedIndustries = task.industries.map((item) => this.formatSecondaryIndustry(item));
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    onSubmit() {
      if (!this.editId) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer udbud');

      try {
        const payload = await AdminCompanyTaskService.update(this.company.id, this.editId, {
          title: this.form.title,
          address: this.form.address,
          zipcode: this.form.zipcode,
          description: this.form.description,
          contact_description: this.form.contactDescription,
          published_at: this.form.publishedAt,
          deadline_at: this.form.deadlineAt,
          more_link: this.form.moreLink,
          task_type_id: this.selectedTaskTypeId,
          industries: this.selectedIndustries.map((i) => i.id)
        }, this.form.images);
        Message.httpSuccess(payload);
        this.$emit('updated');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter udbud');

      try {
        const payload = await AdminCompanyTaskService.store(this.company.id, {
          title: this.form.title,
          address: this.form.address,
          zipcode: this.form.zipcode,
          description: this.form.description,
          contact_description: this.form.contactDescription,
          published_at: this.form.publishedAt,
          deadline_at: this.form.deadlineAt,
          more_link: this.form.moreLink,
          task_type_id: this.selectedTaskTypeId,
          industries: this.selectedIndustries.map((i) => i.id)
        }, this.form.images);
        Message.httpSuccess(payload);
        this.$emit('created');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.list {
  list-style: none;

  li {
    margin: 5px 0;
    padding: 10px;
    font-size: 0.9em;
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}
</style>
