<template>
  <HeroLoader :loading="isLoadingPage">
    <Box :headline="headerLabel" style="max-width: 800px;">
      <form @submit.prevent="onSubmit" class="box-body">
        <BaseField label="Upload billede">
          <FileUpload @upload="onUpload" multiple v-if="!fullImagePath"></FileUpload>
          <div v-else>
            <BaseImage :src="fullImagePath"/>
            <p style="text-align: right; font-weight: 600; font-size: 0.9em;">
              <a href="#" @click.prevent="onDeleteImage">Slet billede</a>
            </p>
          </div>
        </BaseField>
        <BaseColumn>
          <BaseField label="Overskrift">
            <BaseInput v-model="form.title"/>
          </BaseField>
          <BaseField label="Pris (DKK)">
            <BaseInput v-model.number="form.price" />
          </BaseField>
        </BaseColumn>
        <BaseColumn>
          <BaseField label="Adresse">
            <BaseInput v-model="form.address"/>
          </BaseField>
          <BaseField label="Postnr & By">
            <ZipcodeSelector v-model="form.zipcode"/>
          </BaseField>
        </BaseColumn>
         <BaseColumn>
          <BaseField label="Læs mere">
            <BaseInput v-model="form.moreLink" />
          </BaseField>
          <BaseField label="Tilmeld">
            <BaseInput v-model="form.signupLink" />
          </BaseField>
        </BaseColumn>
        <BaseColumn>
          <BaseField label="Dato for afholdelse">
            <BaseInput type="date"
                       :model-value="startDate"
                       @update:modelValue="setStartDate"
                       :min="minDate"
                       :max="maxDate"
            />
          </BaseField>
          <BaseField label="Start tidspunkt">
            <BaseInput type="time" v-model="startAtTime" />
          </BaseField>
          <BaseField label="Slut tidspunkt">
            <BaseInput type="time" v-model="endAtTime" />
          </BaseField>
        </BaseColumn>
        <BaseField label="Beskrivelse">
          <Editor v-model="form.description"/>
        </BaseField>
        <BaseField>
          <BaseButton :label="buttonLabel"/>
        </BaseField>
      </form>
    </Box>
  </HeroLoader>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import FileUpload from '@/containers/FileUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import AdminCompanyEventService from '@/services/Admin/AdminCompanyEventService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseImage from '@/components/BaseImage.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import dayjs from 'dayjs';
import Editor from '@/containers/Editor.vue';

export default {
  props: {
    editId: {
      type: Number,
      default: null
    }
  },
  emits: ['created', 'updated'],
  components: {
    Editor,
    HeroLoader,
    BaseImage,
    BaseButton,
    ZipcodeSelector,
    BaseColumn,
    BaseInput,
    FileUpload,
    BaseField,
    Box
  },
  data() {
    return {
      fullImagePath: '',
      minDate: dayjs()
        .format('YYYY-MM-DD'),
      maxDate: dayjs()
        .add(10, 'year')
        .format('YYYY-MM-DD'),
      form: {
        title: '',
        address: '',
        zipcode: '',
        description: '',
        moreLink: '',
        signupLink: '',
        price: 0,
        startAt: dayjs()
          .startOf('day'),
        endAt: dayjs()
          .startOf('day'),
        imagePath: ''
      }
    };
  },
  computed: {
    startDate() {
      return dayjs(this.form.startAt)
        .format('YYYY-MM-DD');
    },
    startAtTime: {
      get() {
        return dayjs(this.form.startAt)
          .format('HH:mm');
      },
      set(value) {
        const time = value.split(':');
        if (time.length === 2) {
          this.form.startAt = this.form.startAt.set('hour', time[0]);
          this.form.startAt = this.form.startAt.set('minute', time[1]);
          this.form.startAt = this.form.startAt.set('second', 0);
        }
      }
    },
    endAtTime: {
      get() {
        return dayjs(this.form.endAt)
          .format('HH:mm');
      },
      set(value) {
        const time = value.split(':');
        if (time.length === 2) {
          this.form.endAt = this.form.endAt.set('hour', time[0]);
          this.form.endAt = this.form.endAt.set('minute', time[1]);
          this.form.endAt = this.form.endAt.set('second', 0);
        }
      }
    },
    buttonLabel() {
      return this.editId ? 'Gem event' : 'Opret event';
    },
    headerLabel() {
      return this.editId ? 'Opdater event' : 'Tilføj ny event';
    }
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    if (this.editId) {
      this.startPageLoading();
      await this.loadEvent();
      this.stopPageLoading();
    }
  },
  methods: {
    setStartDate(value) {
      const date = dayjs(value);
      if (date.isValid() && date.isAfter(this.minDate)) {
        this.form.startAt = this.form.startAt.set('date', date.get('date'));
        this.form.startAt = this.form.startAt.set('month', date.get('month'));
        this.form.startAt = this.form.startAt.set('year', date.get('year'));

        this.form.endAt = this.form.endAt.set('date', date.get('date'));
        this.form.endAt = this.form.endAt.set('month', date.get('month'));
        this.form.endAt = this.form.endAt.set('year', date.get('year'));
      }
    },
    async loadEvent() {
      Message.clear();
      this.startLoading('Henter event');

      try {
        const payload = await AdminCompanyEventService.show(this.company.id, this.editId);
        const event = payload?.data?.data;
        if (event) {
          this.form.imagePath = event.imagePath;
          this.form.zipcode = event.location.zipcode;
          this.form.address = event.location.address;
          this.form.title = event.title;
          this.form.startAt = dayjs(event.startAt);
          this.form.endAt = dayjs(event.endAt);
          this.form.price = event.price.editable;
          this.form.description = event.description.html;
          this.form.moreLink = event.links.more;
          this.form.signupLink = event.links.signup;
          this.fullImagePath = event.image;
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    onDeleteImage() {
      this.fullImagePath = '';
      this.form.imagePath = '';
    },
    onSubmit() {
      if (!this.editId) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer event');

      try {
        const payload = await AdminCompanyEventService.update(this.company.id, this.editId, {
          title: this.form.title,
          image_path: this.form.imagePath,
          description: this.form.description,
          start_at: this.form.startAt,
          end_at: this.form.endAt,
          address: this.form.address,
          zipcode: this.form.zipcode,
          price: this.form.price,
          more_link: this.form.moreLink,
          signup_link: this.form.signupLink
        });
        Message.httpSuccess(payload);
        this.$emit('updated');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter nyt event');

      try {
        const payload = await AdminCompanyEventService.store(this.company.id, {
          title: this.form.title,
          image_path: this.form.imagePath,
          description: this.form.description,
          start_at: this.form.startAt,
          end_at: this.form.endAt,
          address: this.form.address,
          zipcode: this.form.zipcode,
          price: this.form.price,
          more_link: this.form.moreLink,
          signup_link: this.form.signupLink
        });
        Message.httpSuccess(payload);
        this.$emit('created');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onUpload(formData) {
      Message.clear();
      this.startLoading('Uploader billede');
      try {
        const payload = await AdminCompanyEventService.upload(this.company.id, formData);
        Message.httpSuccess(payload);
        this.fullImagePath = payload?.data?.data?.fullImagePath;
        this.form.imagePath = payload?.data?.data?.imagePath;
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

</style>
