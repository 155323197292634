<template>
  <BaseSidebar>
    <FilterSection v-if="regions.length > 0" label="Område">
      <BaseField v-for="region in regions" :key="region.id">
        <BaseCheckbox v-model="selectedRegionIds" :value="region.id" :label="region.name" @change="onSelectRegion"/>
      </BaseField>
    </FilterSection>
    <FilterSection v-if="industries.length > 0" label="Brancher">
      <FilterSectionIndustries
        :industries="industries"
        v-model:selected="selectedIndustry"
        @update:selected="onSelectIndustry"
      />
    </FilterSection>
  </BaseSidebar>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Virksomhedsprofiler" class="mb-2" slot-grow>
        <BaseSearch v-model="searchterm"
                    icon="search"
                    placeholder="Find din næste samarbejdspartner"
                    @input="onSearch"
        />
      </BasePageHeader>
      <HeroLoader :empty="companies.length === 0" :loading="isLoadingPage">
        <div class="grid">
          <CompanyCard
            v-for="company in companies"
            :key="company.id"
            :company="company"
            class="col-12 col-sm-6 col-lg-4"
          />
        </div>

        <Pagination
          v-if="showPagination"
          v-model="page"
          :pages="meta.lastPage"
          scroll-top
          @update:modelValue="onPageChange"
        />
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import BaseSidebar from '@/components/BaseSidebar.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useIndustries from '@/composables/useIndustries';
import FilterSection from '@/containers/FilterSection.vue';
import FilterSectionIndustries from '@/containers/FilterSectionIndustries.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import useZipcode from '@/composables/useZipcode';
import BaseField from '@/components/BaseField.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import CompanyService from '@/services/CompanyService';
import Message from '@/helpers/Message';
import Box from '@/components/Box.vue';
import NoResults from '@/components/NoResults.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import CompanyCard from '@/containers/CompanyCard.vue';
import Pagination from '@/containers/Pagination.vue';

export default {
  components: {
    Pagination,
    CompanyCard,
    HeroLoader,
    // eslint-disable-next-line vue/no-unused-components
    NoResults,
    // eslint-disable-next-line vue/no-unused-components
    Box,
    BaseField,
    BaseCheckbox,
    FilterSectionIndustries,
    FilterSection,
    BasePageHeader,
    BaseSearch,
    BaseSidebar
  },
  data() {
    return {
      selectedIndustry: null,
      selectedRegionIds: [],
      searchterm: '',
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      },
      companies: []
    };
  },
  computed: {
    showPagination() {
      return this.meta.total > this.meta.perPage;
    }
  },
  setup() {
    const {
      fetchIndustries,
      industries
    } = useIndustries();

    const {
      fetchRegions,
      regions
    } = useZipcode();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchIndustries,
      industries,
      fetchRegions,
      regions
    };
  },
  async mounted() {
    this.startPageLoading();
    this.startLoading();
    this.syncQuery();
    await Promise.all([this.fetchIndustries(), this.fetchRegions()]);
    await this.fetchCompaniesByFilters();
    this.stopPageLoading();
  },
  methods: {
    onPageChange() {
      this.fetchCompaniesByFilters();
      this.setQuery();
    },
    onSelectIndustry() {
      this.searchterm = '';
      this.page = 1;
      this.setQuery();
    },
    onSelectRegion() {
      this.page = 1;
      this.searchterm = '';
      this.setQuery();
    },
    async fetchCompaniesByFilters() {
      this.startLoading();
      try {
        const payload = await CompanyService.all({
          q: this.searchterm,
          industry: this.selectedIndustry,
          regions: this.selectedRegionIds,
          relations: ['industries', 'images'],
          page: this.page
        });
        this.companies = payload?.data?.data;
        this.meta.lastPage = payload?.data?.meta?.last_page;
        this.meta.perPage = payload?.data?.meta?.per_page;
        this.meta.total = payload?.data?.meta?.total;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
        this.stopLoading();
      }
    },
    // eslint-disable-next-line
    onSearch: debounce(function () {
      this.page = 1;
      this.setQuery();
    }, 250),
    syncQuery() {
      const { query } = this.$route;
      if (query.page) {
        this.page = parseInt(query.page.toString(), 10);
      }

      if (query.industry) {
        this.selectedIndustry = parseInt(query.industry.toString(), 10);
      }
      if (query.regions) {
        this.selectedRegionIds = query.regions
          .toString()
          .split(',')
          .map((x) => parseInt(x.toString(), 10));
      }
      if (query.q) {
        this.searchterm = decodeURIComponent(query.q.toString());
      }
    },
    setQuery() {
      const query = {
        page: this.page,
        industry: this.selectedIndustry,
        regions: this.selectedRegionIds.length > 0 ? this.selectedRegionIds.join(',') : null,
        q: this.searchterm.length > 0 ? encodeURIComponent(this.searchterm) : null
      };

      this.$router.replace({ query });

      this.fetchCompaniesByFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
</style>
