export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/projects`);
  },
  show(companyId, projectId) {
    return window.axios.get(`/api/admin/company/${companyId}/projects/${projectId}`);
  },
  store(companyId, data) {
    return window.axios.post(`/api/admin/company/${companyId}/projects`, data);
  },
  update(companyId, projectId, data) {
    return window.axios.put(`/api/admin/company/${companyId}/projects/${projectId}`, data);
  },
  destroy(companyId, projectId) {
    return window.axios.delete(`/api/admin/company/${companyId}/projects/${projectId}`);
  },
  upload(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/projects/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  pin(companyId, projectId) {
    return window.axios.post(`/api/admin/company/${companyId}/projects/${projectId}/pin`);
  }
};
