<template>
  <section class="grid" v-bind="$attrs">
    <div class="col-12" :class="css">
      <h4 class="subtitle">{{ headline }}</h4>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      required: true
    },
    css: {
      type: String,
      default: 'col-xl-9'
    }
  }
};
</script>
