// eslint-disable-next-line no-unused-vars
import { computed, ref } from 'vue';

const state = ref({
  isMobileActive: false
});

export default function useMainNavigation() {
  function showOnMobile() {
    state.value.isMobileActive = true;
  }

  function hideOnMobile() {
    state.value.isMobileActive = false;
  }

  function toggleOnMobile() {
    if (state.value.isMobileActive === true) {
      hideOnMobile();
    } else {
      showOnMobile();
    }
  }

  // const isAuthenticated = computed(() => Object.keys(state.value.user).length > 0);

  return {
    showOnMobile,
    toggleOnMobile,
    isMobileActive: computed(() => state.value.isMobileActive === true),
    hideOnMobile
  };
}
