import anime from 'animejs/lib/anime.es';

window.anime = anime;

function slideUp({
  targets,
  duration = 300,
  changeComplete = null
}) {
  anime({
    targets,
    height: {
      value: 0,
      delay: 180
    },
    duration,
    opacity: 0,
    easing: 'linear',
    changeComplete
  });
}

function slideDown({
  targets,
  changeComplete = null,
  height = 'auto',
  duration = 300
}) {
  anime({
    targets,
    height,
    opacity: {
      value: 1,
      delay: 250
    },
    duration,
    easing: 'easeInOutSine',
    changeComplete
  });
}

function slideLeft({ targets, width, duration = 300 }) {
  anime({
    targets,
    // translateX: '-100%',
    easing: 'linear',
    duration,
    width
  });
}

function slideRight({ targets, duration = 300 }) {
  anime({
    targets,
    // translateX: '0%',
    easing: 'linear',
    opacity: 1,
    duration,
    width: '250px'
  });
}

export {
  slideUp,
  slideDown,
  slideLeft,
  slideRight
};
