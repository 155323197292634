<template>
  <div :class="{ sticky }">
    <Box headline="Information">
      <GoogleMap :center="event.location?.geocode?.position" :locations="[{ item: null, location: event.location?.geocode?.position }]" style="height: 200px;"/>
      <div class="contact">
        <div class="grid">
          <div class="col-12 col-sm-6">
            <h4 class="contact__title">Pris</h4>
            <IconAndLabel icon="wallet" :label="event.price.withCurrency"/>
          </div>
          <div class="col-12 col-sm-6">
            <h4 class="contact__title">Dato</h4>
            <IconAndLabel icon="calendar-alt" :label="event.meta.startDate"/>
            <IconAndLabel icon="clock" :label="event.meta.timespan"/>
          </div>
        </div>
      </div>
      <div class="contact">
        <h4 class="contact__title">Lokation</h4>
        <div class="grid">
          <IconAndLabel icon="map-marker"
                        class="col-12 col-sm-6 col-xl-6"
                        :label="event.location.full_html"
          />
          <div class="col-12 col-sm-6 col-xl-6">
            <IconAndLabel icon="phone" v-if="event.company.contact.phone">
              <p>
                <a :href="`tel:${event.company.contact.phone}`">{{ event.company.contact.phone }}</a>
              </p>
            </IconAndLabel>
            <IconAndLabel icon="at" v-if="event.company.contact.email">
              <p>
                <a :href="`mailto:${event.company.contact.email}`"
                   :title="event.company.contact.email">Send
                  mail</a>
              </p>
            </IconAndLabel>
          </div>
        </div>
      </div>
    </Box>
    <BaseButton v-if="event.links.signup"
                :href="event.links.signup"
                tag="a"
                target="_blank"
                label="Gå til tilmelding"
                full
                size="large"
                type="info"
                class="button"
    />
  </div>
</template>

<script>
import Box from '@/components/Box.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
    IconAndLabel,
    GoogleMap,
    Box
  },
  props: {
    event: Object,
    sticky: Boolean
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.sticky {
  position: sticky;
  top: 20px;
}

.button {
  margin-top: 1rem;
}

.contact {
  margin: 1.5em;

  &__title {
    padding-bottom: 0.7em;
    font-weight: 500;
  }
}
</style>
