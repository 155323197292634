<template>
  <BoxWithImage class="eventCard"
                :src="imagePath || ''"
                :label="title"
                :link="link"
                @click="$router.push(link)"
                :sticker="sticker"
  >
    <div class="grid grid--center">
      <DateHighlight :date="startAt"/>
      <div class="col-9">
        <IconAndLabel icon="map-marker" :label="address"/>
        <IconAndLabel icon="clock" class="col-12" :label="timespan"/>
      </div>
    </div>
    <slot/>
    <div class="card-footer">
      <hr />
      <div class="interactions">
        <LikeCounter
          type="event"
          :id="id"
          :liked="liked"
          :count="likeCounter"
          @liked="onLike"
        />
        <CommentCount :count="totalComments" />
      </div>
    </div>
  </BoxWithImage>
</template>

<script>
import BoxWithImage from '@/containers/BoxWithImage.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import DateHighlight from '@/components/DateHighlight.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  emits: ['liked'],
  components: {
    DateHighlight,
    IconAndLabel,
    BoxWithImage,
    LikeCounter,
    CommentCount
  },
  props: {
    id: [String, Number],
    imagePath: String,
    sticker: String,
    title: String,
    address: String,
    startAt: String,
    timespan: String,
    likeCounter: Number,
    liked: Boolean,
    totalComments: Number
  },
  computed: {
    link() {
      return {
        name: 'events.show',
        params: {
          id: this.id
        }
      };
    }
  },
  methods: {
    onLike(data) {
      this.$emit('liked', data);
    }
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.eventCard {
  font-size: 14px;
  cursor: pointer;

  @include config.lg {
    font-size: 1rem;
  }
}
.card-footer {
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
hr {
  background-color: #CFD2CF;
  border: none;
  height: 1px;
  margin: 0.75rem 0;
  box-shadow: none;
}
.interactions {
  display: flex;
  justify-content: space-between;
}
</style>
