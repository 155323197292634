<template>
  <div class="filterSection">
    <p class="filterSection__label">{{ label }}</p>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.filterSection {
  &__label {
    font-size: 1.1em;
    font-weight: bold;
    font-family: config.$font-headline;
    margin-bottom: 10px;
  }
}
</style>
