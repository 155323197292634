<template>
  <div v-if="loading" class="heroLoader">
    <div class="pulseLoader"></div>
  </div>
  <NoResults v-else-if="empty"/>
  <slot v-else-if="!loading && !empty"/>
</template>

<script>

import NoResults from '@/components/NoResults.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';

export default {
  components: { NoResults },
  props: {
    empty: Boolean,
    loading: Boolean
  },
  setup() {
    const {
      isLoadingPage
    } = useGlobalLoader();

    return { isLoadingPage };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

$pulseSize: 12em;
$pulseTiming: 1.2s;

.heroLoader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  grid-area: content;
}

.pulseLoader {
  width: $pulseSize;
  height: $pulseSize;
  border-radius: $pulseSize;
  background-color: #aaa;
  outline: 1px solid transparent;
  animation: pulse $pulseTiming ease-in-out infinite;
}
</style>
