<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Arktikler">
        <BaseButton @click="showForm = true" v-if="!showForm">Opret ny artikel</BaseButton>
        <a href="#" @click.prevent="goBackToListView" v-if="showForm">Tilbage til visningen</a>
      </BasePageHeader>

      <AdminArticleForm v-if="showForm" @created="onCreated" @updated="onUpdated" :edit-id="selectedArticleId"/>

      <BaseSection css="" headline="Indlæg" style="margin-top: 3rem;" v-if="!showForm">
        <div class="grid" v-if="articles.length > 0">
          <ArticleCard v-for="article in articles"
                    :key="article.id"
                    :article="article"
                    class="col-12 col-sm-6 col-lg-4"
          >
            <br />
            <div style="display: flex; gap: 1rem;">
              <BaseButton type="info" @click.stop="onEdit(article)">
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </BaseButton>
              <BaseButton type="danger" @click.stop="onDelete(article)">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </BaseButton>
            </div>
          </ArticleCard>
        </div>
        <p v-else style="font-size: 0.9em">
          Der er ikke oprettet nogen artikler.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
import BaseSection from '@/components/BaseSection.vue';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import AdminCompanyBlogService from '@/services/Admin/AdminCompanyBlogService';
import HeroLoader from '@/components/HeroLoader.vue';
import AdminArticleForm from '@/views/Admin/components/AdminArticleForm.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import ArticleCard from '@/containers/ArticleCard.vue';

export default {
  components: {
    ArticleCard,
    BaseButton,
    BasePageHeader,
    AdminArticleForm,
    HeroLoader,
    BaseSection
  },
  data() {
    return {
      articles: [],
      showForm: false,
      selectedArticleId: null
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyArticles();
    this.stopPageLoading();
  },
  methods: {
    onEdit(article) {
      this.selectedArticleId = article.id;
      this.showForm = true;
    },
    async onDelete(article) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Vil du slette denne artikel?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter artikel');

      try {
        const payload = await AdminCompanyBlogService.destroy(this.company.id, article.id);
        await this.loadCompanyArticles();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreated() {
      this.articles = [];
      this.showForm = false;
      await this.loadCompanyArticles();
    },
    async onUpdated() {
      this.articles = [];
      this.showForm = false;
      await this.loadCompanyArticles();
    },
    goBackToListView() {
      this.showForm = false;
      this.selectedArticleId = null;
    },
    async loadCompanyArticles() {
      Message.clear();
      this.startLoading('Henter artikel');

      try {
        const payload = await AdminCompanyBlogService.all(this.company.id);
        this.articles = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

</style>
