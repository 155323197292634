import Companies from '@/views/Companies/Companies.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import CompanyView from '@/views/Companies/CompanyView.vue';
import Modules from '@/helpers/Modules';

export default [
  {
    path: '/virksomheder',
    name: 'companies',
    component: Companies,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.COMPANY
    }
  },
  {
    path: '/virksomheder/:id',
    name: 'companies.show',
    component: CompanyView,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.COMPANY
    },
    props: true
  }
];
