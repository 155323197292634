<template>
  <figure class="baseImage">
    <img :src="src" :alt="alt"/>
  </figure>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.baseImage {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
