<template>
  <div class="divider"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.divider {
  width: 1px;
  height: 31px;
  margin: 0 12px;
  background-color: config.$grey;
}
</style>
