<template>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Vi sætter pris på jeres feedback"/>
      <div class="grid">
        <div class="col-12 col-sm-6">
          <Box headline="Indtast din feedback">
            <form class="box-body" @submit.prevent="onSendFeedback">
              <BaseField>
                <BaseTextarea v-model="form.feedback" placeholder="Skriv din feedback her" required/>
              </BaseField>
              <BaseField>
                <BaseButton label="Send" :loading="isLoading"></BaseButton>
              </BaseField>
            </form>
          </Box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePageHeader from '@/containers/BasePageHeader.vue';
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import useGlobalLoader from '@/composables/useGlobalLoader';

export default {
  name: 'Feedback',
  components: {
    BaseButton,
    BaseTextarea,
    BaseField,
    Box,
    BasePageHeader
  },
  data() {
    return {
      form: {
        feedback: ''
      }
    };
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      isLoading
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoading
    };
  },
  methods: {
    async onSendFeedback() {
      this.startLoading();
      try {
        const payload = await window.axios.post('/api/feedback', {
          description: this.form.feedback
        });
        this.form.feedback = '';
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style scoped>
.box-body {
  padding: 10px;
}
</style>
