<template>
  <div v-if="src">
    <div class="companyLogo" :class="[`companyLogo--${size}`, { 'companyLogo--deleteable': deleteable }]">
      <img :src="src" :alt="alt" class="companyLogo__img"/>
      <div v-if="deleteable" class="companyLogo__deleteable" @click="onDelete">
        <font-awesome-icon icon="times"></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    deleteable: Boolean,
    size: {
      type: String,
      default: ''
    }
  },
  emits: ['delete'],
  methods: {
    onDelete() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette logo?')) {
        this.$emit('delete');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.companyLogo {
  position: relative;
  display: inline-block;

  &__img {
    max-height: 70px;

    .companyLogo--small & {
      max-height: 40px;
    }
  }

  &__deleteable {
    position: absolute;
    top: 0;
    right: -35px;
    background: config.$brown;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    z-index: 2;
    font-size: 12px;
    color: config.$white;
  }
}
</style>
