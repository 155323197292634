import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import useAuth from '@/composables/useAuth';
import dayjs from 'dayjs';
import dayjsDA from 'dayjs/locale/da';
import {
  faUserSecret,
  faSpinner,
  faUserAlt,
  faUser,
  faKey,
  faMarker,
  faLongArrowAltLeft,
  faPhone,
  faAt,
  faBullhorn,
  faHandshake,
  faUserTie,
  faBullseye,
  faCalendarAlt,
  faComments,
  faBars,
  faCogs,
  faStickyNote,
  faSitemap,
  faFilter,
  faSearch,
  faClock,
  faCheck,
  faMapMarker,
  faLink,
  faCircle,
  faWallet,
  faCertificate,
  faTimes,
  faCommentDots,
  faPen,
  faTrash,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faArrowUp,
  faArrowDown,
  faArrowAltCircleLeft,
  faList,
  faAlignJustify,
  faThumbtack,
  faThumbsUp as fasThumbsUp,
  faRss
} from '@fortawesome/free-solid-svg-icons';
import { faComment, faThumbsUp as farThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Loader } from 'google-maps';
// import VueGoogleMaps from '@fawmi/vue-google-maps';
import App from './App.vue';
import './registerServiceWorker';
import './bootstrap';
import router from './router';

const loader = new Loader('AIzaSyDamss5U2MB8jdxpy60t9MTSoPBl5AdzSc', {
  region: 'DK',
  language: 'da'
});

window.dayjs = dayjs;
window.dayjs.locale(dayjsDA);

// eslint-disable-next-line no-unused-expressions
(async function load() {
  window.google = await loader.load();
}());

const app = createApp(App);
const { self } = useAuth();

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

library.add(
  faUserSecret,
  faSpinner,
  faCheck,
  faUserAlt,
  faUser,
  faKey,
  faStickyNote,
  faComment,
  faCommentDots,
  faMarker,
  faLongArrowAltLeft,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faArrowUp,
  faArrowDown,
  faArrowAltCircleLeft,
  faList,
  faPhone,
  faCircle,
  faCertificate,
  faAt,
  faBullhorn,
  faTimes,
  faClock,
  faHandshake,
  faMapMarker,
  faLink,
  faWallet,
  faUserTie,
  faBullseye,
  faCalendarAlt,
  faPen,
  faTrash,
  faAlignJustify,
  faComments,
  faBars,
  faSitemap,
  faCogs,
  faSearch,
  faFilter,
  faThumbtack,
  fasThumbsUp,
  farThumbsUp,
  faRss
);

self({ silent: true })
  .finally(() => {
    app.use(router)
      // .use(VueGoogleMaps, {
      //   load: {
      //     key: 'AIzaSyBTMst4Gj0ymljvqj1_M9I49klfZjq5ph8'
      //   }
      // })
      .component('font-awesome-icon', FontAwesomeIcon)
      .mount('#app');
  });
