// eslint-disable-next-line no-unused-vars
import { computed, ref } from 'vue';
import useShouldFetch from '@/composables/useShouldFetch';
import Message from '@/helpers/Message';
import ZipcodeService from '@/services/ZipcodeService';

const state = ref({
  regions: []
});

const { fetch } = useShouldFetch(60);

export default function useZipcode() {
  async function fetchRegions(relations = '') {
    await fetch(async () => {
      try {
        const payload = await ZipcodeService.regions(relations);
        state.value.regions = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    });
  }

  return {
    fetchRegions,
    regions: computed(() => state.value.regions)
  };
}
