<template>
  <div class="box"
       :class="{'box--shadow-light': lightShadow, 'box--with-hover': hover, 'box--open': modelOpen }">
    <p v-if="headline" class="box__header" @click="onToggleAccordion">
      <font-awesome-icon v-if="headlineIcon" :icon="headlineIcon"></font-awesome-icon>
      <span v-else></span>
      {{ headline }}
      <span v-if="useAccordion">
        <font-awesome-icon icon="arrow-alt-circle-left"
                           class="box__accordion-icon"></font-awesome-icon>
      </span>
      <span v-else></span>
    </p>
    <Accordion v-model="modelOpen" v-if="useAccordion">
      <slot/>
    </Accordion>
    <slot v-else/>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
import useVModel from '@/composables/useVModel';

export default {
  components: { Accordion },
  props: {
    lightShadow: Boolean,
    hover: Boolean,
    headline: String,
    headlineIcon: String,
    open: Boolean,
    useAccordion: Boolean
  },
  emits: ['update:open'],
  setup(props) {
    return {
      modelOpen: useVModel(props, 'open')
    };
  },
  methods: {
    onToggleAccordion() {
      if (!this.useAccordion) {
        return;
      }
      this.modelOpen = !this.open;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  transition: all 200ms ease-out;

  &__header {
    background-color: config.$brown;
    color: config.$white;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: config.$font-headline;
    font-size: 1em;
    user-select: none;

    svg {
      margin-right: 0.7em;
    }
  }

  &--shadow-light {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
  }

  &--with-hover {
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    }
  }

  &__accordion-icon {
    transition: transform 275ms ease;

    .box--open & {
      transform: rotate(-90deg);
    }
  }
}
</style>
