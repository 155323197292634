<template>
  <div v-if="image" class="page-image">
    <img :src="image" :alt="headline"/>
  </div>
  <section class="page-header"
           v-bind="$attrs"
           :class="[`page-header--${type}`, { 'page-header--shadow': shadow }]"
  >
    <h1>{{ headline }}</h1>
    <div class="page-header__content" v-if="hasSlotContent" :class="{'page-header__content--grow': slotGrow}">
      <slot/>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    headline: String,
    shadow: Boolean,
    image: String,
    slotGrow: Boolean,
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'light'].includes(value)
    }
  },
  computed: {
    hasSlotContent() {
      return !!(this.$slots.default && this.$slots.default().length > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.page-image {
  height: 100%;
  max-height: 300px;
  position: relative;

  img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.page-header {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 70px;
  justify-content: center;

  &--light {
    background: white;
  }

  &--shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
  }

  h1 {
    margin-bottom: 0;
  }

  @include config.md {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      padding-right: 50px;
    }
  }

  &__content {
    margin-top: 1rem;

    @include config.md {
      max-width: 450px;
      margin-top: 0;

      &.page-header__content--grow {
        flex-grow: 1;
      }
    }
  }
}
</style>
