<template>
  <nav class="nav" :class="{'nav--mobile-active': isMobileActive}">
    <div class="nav__logo">
      <LogoFull/>
    </div>
    <ul class="nav__list">
      <router-link
        :to="{ name: item.route }"
        tag="li"
        v-for="item in items"
        :key="item.title"
        class="nav-item"
        :class="{'router-link-active': isRouteActive(item.route) }"
      >
        <div class="nav-item__icon">
          <font-awesome-icon :icon="item.icon" v-if="item.icon" size="2x"/>
        </div>
        <p class="nav-item__title">{{ item.title }}</p>
      </router-link>
    </ul>
  </nav>
</template>

<script>
import useAuth from '@/composables/useAuth';
import Modules from '@/helpers/Modules';
import LogoFull from '@/components/LogoFull.vue';
import useMainNavigation from '@/composables/useMainNavigation';

export default {
  components: { LogoFull },
  setup() {
    const { hasSubscription } = useAuth();
    const {
      isMobileActive,
      hideOnMobile
    } = useMainNavigation();

    return {
      hasSubscription,
      hideOnMobile,
      isMobileActive
    };
  },
  computed: {
    items() {
      return [
        {
          title: 'Aktivitet',
          icon: 'rss',
          route: 'activity',
          visible: true
        },
        {
          title: 'Virksomheder',
          icon: 'user-tie',
          route: 'companies',
          visible: this.hasSubscription(Modules.COMPANY)
        },
        {
          title: 'Kalender',
          icon: 'calendar-alt',
          route: 'events',
          visible: this.hasSubscription(Modules.EVENT)
        },
        {
          title: 'Ressourcer',
          icon: 'handshake',
          route: 'trading',
          visible: this.hasSubscription(Modules.TRADING)
        },
        {
          title: 'Artikler',
          icon: 'bullhorn',
          route: 'blog',
          visible: this.hasSubscription(Modules.BLOG)
        },
        {
          title: 'Udbud',
          icon: 'bullseye',
          route: 'tasks',
          visible: this.hasSubscription(Modules.TASK)
        },
        {
          title: 'Debat',
          icon: 'comments',
          route: 'forum',
          visible: this.hasSubscription(Modules.FORUM)
        }
      ].filter((item) => item.visible === true);
    }
  },
  methods: {
    isRouteActive(route) {
      return this.$route.name.startsWith(route);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.nav {
  background-color: config.$green-dark;
  width: 70vw;
  max-width: config.$navWidth;
  z-index: config.$mobileNavZIndex;
  top: config.$header-height;
  padding: 25px 0;
  overflow: hidden;
  transition: all 250ms ease;
  height: calc(var(--app-height) - #{config.$header-height});
  position: fixed;
  transform: translateX(-100%);

  &--icons-only {
    max-width: 75px;
  }

  @include config.md {
    transform: translateX(0%);
  }

  &--mobile-active {
    transform: translateX(0%);
  }

  @include config.md {
    height: var(--app-height);
    position: static;
    padding-top: 0;
  }

  &__list {
    list-style: none;
    @include config.md {
      margin-top: 30px;
    }
  }

  &__logo {
    display: none;
    height: config.$header-height;
    justify-content: center;
    align-items: center;

    @include config.md {
      display: flex;
    }

    svg {
      width: 100%;
    }
  }
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  color: config.$white;
  height: 80px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  @include config.not_last {
    margin-bottom: 20px;
  }

  &:hover,
  &.router-link-active {
    background: #CAE8D6;
    color: config.$black;
    border-radius: 10px;
    filter: drop-shadow(0px 3px 26px rgba(0, 0, 0, 0.11));

    .nav-item__icon svg {
      color: #639478;
    }
  }

  &__title {
    font-size: 12px;
    margin-top: 6px;
  }

  &__icon {
    svg {
      color: config.$white;
    }
  }
}
</style>
