<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="94.477" height="14.582" viewBox="0 0 94.477 14.582">
    <g id="Logo-01" transform="translate(-101.37 -137.618)">
      <path id="Path_1" data-name="Path 1"
            d="M103.535,137.62l.015,3.7a8.385,8.385,0,0,1-.05.958,4.764,4.764,0,0,1-.186.9l.207.193.014,5.56-2.165-.107v-11.2Zm2.4,4.179a2.371,2.371,0,0,1,2.062.872,5.064,5.064,0,0,1,.621,2.88,6.9,6.9,0,0,1-.157,1.536,3.156,3.156,0,0,1-.54,1.225,2.53,2.53,0,0,1-1.079.8,4.655,4.655,0,0,1-1.787.284c-.25,0-.542-.007-.879-.036s-.672-.058-1.015-.1a7.836,7.836,0,0,1-.986-.178,4.3,4.3,0,0,1-.808-.257l1.729-1.244a4.3,4.3,0,0,0,.615.157,5.3,5.3,0,0,0,.686.086,4.513,4.513,0,0,0,.614.007,1.484,1.484,0,0,0,.8-.213,1.105,1.105,0,0,0,.427-.686,5.741,5.741,0,0,0,.129-1.365,5.6,5.6,0,0,0-.107-1.251,1,1,0,0,0-.393-.629,1.471,1.471,0,0,0-.8-.186,1.9,1.9,0,0,0-.88.208,8.767,8.767,0,0,0-.879.536l-.157-1.022a5.689,5.689,0,0,1,.822-.729,3.712,3.712,0,0,1,.938-.51A2.94,2.94,0,0,1,105.937,141.8Z"
            transform="translate(0 -0.002)" fill="#fff"/>
      <path id="Path_2" data-name="Path 2"
            d="M213.639,199.11l1.379,4.681c.036.186.079.372.114.557s.071.372.1.557h.4l-.693,1.437h-.521a.7.7,0,0,1-.444-.15.767.767,0,0,1-.264-.379l-2.38-6.7Zm5.739,0-2.6,7.316a5.8,5.8,0,0,1-.486,1.094,3.987,3.987,0,0,1-.793.988,3.091,3.091,0,0,1-1.138.665,3.522,3.522,0,0,1-1.579.107l-.157-1.229a3.631,3.631,0,0,0,1.386-.629,2.6,2.6,0,0,0,.779-1.037l.451-1.008a5.387,5.387,0,0,0,.258-.786c.086-.307.158-.569.213-.8l1.351-4.681Z"
            transform="translate(-102.142 -57.12)" fill="#fff"/>
      <path id="Path_3" data-name="Path 3"
            d="M331.393,200.535l1.086.193a.644.644,0,0,0-.284.364.583.583,0,0,0,.036.464.6.6,0,0,0,.515.264l2.666.308a2.471,2.471,0,0,1,1.608.636,2.178,2.178,0,0,1,.464,1.515,2.332,2.332,0,0,1-.378,1.444,2.01,2.01,0,0,1-1.215.693,10.505,10.505,0,0,1-2.223.193,13.414,13.414,0,0,1-1.88-.107,3.33,3.33,0,0,1-1.172-.364,1.354,1.354,0,0,1-.6-.651,2.549,2.549,0,0,1-.171-1,1.8,1.8,0,0,1,.164-.786,1.644,1.644,0,0,1,.55-.593,4.235,4.235,0,0,1,1.086-.479l1.072-.378.772.307-.587.378c-.242.172-.436.322-.593.444a1.223,1.223,0,0,0-.321.364,1.045,1.045,0,0,0-.093.465.807.807,0,0,0,.15.521.876.876,0,0,0,.529.25,6.846,6.846,0,0,0,1.067.065,7.076,7.076,0,0,0,1.094-.065.96.96,0,0,0,.55-.243.746.746,0,0,0,.158-.5.594.594,0,0,0-.115-.407.683.683,0,0,0-.365-.2,5.886,5.886,0,0,0-.707-.121l-2.275-.25a1.785,1.785,0,0,1-.894-.307,1.324,1.324,0,0,1-.443-.608,1.381,1.381,0,0,1-.064-.722,1.475,1.475,0,0,1,.271-.665A1.18,1.18,0,0,1,331.393,200.535Zm2.2-4.145a6.778,6.778,0,0,1,1.994.236,1.91,1.91,0,0,1,1.072.736,2.833,2.833,0,0,1,.007,2.637,1.862,1.862,0,0,1-1.079.744,8.98,8.98,0,0,1-4.024,0,1.933,1.933,0,0,1-1.089-.737,2.351,2.351,0,0,1-.329-1.315,2.429,2.429,0,0,1,.329-1.322,1.909,1.909,0,0,1,1.094-.743,7.027,7.027,0,0,1,2.022-.236Zm-.014,1.337a2.168,2.168,0,0,0-1.115.207.842.842,0,0,0-.307.758.851.851,0,0,0,.307.757,2.069,2.069,0,0,0,1.115.222,1.884,1.884,0,0,0,1.094-.222.9.9,0,0,0,.293-.757.872.872,0,0,0-.293-.758,2,2,0,0,0-1.1-.207Zm4.245-1.143-.122.924-1.93.25-.529-1.172Z"
            transform="translate(-212.228 -54.593)" fill="#fff"/>
      <path id="Path_4" data-name="Path 4"
            d="M459.056,137.62l.014,3.857a8.77,8.77,0,0,1-.064,1.086,8.068,8.068,0,0,1-.15.865l.2.186v5.611H456.89v-11.6Zm2.873,4.179a1.791,1.791,0,0,1,2.044,2.009v5.417h-2.18v-4.745a1.026,1.026,0,0,0-.164-.665.785.785,0,0,0-.594-.186,2.942,2.942,0,0,0-.965.179,8.33,8.33,0,0,0-1.229.569l-.193-1.129a6.5,6.5,0,0,1,1.635-1.072A4.008,4.008,0,0,1,461.929,141.8Z"
            transform="translate(-330.242 -0.002)" fill="#fff"/>
      <path id="Path_5" data-name="Path 5"
            d="M606.44,138.447a1.824,1.824,0,0,0-.629-.607,1.714,1.714,0,0,0-.865-.222,1.759,1.759,0,0,0-.872.222,1.855,1.855,0,0,0-.64.607,1.681,1.681,0,0,0,0,1.716,1.732,1.732,0,0,0,.64.607,1.765,1.765,0,0,0,.872.229,1.741,1.741,0,0,0,1.493-.843,1.614,1.614,0,0,0,.236-.858A1.589,1.589,0,0,0,606.44,138.447Zm-.958,1.416a.748.748,0,0,1-.55.213.732.732,0,0,1-.743-.764.722.722,0,0,1,.743-.772.772.772,0,0,1,.55.207.761.761,0,0,1,.213.565A.74.74,0,0,1,605.483,139.863Z"
            transform="translate(-466.149)" fill="#fff"/>
      <path id="Path_6" data-name="Path 6"
            d="M699.674,196.583l.243,1.436.178.186v5.611H697.93v-7.232Zm3.288-.193a1.793,1.793,0,0,1,2.051,2.009v5.417h-2.165v-4.745a.937.937,0,0,0-.179-.665.814.814,0,0,0-.6-.186,2.843,2.843,0,0,0-.965.186,11.051,11.051,0,0,0-1.229.565l-.1-1.215a6.007,6.007,0,0,1,1.586-1.007A4.014,4.014,0,0,1,702.962,196.39Z"
            transform="translate(-554.144 -54.593)" fill="#fff"/>
      <path id="Path_7" data-name="Path 7"
            d="M820.734,141.8a5.064,5.064,0,0,1,1.472.222,3.356,3.356,0,0,1,1.251.715l-.15,1c-.329-.1-.629-.186-.886-.264a3.286,3.286,0,0,0-.929-.114,1.448,1.448,0,0,0-.729.158.977.977,0,0,0-.407.629,5.6,5.6,0,0,0-.136,1.394,5.05,5.05,0,0,0,.129,1.308.986.986,0,0,0,.393.614,1.28,1.28,0,0,0,.693.171,2.2,2.2,0,0,0,.657-.085,2.134,2.134,0,0,0,.557-.25c.186-.107.4-.25.629-.415l.164,1.072a3.706,3.706,0,0,1-1.143,1.029,3.316,3.316,0,0,1-1.73.415,2.112,2.112,0,0,1-1.979-1,5.652,5.652,0,0,1-.589-2.85,6.8,6.8,0,0,1,.3-2.265,2.021,2.021,0,0,1,.915-1.143A3.167,3.167,0,0,1,820.734,141.8Zm4.5-4.179v11.6h-1.715l-.222-1.339-.229-.157v-4.743l.086-.186c-.028-.307-.05-.607-.064-.914s-.022-.622-.022-.944V137.62Z"
            transform="translate(-665.677 -0.002)" fill="#fff"/>
      <path id="Path_8" data-name="Path 8"
            d="M941.737,199.064l-2.388,6.718a.8.8,0,0,1-.272.372.69.69,0,0,1-.427.142h-1.9a.689.689,0,0,1-.427-.142.8.8,0,0,1-.272-.372l-2.388-6.718h2.3l1.33,4.523c.057.213.114.436.164.658s.092.436.142.64h.193c.058-.213.115-.427.164-.651s.114-.436.172-.651l1.3-4.523Z"
            transform="translate(-773.123 -57.073)" fill="#fff"/>
      <path id="Path_9" data-name="Path 9"
            d="M1060.644,199.392l-.014,1.2h-3.18a.7.7,0,0,0-.55.193.759.759,0,0,0-.164.508v.356a.733.733,0,0,0,.2.557.887.887,0,0,0,.629.2,2.433,2.433,0,0,0,.829-.157,3.9,3.9,0,0,0,.886-.465,4.014,4.014,0,0,0,.782-.693l.071,1a3.666,3.666,0,0,1-.393.55,4.72,4.72,0,0,1-.665.622,3.555,3.555,0,0,1-.936.522,3.49,3.49,0,0,1-1.244.207,2.979,2.979,0,0,1-1.172-.229,1.852,1.852,0,0,1-.822-.693,1.987,1.987,0,0,1-.3-1.122v-.751a1.616,1.616,0,0,1,.58-1.315,2.413,2.413,0,0,1,1.586-.486Zm-1.92-3a4.316,4.316,0,0,1,1.529.243,1.8,1.8,0,0,1,.944.822,3.278,3.278,0,0,1,.321,1.586v1.379h-2.179v-1.394a.97.97,0,0,0-.243-.743,1.3,1.3,0,0,0-.879-.236c-.372,0-.836.014-1.386.036s-1.116.058-1.687.093l-.193-1.429c.343-.071.736-.129,1.187-.179s.9-.092,1.357-.121.87-.058,1.227-.058Zm4.288,0a4.257,4.257,0,0,1,2.616.622,2.293,2.293,0,0,1,.8,1.858,2.427,2.427,0,0,1-.229,1.108,1.371,1.371,0,0,1-.743.651,3.657,3.657,0,0,1-1.387.213h-3.9v-1.214h3.309a.83.83,0,0,0,.671-.208.959.959,0,0,0,.165-.6.82.82,0,0,0-.284-.7,1.656,1.656,0,0,0-.943-.208,2.293,2.293,0,0,0-.958.164.9.9,0,0,0-.465.615,5.033,5.033,0,0,0-.136,1.315,4.7,4.7,0,0,0,.165,1.4,1.094,1.094,0,0,0,.55.679,2.482,2.482,0,0,0,1.086.193c.336,0,.743-.014,1.222-.036s.979-.071,1.473-.128l.207,1.336a4.476,4.476,0,0,1-.95.321,8.807,8.807,0,0,1-1.094.165c-.372.036-.744.05-1.1.05a5.042,5.042,0,0,1-2.194-.4,2.453,2.453,0,0,1-1.194-1.236,5.4,5.4,0,0,1-.364-2.137,5.719,5.719,0,0,1,.364-2.251,2.3,2.3,0,0,1,1.179-1.2,5.215,5.215,0,0,1,2.126-.372Z"
            transform="translate(-885.454 -54.593)" fill="#fff"/>
      <path id="Path_10" data-name="Path 10"
            d="M1242.114,196.583l.265,1.436.208.186v5.611h-2.166v-7.232Zm3.531-.193-.206,2.009h-.6a4.408,4.408,0,0,0-1.066.142c-.372.1-.843.25-1.408.443l-.142-1.215a5.913,5.913,0,0,1,1.536-1.022,3.6,3.6,0,0,1,1.508-.355Z"
            transform="translate(-1058.063 -54.593)" fill="#fff"/>
      <path id="Path_11" data-name="Path 11"
            d="M1330.436,137.62l.014,5.586a8.835,8.835,0,0,1-.057,1,8.147,8.147,0,0,1-.179.95c.071.308.122.615.164.929a8.2,8.2,0,0,1,.058.886v2.259h-2.166V137.62Zm1.007,6.916v1.322h-1.679v-1.322Zm3.824-2.544-1.887,2.516a2.457,2.457,0,0,1-.336.4c-.142.122-.25.213-.343.279v.057a1.472,1.472,0,0,1,.343.307,3.155,3.155,0,0,1,.336.465l2.133,3.209H1333.1l-2.473-4.074,2.223-3.158Z"
            transform="translate(-1139.666 -0.002)" fill="#fff"/>
      <path id="Path_12" data-name="Path 12"
            d="M589.205,197.443a1.862,1.862,0,0,0-.959-.822,4.417,4.417,0,0,0-1.536-.243h-.827a4.417,4.417,0,0,0-1.536.243,1.862,1.862,0,0,0-.958.822,3.194,3.194,0,0,0-.329,1.586V203.8h2.129l.053-4.789a.97.97,0,0,1,.243-.743,1.335,1.335,0,0,1,.814-.213,1.318,1.318,0,0,1,.815.213.969.969,0,0,1,.242.743l.011,4.789h2.169V199.03A3.224,3.224,0,0,0,589.205,197.443Z"
            transform="translate(-447.441 -54.582)" fill="#fff"/>
    </g>
  </svg>

</template>

<script>
export default {};
</script>
