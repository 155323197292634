<template>
  <Box class="post" hover :label="post?.name" @click="$router.push(link)">
    <div class="box-body">
      <h2 class="post__title">{{ post.title }}</h2>
      <div class="post__body"
           v-html="post.body"
           ref="body"
           :class="{ 'post__body--shadow': showShadow }"
      />
      <div class="post__hr"/>
      <div class="post__author">
        <p class="post__author-name">Af {{ post.user.full_name }}</p>
        <p class="post__author-date">{{ post.updatedAt }}</p>
        <div class="interactions">
          <LikeCounter
            type="forum-post"
            :id="post.id"
            :liked="post.liked"
            :count="post.likeCounter"
            compact
            @liked="onLike"
          />
          <CommentCount :count="post.totalComments" />
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from '@/components/Box.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  emits: ['liked'],
  components: {
    Box,
    LikeCounter,
    CommentCount
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showShadow: false
    };
  },
  computed: {
    link() {
      return {
        name: 'forum.show',
        params: {
          id: this.post?.id
        }
      };
    }
  },
  mounted() {
    if (this.$refs?.body?.getBoundingClientRect()?.height > 150) {
      this.showShadow = true;
    }
  },
  methods: {
    onLike(data) {
      this.$emit('liked', data);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.interactions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.post {
  position: relative;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__hr {
    margin: 1rem 0;
    height: 1px;
    background-color: config.$grey;
  }

  &__author {
    font-size: 0.9em;
    color: #8C8C8C;
    display: grid;
    font-family: config.$font-headline;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    @include config.lg {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    &-name {
      grid-area: 1 / 1 / 2 / 2;
    }

    &-date {
      grid-area: 2 / 1 / 3 / 3;

      @include config.lg {
        grid-area: 1 / 2 / 2 / 3;
        text-align: center;
      }
    }

    &-count {
      grid-area: 1 / 2 / 2 / 3;
      text-align: right;

      @include config.lg {
        grid-area: 1 / 3 / 2 / 4;
      }

      svg {
        font-size: 1.1rem;
      }
    }
  }

  &__body {
    max-height: 158px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    content: '';

    &.post__body--shadow::after {
      content: '';
      width: 100%;
      height: 5ch;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(0, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))
    }
  }

  .box-body {
    @include config.lg {
      padding: 1.5rem 2rem;
    }
  }
}
</style>
