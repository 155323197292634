<template>
  <BaseButton class="sidebar-toggle" :class="{'sidebar-toggle--hide':showSidebar}" icon="filter"
              @click.prevent="showSidebar = !showSidebar">
    <font-awesome-icon icon="filter"></font-awesome-icon>
    <span>{{ openSidebarLabel }}</span>
  </BaseButton>
  <div class="sidebar__underlay" v-if="showSidebar" @click.prevent="showSidebar = false"></div>
  <div class="sidebar" :class="{'sidebar--active': showSidebar}">
    <div class="sidebar__content">
      <slot/>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'BaseSidebar',
  components: { BaseButton },
  props: {
    openSidebarLabel: {
      type: String,
      default: 'Vis filtre'
    }
  },
  data() {
    return {
      showSidebar: false
    };
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.sidebar {
  &__content {
    > * {
      @include config.not_last {
        &::after {
          content: '';
          display: block;
          height: 1px;
          background: config.$grey;
          margin: 1.3rem 0;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.sidebar-toggle {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  width: 200px;
  transition: all 150ms ease;
  opacity: 1;
  z-index: config.$sidebarZIndex;

  &--hide {
    transform: translateX(-50%) translateY(50%);
    opacity: 0;
  }

  span {
    margin-left: 5px;
  }

  @include config.md {
    display: none !important;
  }
}

.sidebar__underlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(#{config.$sidebarZIndex} - 1);
  background-color: rgba(0,0,0,.1);

  @include config.md {
    display: none !important;
  }
}

.sidebar {
  font-size: 14px;
  position: fixed;
  top: config.$header-height;
  left: 0;
  width: 280px;
  height: 100%;
  background: white;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: auto;
  max-height: calc(100vh - #{config.$header-height});
  transform: translateX(-100%);
  transition: transform 200ms linear;
  will-change: revert;
  z-index: config.$sidebarZIndex;

  @include config.md {
    grid-area: sidebar;
    position: sticky;
    top: 0;
    width: 250px;
    transform: none;
    padding-top: 50px;
  }

  &--active {
    transform: translateX(0);
  }
}
</style>
