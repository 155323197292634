<template>
  <HeroLoader :loading="isLoadingPage">
    <form @submit.prevent="onSubmit" class="grid grid--start">
      <Box headline="Indlæg" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Overskrift">
            <BaseInput v-model="form.title"/>
          </BaseField>
          <BaseField label="Publiceringsdato">
            <BaseInput type="date"
                       v-model="form.publishedAt"
                       :max="maxDate"
            />
          </BaseField>
          <BaseField label="Manchet">
            <BaseTextarea :autosize="true" v-model="form.manchet" :max-length="200"/>
          </BaseField>
          <BaseField label="Indhold">
            <Editor v-model="form.body"/>
          </BaseField>
          <BaseField>
            <BaseButton :label="buttonLabel"/>
          </BaseField>
        </div>
      </Box>
      <div class="col-12 col-xl-6">
        <div class="grid">
          <Box headline="Links (Video + eksterne links)" class="col-12">
            <div class="box-body">
              <div class="single-link" v-for="(link, linkIndex) in links" :key="linkIndex">
                <BaseColumn>
                  <BaseField label="Type" style="max-width: 40%">
                    <BaseSelect v-model="link.type">
                      <option value="youtube">Youtube Video</option>
                      <option value="vimeo">Vimeo Video</option>
                      <option value="link">Eksternt link</option>
                    </BaseSelect>
                  </BaseField>
                  <BaseField label="Overskrift">
                    <BaseInput v-model="link.title"/>
                  </BaseField>
                </BaseColumn>
                <BaseField label="Link" :help="getLinkHelpText(link)">
                  <BaseInput v-model="link.link"/>
                </BaseField>
                <div class="single-link__remove" @click.prevent="onRemoveLink(linkIndex)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </div>
              </div>
              <div style="width: 100%; text-align: right;">
                <BaseButton type="info" size="small" @click.prevent="onAddNewLink">
                  Tilføj nyt link
                </BaseButton>
              </div>
            </div>
          </Box>
          <Box headline="Billeder" class="col-12">
            <div class="box-body">
              <div class="single-link" v-for="(image, uploadIndex) in images" :key="uploadIndex">
                <BaseColumn>
                  <BaseField label="" style="width: 160px;">
                    <BaseImage :src="image.fullImagePath"/>
                  </BaseField>
                  <BaseField label="Kort billedtekst">
                    <BaseInput v-model="image.description"/>
                  </BaseField>
                </BaseColumn>
                <div class="single-link__remove" @click.prevent="onRemoveImage(uploadIndex)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </div>
              </div>
              <BaseField label="Upload billeder">
                <FileUpload @upload="onUpload" multiple></FileUpload>
              </BaseField>
            </div>
          </Box>
        </div>
      </div>
    </form>
  </HeroLoader>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import FileUpload from '@/containers/FileUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import AdminCompanyBlogService from '@/services/Admin/AdminCompanyBlogService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseImage from '@/components/BaseImage.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import dayjs from 'dayjs';
import Editor from '@/containers/Editor.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import LinkType from '@/helpers/LinkType';

export default {
  props: {
    editId: {
      type: Number,
      default: null
    }
  },
  emits: ['created', 'updated'],
  components: {
    BaseTextarea,
    BaseSelect,
    Editor,
    HeroLoader,
    BaseImage,
    BaseButton,
    BaseColumn,
    BaseInput,
    FileUpload,
    BaseField,
    Box
  },
  data() {
    return {
      minDate: dayjs()
        .format('YYYY-MM-DD'),
      maxDate: dayjs()
        .add(10, 'year')
        .format('YYYY-MM-DD'),
      links: [],
      images: [],
      form: {
        title: '',
        manchet: '',
        body: '',
        publishedAt: dayjs()
          .startOf('day')
          .format('YYYY-MM-DD')
      }
    };
  },
  computed: {
    buttonLabel() {
      return this.editId ? 'Gem indlæg' : 'Opret indlæg';
    }
  },
  setup() {
    const { company } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    if (this.editId) {
      await this.loadArticle();
    }
    this.stopPageLoading();
  },
  methods: {
    onRemoveLink(linkIndex) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette linket?')) {
        this.links = this.links.filter((link, i) => i !== linkIndex);
      }
    },
    onRemoveImage(imageIndex) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette billedet?')) {
        this.images = this.images.filter((link, i) => i !== imageIndex);
      }
    },
    getEmptyLink() {
      return {
        type: LinkType.YOUTUBE,
        title: '',
        link: ''
      };
    },
    onAddNewLink() {
      this.links.push(this.getEmptyLink());
    },
    getLinkHelpText(link) {
      switch (link.type) {
        case LinkType.LINK:
          return 'Link vil automatisk åbnes i nyt vindue';
        case LinkType.YOUTUBE:
          return 'Eksempel: https://www.youtube.com/watch?v=peMRaht4Cwc';
        case LinkType.VIMEO:
          return 'Eksempel: https://vimeo.com/237527670';
        default:
          return '';
      }
    },
    onSubmit() {
      if (!this.editId) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    getRequestObject() {
      return {
        article: {
          title: this.form.title,
          manchet: this.form.manchet,
          body: this.form.body,
          published_at: this.form.publishedAt
        },
        links: this.links.map((link) => ({
          title: link.title,
          type: link.type,
          link: link.link
        })),
        images: this.images.map((image) => ({
          path: image.imagePath,
          description: image.description
        }))
      };
    },
    async loadArticle() {
      Message.clear();
      this.startLoading('Henter indlæg');

      try {
        const payload = await AdminCompanyBlogService.show(this.company.id, this.editId);
        const article = payload?.data?.data;
        if (article) {
          this.form.title = article.title;
          this.form.body = article.body;
          this.form.manchet = article.manchet;
          this.form.publishedAt = dayjs(article.publishedAt)
            .format('YYYY-MM-DD');

          this.images = article?.images?.map((image) => ({
            fullImagePath: image.path,
            imagePath: image.imagePath,
            description: image.description
          }));
          this.links = article?.links?.map((link) => ({
            type: link.type,
            title: link.title,
            link: link.link
          }));
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer indlæg');

      try {
        const payload = await AdminCompanyBlogService.update(this.company.id, this.editId, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('updated');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter indlæg');

      try {
        const payload = await AdminCompanyBlogService.store(this.company.id, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('created');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onUpload(formData) {
      Message.clear();
      this.startLoading('Uploader billede');
      try {
        const payload = await AdminCompanyBlogService.upload(this.company.id, formData);
        Message.httpSuccess(payload);

        // eslint-disable-next-line no-unused-expressions
        payload?.data?.data?.forEach((image) => {
          this.images.push({
            fullImagePath: image.fullImagePath,
            imagePath: image.imagePath,
            description: ''
          });
        });
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.single-link {
  background: #eee;
  padding: 10px;
  border-radius: config.$field-border;
  position: relative;

  @include config.not_last {
    margin-bottom: 10px;
  }

  &__remove {
    background: config.$brown;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

.list {
  list-style: none;

  li {
    margin: 5px 0;
    padding: 10px;
    font-size: 0.9em;
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}
</style>
