import Forum from '@/views/Forum/Forum.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import ForumPost from '@/views/Forum/ForumPost.vue';
import Modules from '@/helpers/Modules';
import ForumCreate from '@/views/Forum/ForumCreate.vue';

export default [
  {
    path: '/forum',
    name: 'forum',
    component: Forum,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.FORUM
    }
  },
  {
    path: '/forum/:id',
    name: 'forum.show',
    component: ForumPost,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.FORUM
    },
    props: true
  },
  {
    path: '/forum/opret',
    name: 'forum.create',
    component: ForumCreate,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.FORUM
    },
    props: true
  }
];
