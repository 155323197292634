<template>
  <div ref="map"></div>
</template>

<script>
/** eslint-disabled */
import MapStyle from '@/helpers/MapStyle.json';
import MapHelper from '@/helpers/MapHelper';
// eslint-disable-next-line no-unused-vars
import MarkerClusterer from '@googlemaps/markerclustererplus';
import _ from 'lodash';

let markers = [];
let cluster = null;
let mapInstance = null;

export default {
  props: {
    locations: Array,
    infobox: Function,
    center: {
      type: Object,
      default: () => ({
        lat: 53.470038,
        lng: 7.382813
      })
    },
    useCluster: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zoom: 7,
      map: null,
      infoWindow: null,
      google: null
    };
  },
  computed: {
    hasInfobox() {
      return typeof this.infobox === 'function';
    }
  },
  async mounted() {
    if (!this.$refs.map) {
      return;
    }

    mapInstance = new window.google.maps.Map(this.$refs.map, {
      center: this.center,
      streetViewControl: false,
      scrollwheel: false,
      disableDefaultUI: true,
      zoom: this.zoom,
      minZoom: this.zoom,
      maxZoom: this.zoom + 10,
      zoomControl: true,
      styles: MapStyle,
      restriction: {
        latLngBounds: new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(53.470038, 7.382813),
          new window.google.maps.LatLng(58.101105, 16.303711)
        )
      }
    });

    if (this.hasInfobox) {
      this.setupInfoWindow();
      window.google.maps.event.addListener(mapInstance, 'click', (() => {
        this.infoWindow.close();
        this.resetAllMarkerIcons();
      }));
    }

    this.setupMarkers();
  },
  methods: {
    addProjectMarker(data) {
      const marker = new window.google.maps.Marker({
        position: data.location,
        icon: MapHelper.markerImageSmall
      });
      if (!this.useCluster) {
        marker.setMap(mapInstance);
      }
      if (this.hasInfobox) {
        marker.addListener('click', (() => {
            this.onMarkerClick(_.cloneDeep(data), marker);
          }
        ));
      }
      markers.push(marker);
    },
    clearMarkers() {
      if (this.useCluster && cluster) {
        cluster.clearMarkers();
      }
      markers = [];
    },
    setupMarkers() {
      this.locations.map(this.addProjectMarker);
      if (this.useCluster) {
        cluster = new MarkerClusterer(mapInstance, markers, {
          imagePath: '/img/cluster',
          imageSizes: [1],
          imageExtension: 'svg',
          ignoreHidden: true,
          styles: MapHelper.clusterStyle,
          gridSize: 42,
          clusterClass: 'projectMapCluster'
        });
      }
      // this.cluster.repaint();
    },
    resetAllMarkerIcons() {
      markers.forEach(((e) => e.setIcon(MapHelper.markerImageSmall)));
    },
    onMarkerClick(data, marker) {
      this.resetAllMarkerIcons();
      marker.setIcon(MapHelper.markerSvgIconSelected);
      this.infoWindow.setContent(this.infobox(data));
      this.infoWindow.open(mapInstance, marker);
    },
    setupInfoWindow() {
      this.infoWindow = new window.google.maps.InfoWindow({
        content: ''
      });
    }
  },
  watch: {
    locations() {
      this.$nextTick(() => {
        this.clearMarkers();
        this.setupMarkers();
      });
    }
  }
};
</script>

<style lang="scss">
.projectMapCluster {
  div[aria-label] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: inherit;
  }
}
</style>
