<template>
  <div class="dashboard">
    <MainHeader class="dashboard__header"></MainHeader>
    <MainNavigation class="dashboard__nav"/>
    <div class="dashboard__nav-underlay" v-if="isMobileActive" @click="hideOnMobile"></div>
    <main class="dashboard__main" id="main">
      <router-view></router-view>
<!--      <router-view v-slot="{ Component, route }">-->
<!--        <transition :name="route.meta.transition || 'fade'">-->
<!--          <component :is="Component" />-->
<!--        </transition>-->
<!--      </router-view>-->
    </main>
  </div>
</template>
<script>
import MainHeader from '@/components/MainHeader.vue';
import useAuth from '@/composables/useAuth';
import MainNavigation from '@/components/MainNavigation.vue';
import useMainNavigation from '@/composables/useMainNavigation';

export default {
  components: {
    MainNavigation,
    MainHeader
  },
  data() {
    return {
      test: true,
      showMobile: false,
      show: true,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  setup() {
    const {
      logout,
      user
    } = useAuth();

    const {
      isMobileActive,
      hideOnMobile
    } = useMainNavigation();
    return {
      isMobileActive,
      hideOnMobile,
      user,
      onLogout: logout
    };
  }
  // watch: {
  //   // eslint-disable-next-line func-names
  //   '$route.name': function () {
  //     setTimeout(() => {
  //       this.hideOnMobile();
  //     }, 1);
  //   }
  // }
};
</script>
<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.dashboard {
  display: grid;
  height: 100%;
  grid-template-areas:
    "head head"
    "main main";
  grid-template-rows: config.$header-height 1fr;
  grid-template-columns: auto 1fr;

  @include config.md {
    grid-template-areas:
    "nav head"
    "nav main";
  }

  &__header {
    grid-area: head;
  }

  &__nav {
    grid-area: nav;
  }

  &__nav-underlay {
    position: fixed;
    top: config.$header-height;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: calc(#{config.$mobileNavZIndex} - 1);
    background-color: rgba(0,0,0,.1);

    @include config.lg {
      display: none !important;
    }
  }

  &__main {
    grid-area: main;
    background-color: #F4F4F4;
    height: calc(var(--app-height) - #{config.$header-height});
    overflow: auto;
    width: 100%;
    display: grid;
    grid-template-areas: "sidebar content";
    grid-template-columns: auto 1fr;
    position: relative;
  }
}
</style>
