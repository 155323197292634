<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Cases">
        <BaseButton @click="showForm = true" v-if="!showForm">Opret ny case</BaseButton>
        <a href="#" @click.prevent="goBackToListView" v-if="showForm">Tilbage til visningen</a>
      </BasePageHeader>

      <div v-if="!showForm && projects.length > 0" class="flex" style="gap: 0.5em; align-items: center">
        <font-awesome-icon icon="thumbtack"></font-awesome-icon>
        <h4 v-if="pinnedProjects.length > 0">{{ `Du har udvalgt ${pinnedProjects.length} af ${maxPinnedProjects} cases der skal vises offentligt på portalen` }}</h4>
        <h4 v-else>{{ `Udvælg op til ${maxPinnedProjects} cases der skal vises offentligt på portalen` }}</h4>
      </div>

      <AdminProjectForm v-if="showForm" @created="onCreated" @updated="onUpdated" :edit-id="selectedProjectId" />

      <BaseSection headline="Eksisterende cases" style="margin-top: 3rem;" v-if="!showForm">
        <div class="grid" v-if="projects.length">
          <ProjectCard v-for="project in projects"
                            :key="project.id"
                            class="col-12 col-sm-6"
                            :image="project.images[0].path"
                            :address="project.location.full_inline"
                            :title="project.title"
                            :description="project.description"
                            :partner-description="project.partnerDescription"
                            :video-link="project.videoLink"
                            :quality-description="project.qualityDescription"
                            :pinned="project.pinned"
                            type="edit"
          >
            <div class="flex space-between">
              <div class="flex" style="gap: 1rem;">
                <BaseButton type="info" @click.prevent="onEdit(project)">
                  <font-awesome-icon icon="pen"></font-awesome-icon>
                </BaseButton>
                <BaseButton type="danger" @click.prevent="onDelete(project)">
                  <font-awesome-icon icon="trash"></font-awesome-icon>
                </BaseButton>
              </div>

              <button :class="['pin', {'pin--pinned': project.pinned}]" @click.prevent="onPin(project)">
                <font-awesome-icon icon="thumbtack"></font-awesome-icon>
              </button>
            </div>

          </ProjectCard>
        </div>
        <p v-else style="font-size: 0.9em">
          Der er ikke oprettet nogen cases.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
import BaseSection from '@/components/BaseSection.vue';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import AdminCompanyProjectService from '@/services/Admin/AdminCompanyProjectService';
import HeroLoader from '@/components/HeroLoader.vue';
import AdminProjectForm from '@/views/Admin/components/AdminProjectForm.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import ProjectCard from '@/components/ProjectCard.vue';

export default {
  components: {
    ProjectCard,
    BaseButton,
    BasePageHeader,
    AdminProjectForm,
    HeroLoader,
    BaseSection
  },
  data() {
    return {
      projects: [],
      showForm: false,
      selectedProjectId: null
    };
  },
  computed: {
    pinnedProjects() {
      return this.projects.filter((project) => project.pinned);
    }
  },
  setup() {
    const {
      company,
      maxPinnedProjects
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company,
      maxPinnedProjects
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyCases();
    this.stopPageLoading();
  },
  methods: {
    onEdit(project) {
      this.selectedProjectId = project.id;
      this.showForm = true;
    },
    async onDelete(project) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Vil du slette denne case?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter case');

      try {
        const payload = await AdminCompanyProjectService.destroy(this.company.id, project.id);
        await this.loadCompanyCases();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onPin(project) {
      // eslint-disable-next-line no-alert
      this.startLoading('Pinner case');

      try {
        const payload = await AdminCompanyProjectService.pin(this.company.id, project.id);
        this.projects.filter((item) => item.id === project.id)[0].pinned = !!payload?.data?.data;
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreated() {
      this.projects = [];
      this.showForm = false;
      await this.loadCompanyCases();
    },
    async onUpdated() {
      this.projects = [];
      this.showForm = false;
      await this.loadCompanyCases();
    },
    async loadCompanyCases() {
      Message.clear();
      this.startLoading('Henter cases');

      try {
        const payload = await AdminCompanyProjectService.all(this.company.id);
        this.projects = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    goBackToListView() {
      this.showForm = false;
      this.selectedProjectId = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
.pin {
  outline: solid;
  color: config.$green-dark;
  outline-color: config.$green-dark;
  outline-width: 1px;
  border-radius: 8px;
  height: 2.5em;
  padding: 0 2em;
  display: flex;
  align-items: center;
  cursor: pointer;

  &--pinned {
    background-color: config.$green-dark;
    color: white;
  }
}
</style>
