<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Events">
        <BaseButton @click="showForm = true" v-if="!showForm">Opret nyt event</BaseButton>
        <a href="#" @click.prevent="goBackToListView" v-if="showForm">Tilbage til visningen</a>
      </BasePageHeader>

      <AdminEventForm v-if="showForm" @created="onCreated" @updated="onUpdated" :edit-id="selectedProjectId"/>

      <BaseSection css="" headline="Kommende events" style="margin-top: 3rem;" v-if="!showForm">
        <div class="grid" v-if="events.length > 0">
          <EventCard
            v-for="event in events"
            :key="event.id"
            :id="event.id"
            class="col-12 col-sm-6 col-xl-4"
            :image-path="event.image"
            :title="event.title"
            :address="event.location.full_html"
            :sticker="getEventSticker(event)"
            :timespan="event.meta.timespan"
            :start-at="event.startAt"
            :like-counter="event.likeCounter"
            :liked="event.liked"
            :total-comments="event.totalComments"
          >
            <div style="display: flex; gap: 1rem;">
              <BaseButton type="info" style="margin-right: 1rem;" @click.stop="onEdit(event)">
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </BaseButton>
              <BaseButton type="danger" @click.stop="onDelete(event)">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </BaseButton>
            </div>
          </EventCard>
        </div>
        <p v-else style="font-size: 0.9em">
          Der er ikke oprettet nogen events.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
import BaseSection from '@/components/BaseSection.vue';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import AdminCompanyEventService from '@/services/Admin/AdminCompanyEventService';
import HeroLoader from '@/components/HeroLoader.vue';
import AdminEventForm from '@/views/Admin/components/AdminEventForm.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import EventCard from '@/components/EventCard.vue';
import dayjs from 'dayjs';

export default {
  components: {
    EventCard,
    BaseButton,
    BasePageHeader,
    AdminEventForm,
    HeroLoader,
    BaseSection
  },
  data() {
    return {
      events: [],
      showForm: false,
      selectedProjectId: null
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyCases();
    this.stopPageLoading();
  },
  methods: {
    getEventSticker(event) {
      if (event.isApproved && dayjs(event.approvedAt).isAfter(dayjs())) {
        return `Er godkendt. Vises fra d. ${dayjs(event.approvedAt).format('MM-DD-YYYY')}`;
      }
      return event.isApproved ? '' : 'Afventer bekræftelse inden publicering';
    },
    onEdit(event) {
      this.selectedProjectId = event.id;
      this.showForm = true;
    },
    async onDelete(event) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Vil du slette dette event?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter event');

      try {
        const payload = await AdminCompanyEventService.destroy(this.company.id, event.id);
        await this.loadCompanyCases();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreated() {
      this.events = [];
      this.showForm = false;
      await this.loadCompanyCases();
    },
    async onUpdated() {
      this.events = [];
      this.showForm = false;
      await this.loadCompanyCases();
    },
    async loadCompanyCases() {
      Message.clear();
      this.startLoading('Henter events');

      try {
        const payload = await AdminCompanyEventService.all(this.company.id);
        this.events = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    goBackToListView() {
      this.showForm = false;
      this.selectedProjectId = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

</style>
