export default {
  all(commentableType, commentableId) {
    return window.axios.get(`/api/comments/${commentableType}/${commentableId}`);
  },
  store(commentableType, commentableId, comment, parentCommentId = null) {
    return window.axios.post(`/api/comments/${commentableType}/${commentableId}`, {
      comment,
      comment_id: parentCommentId
    });
  }
};
