<template>
  <component
    :is="tag"
    class="button"
    :class="[buttonType, buttonFull, buttonFormat, buttonSize]"
    :disabled="loading"
  >
    {{ label }}
    <slot/>
    <span class="button__icon" v-if="loading">
      <font-awesome-icon icon="spinner" spin/>
    </span>
  </component>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    },
    type: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String
    },
    format: {
      type: String,
      default: 'rounded'
    },
    loading: Boolean,
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonType() {
      if (!this.type) {
        return null;
      }
      return `button--${this.type}`;
    },
    buttonFormat() {
      if (this.format === 'square') {
        return 'button--square';
      }

      return '';
    },
    buttonFull() {
      return this.full ? 'button--full' : null;
    },
    buttonSize() {
      if (this.size === 'large') {
        return 'button--large';
      }
      if (this.size === 'small') {
        return 'button--small';
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use 'sass:color';
@use "~@/styles/config/_index.scss" as config;

$_variations: (
  "primary": config.$brown,
  "danger": config.$brown-dark,
  'info': config.$green-dark,
  'success': config.$green
);

.button {
  background-color: config.$grey;
  border-radius: config.$field-border;
  cursor: pointer;
  font-family: config.$font-headline;
  height: config.$field-height;
  padding: 0 2em;
  text-align: center;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    background-color: config.darkenColor(config.$grey);
  }

  &:disabled {
    opacity: 0.8;
  }

  &--full {
    width: 100%;
  }

  &--large {
    height: calc(#{config.$field-height} * 1.3);
  }

  &--small {
    height: calc(#{config.$field-height} - 5px);
    font-size: 14px;
  }

  &--square {
    border-radius: 0;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  /**
  * Variations
   */
  @each $name, $color in $_variations {
    &--#{$name} {
      background-color: $color;
      color: config.$white;

      &:hover,
      &:focus:not(:active) {
        background-color: config.darkenColor($color);
      }

      &:focus:not(:active) {
        //box-shadow: 10px 10px 10px red;
      }
    }
  }
}
</style>
