<template>
  <BaseInput v-model="searchterm" />
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';

export default {
  components: { BaseInput },
  props: {
    modelValue: [String, Number]
  },
  emits: ['update:modelValue'],
  computed: {
    searchterm: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
};
</script>

<style scoped>

</style>
