// import Blog from '@/views/Blog/Blog.vue';
// import DashboardLayout from '@/layouts/DashboardLayout.vue';
import PublicTaskForm from '@/views/Public/PublicTaskForm.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';
// import BlogView from '@/views/Blog/BlogView.vue';
// import Modules from '@/helpers/Modules';

export default [
  {
    path: '/p/udbud',
    name: 'public.task',
    component: PublicTaskForm,
    meta: {
      requireAuth: false,
      layout: PublicLayout
    },
    props: (route) => ({ token: route.query.token })
  }
];
