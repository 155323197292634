<template>
  <div class="area-content" v-show="tab === 'map'">
    <div class="filter">
      <div class="filter__listview" @click="onChangeView('list')">
        <font-awesome-icon icon="list"></font-awesome-icon>
      </div>
      <Box>
        <div class="box-body filter__search">
          <BaseInput icon="search"
                     v-model="searchterm"
                     placeholder="Søg efter udbud"
                     :loading="searchtermLoading"
                     @input="onSearch"
          />
        </div>
        <div class="box-body">
          <div ref="content" class="filter__content">
            <Accordion title="Jens" v-model="showContent" bottom-nav>
              <div class="filters">
                <FilterSection v-if="regions.length > 0" label="Område">
                  <BaseField v-for="region in regions" :key="region.id">
                    <BaseCheckbox
                      v-model="selectedRegionIds"
                      :value="region.id"
                      :label="region.name"
                      @update:modelValue="onSelectRegion"
                    />
                  </BaseField>
                </FilterSection>
                <br /><br />
                <FilterSection label="Udbudstyper">
                  <BaseField v-for="taskType in taskTypes" :key="taskType.id">
                    <BaseCheckbox
                      v-model="selectedTaskTypesIds"
                      :value="taskType.id"
                      :label="taskType.name"
                      @update:modelValue="fetchTasksByFilters"
                    />
                  </BaseField>
                </FilterSection>
              </div>
            </Accordion>
          </div>
          <transition name="mode-fade" mode="out-in">
            <button class="filter__toggle" @click.prevent="onToggleContent" v-if="showContent"
                    key="on">
              <font-awesome-icon icon="arrow-up"></font-awesome-icon>
              <span>Skjul</span>
            </button>
            <button class="filter__toggle" @click.prevent="onToggleContent" v-else-if="!showContent"
                    key="off">
              <font-awesome-icon icon="arrow-down"></font-awesome-icon>
              <span>Tryk her for at filtrere i udbud</span>
            </button>
          </transition>
        </div>
      </Box>
    </div>
    <GoogleMap :display-info="true"
               :infobox="displayBox"
               :locations="locations"
               class="map"
               use-cluster
    />
  </div>
  <template v-if="tab === 'list'">
    <BaseSidebar>
      <p style="text-align: center">
        <BaseButton label="Gå til kortvisning" @click.prevent="onChangeView('map')" />
      </p>
      <FilterSection v-if="regions.length > 0" label="Område">
        <BaseField v-for="region in regions" :key="region.id">
          <BaseCheckbox
            v-model="selectedRegionIds"
            :value="region.id"
            :label="region.name"
            @change="onSelectRegion"
          />
        </BaseField>
      </FilterSection>
      <FilterSection label="Udbudstyper">
        <BaseField v-for="taskType in taskTypes" :key="taskType.id">
          <BaseCheckbox
            v-model="selectedTaskTypesIds"
            :value="taskType.id"
            :label="taskType.name"
            @update:modelValue="fetchTasksByFilters"
          />
        </BaseField>
      </FilterSection>
    </BaseSidebar>
    <div class="area-content">
      <div class="wrapper">
        <BasePageHeader headline="Udbudsportalen" class="mb-2" slot-grow>
          <BaseSearch v-model="searchterm"
                      icon="search"
                      placeholder="Søg efter udbud"
                      @input="onSearch"
          />
        </BasePageHeader>
        <HeroLoader :empty="tasks.length === 0" :loading="isLoadingPage">
          <div class="grid">
            <TaskCard v-for="task in tasks"
                      :key="task.id"
                      class="col-12 col-sm-6 col-xl-4"
                      :task="task"
            />
          </div>

          <Pagination
            v-if="showPagination"
            v-model="page"
            :pages="meta.lastPage"
            scroll-top
            @update:modelValue="onPageChange"
          />
        </HeroLoader>
      </div>
    </div>
  </template>
</template>

<script>
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import TaskService from '@/services/TaskService';
import GoogleMap from '@/components/GoogleMap.vue';
import Box from '@/components/Box.vue';
import BaseInput from '@/components/BaseInput.vue';
import Accordion from '@/components/Accordion.vue';
import FilterSection from '@/containers/FilterSection.vue';
import BaseField from '@/components/BaseField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import { debounce } from 'lodash';
import BaseSidebar from '@/components/BaseSidebar.vue';
import useZipcode from '@/composables/useZipcode';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import TaskCard from '@/containers/TaskCard.vue';
import Pagination from '@/containers/Pagination.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
    Pagination,
    TaskCard,
    HeroLoader,
    BaseSearch,
    BasePageHeader,
    BaseSidebar,
    BaseCheckbox,
    BaseField,
    FilterSection,
    Accordion,
    BaseInput,
    Box,
    GoogleMap
  },
  data() {
    return {
      searchterm: '',
      searchtermLoading: false,
      tasks: [],
      tab: 'map',
      selectedRegionIds: [],
      showContent: false,
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      },
      taskTypes: [],
      selectedTaskTypesIds: []
    };
  },
  computed: {
    locations() {
      return this.tasks.map((task) => ({
        item: task,
        location: {
          lat: task.location.geocode?.position.lat,
          lng: task.location.geocode?.position.lng
        }
      }));
    },
    showPagination() {
      return this.meta.total > this.meta.perPage;
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const {
      fetchRegions,
      regions
    } = useZipcode();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchRegions,
      regions
    };
  },
  async mounted() {
    this.startLoading();
    await this.fetchTasksByFilters();
    await Promise.all([this.fetchRegions(), this.fetchTaskTypes()]);
  },
  methods: {
    onChangeView(tab) {
      if (tab === 'map') {
        if (this.selectedTaskTypesIds.length > 0) {
          this.showContent = true;
        }
        if (this.selectedRegionIds.length > 0) {
          this.showContent = true;
        }
      }

      this.tab = tab;
    },
    onSelectRegion() {
      this.searchterm = '';
      this.fetchTasksByFilters();
    },
    onPageChange() {
      // this.fetchTasksByFilters();
    },
    displayBox(data) {
      const { item } = data;
      const route = `${this.$route.fullPath}/${item.id}`;
      return `
        <a href="${route}" class="infoWindow">
          <div class="infoWindow__img-wrapper">
              <img src="${item.images[0].image}" alt="" />
         </div>
          <h2 class="infoWindow__title">${item.title}</h2>
          <p class="infoWindow__deadline"><span>Svarfrist:</span> ${item?.meta?.deadlineDisplay}</p>
          <p class="infoWindow__location">${item.location.full_inline}</p>
          <p class="infoWindow__link">Se udbud</p>
        </a>
      `;
    },
    // eslint-disable-next-line
    onSearch: debounce(function () {
      this.searchtermLoading = true;
      this.fetchTasksByFilters();
    }, 250),
    onToggleContent() {
      this.showContent = !this.showContent;
    },
    async fetchTaskTypes() {
      try {
        const payload = await TaskService.taskTypes();
        this.taskTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async fetchTasksByFilters() {
      this.startLoading('Henter udbud');
      try {
        const payload = await TaskService.all({
          q: this.searchterm,
          relations: ['geocode', 'images'],
          regions: this.selectedRegionIds,
          types: this.selectedTaskTypesIds
        });
        this.tasks = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
        this.searchtermLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.infoWindow {
  text-decoration: none;
  max-width: 250px;
  width: 100%;
  display: block;

  &:hover {
    text-decoration: none;
  }

  &__title {
    font-size: 16px;
  }

  &__deadline {
    margin-top: 5px;
  }

  &__location {
    margin-top: 5px;
  }

  &__link {
    margin-top: 5px;
    font-weight: 500;
  }

  &__img-wrapper {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.area-content {
  padding: 0;
  position: relative;
}

.map {
  height: 100%;
}

.filter {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  max-width: 280px;
  width: 100%;

  @include config.lg {
    max-width: 370px;
  }

  &__listview {
    position: absolute;
    top: 0;
    right: -50px;
    height: 40px;
    width: 40px;
    background: config.$brown;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    color: config.$white;
    cursor: pointer;
  }

  &__search {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__content {
    .filters {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }

  &__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    opacity: 0.8;

    svg {
      cursor: pointer;
      font-size: 10px;
    }

    span {
      padding-left: 10px;
    }
  }
}

.mode-fade-enter-active, .mode-fade-leave-active {
  transition: opacity 250ms ease
}

.mode-fade-enter-from, .mode-fade-leave-to {
  opacity: 0
}
</style>
