export default {
  login({
    email,
    password
  }) {
    return window.axios.post('/api/auth/login', {
      email,
      password
    });
  },
  updatePassword(password, passwordConfirmed) {
    return window.axios.put('/api/profile/password', {
      password,
      password_confirmation: passwordConfirmed
    });
  },
  forgotPassword({ email }) {
    return window.axios.post('/api/auth/forgot-password', { email });
  },
  resetPassword({
    email,
    token,
    password,
    passwordConfirmation
  }) {
    return window.axios.post('/api/auth/reset-password', {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation
    });
  },
  logout() {
    return window.axios.post('/api/auth/logout');
  },
  ping() {
    return window.axios.post('/api/auth/ping');
  },
  self() {
    return window.axios.get('/api/auth/self');
  },
  cookieSetup() {
    return window.axios.get('/sanctum/csrf-cookie');
  },
  signup({
    company,
    user,
    subscriptionId = null
  }) {
    return window.axios.post('/api/signup', {
      company,
      user,
      subscription_id: subscriptionId
    });
  }
};
