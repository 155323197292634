<template>
  <p class="help">
    <span v-html="label" />
    <slot/>
  </p>
</template>

<script>
export default {
  props: {
    label: { type: String }
  }
};
</script>
