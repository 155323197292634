export default {
  regions(includeWith = '') {
    if (includeWith) {
      return window.axios.get(`/api/services/dk-regions?with=${includeWith}`);
    }
    return window.axios.get('/api/services/dk-regions');
  },
  zipcodes() {
    return window.axios.get('/api/services/zipcodes');
  }
};
