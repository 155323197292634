<template>
  <HeroLoader :empty="!article" :loading="isLoading" :key="$route.fullPath">
    <div class="area-content" v-if="article">
      <div class="wrapper">
        <div class="flex" style="width: 100%; justify-content: center">
          <div style="max-width: 980px; width: 100%;">
            <div class="breadcrumbs">
              <router-link :to="{ name: 'blog' }" class="breadcrumb">Tilbage til artikler</router-link>
            </div>
            <Box>
              <div class="box-body box-padding">
                <div class="flex space-between">
                  <IndustryTag :name="article.meta.publishedDisplay" class="date" />
                  <CommentCount :count="article.totalComments" />
                </div>
                <h1>{{ article.title }}</h1>
                <p class="articleDescription">{{ article.manchet }}</p>
                <hr>
                <GallerySlider v-if="article.images.length > 0" :images="article.images" :items="1"
                               :loop="true" :autoheight="true">
                </GallerySlider>

                <div v-html="article.body" class="articleDescription"></div>

                <div class="blogMeta" v-if="standardLinks.length > 0">
                  <h3>Links</h3>
                  <ul>
                    <li v-for="link in standardLinks" :key="link.id">
                      <a :href="link.link" target="_blank">
                        {{ link.title }}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="blogMeta" v-for="link in youtubeLinks" :key="link.id">
                  <h3>{{ link.title }}</h3>
                  <div class="video" v-if="link?.meta?.videoId">
                    <iframe :src="`https://www.youtube-nocookie.com/embed/${link.meta.videoId}?controls=0`"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                  <p v-else style="font-size: 14px;">Video kan ikke vises.</p>
                </div>
                <div class="blogMeta" v-for="link in vimeoLinks" :key="link.id">
                  <h3>{{ link.title }}</h3>
                  <div class="video" v-if="link?.meta?.videoId">
                    <iframe
                      :src="`https://player.vimeo.com/video/${link.meta.videoId}?title=0&byline=0&portrait=0`"
                      allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <p v-else style="font-size: 14px;">Video kan ikke vises.</p>
                </div>
                <div class="mt-2">
                  <LikeCounter
                    :type="modelType"
                    :id="article.id"
                    :count="article.likeCounter"
                    :liked="article.liked"
                    @liked="onLike"
                  />
                </div>
              </div>
            </Box>

            <div class="spacer">
              <h4 id="comments" class="subtitle">Kommentarer</h4>
              <Box class="box-padding">
                <Comment
                  :commentable-type="modelType"
                  :commentable-id="article.id"
                  @created="fetchComments"
                />

                <ul class="comments" v-if="comments.length > 0">
                  <Comments
                    v-for="comment in comments"
                    :key="comment.id"
                    :comment="comment"
                    :commentable="article"
                    :comments="comment.comments"
                    :active-comment-id="activeCommentId"
                    :fetch-comments="fetchComments"
                    :toggle-form="onSetActiveComment"
                    :depth="0"
                    :commentable-type="modelType"
                  />
                </ul>

                <p v-if="comments.length === 0" class="commentsEmpty">
                  Der er endnu ingen kommentarer til denne artikel.
                </p>
              </Box>
            </div>

            <div class="promotion spacer" v-if="article.company">
              <h4 class="subtitle">Udgivet af</h4>
              <div class="grid">
                <div class="col-12 col-lg-7">
                  <Box class="box-padding">
                    <router-link :to="companyLink" class="promotion__title">
                      <span>{{ article.company.name }}</span>
                    </router-link>
                    <p v-if="article.company.description"
                       v-html="article.company.description"
                       class="companyDescription promotion__content"
                    ></p>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </HeroLoader>
</template>

<script>
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import Message from '@/helpers/Message';
import Box from '@/components/Box.vue';
import useAuth from '@/composables/useAuth';
import Modules from '@/helpers/Modules';
import BlogService from '@/services/BlogService';
import CommentService from '@/services/CommentService';
import GallerySlider from '@/components/GallerySlider.vue';
import LinkType from '@/helpers/LinkType';
import IndustryTag from '@/components/IndustryTag.vue';
import Comment from '@/views/Comments/Comment.vue';
import Comments from '@/views/Comments/Comments.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  name: 'CompanyView.vue',
  components: {
    IndustryTag,
    GallerySlider,
    Box,
    HeroLoader,
    Comment,
    Comments,
    LikeCounter,
    CommentCount
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      comments: [],
      article: null,
      relatedArticles: [],
      modelType: 'article',
      activeCommentId: -1
    };
  },
  computed: {
    vimeoLinks() {
      return this.article?.links?.filter((link) => link.type === LinkType.VIMEO);
    },
    youtubeLinks() {
      return this.article?.links?.filter((link) => link.type === LinkType.YOUTUBE);
    },
    standardLinks() {
      return this.article?.links?.filter((link) => link.type === LinkType.LINK);
    },
    hasVideo() {
      return true;
    },
    showCompanyField() {
      return this.hasSubscription(Modules.COMPANY);
    },
    companyLink() {
      if (!this.showCompanyField) {
        return '';
      }
      return {
        name: 'companies.show',
        params: { id: this.article?.company?.id }
      };
    },
    filteredRelatedEvents() {
      return this.relatedArticles.filter((article) => article.id !== this.article.id);
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const { hasSubscription } = useAuth();

    return {
      startLoading,
      stopLoading,
      isLoading,
      startPageLoading,
      stopPageLoading,
      isLoadingPage,
      hasSubscription
    };
  },
  created() {
    this.startPageLoading();
  },
  async mounted() {
    this.startLoading('Henter indlæg');
    await this.fetchArticle();
    await this.fetchComments();
    this.stopPageLoading();
  },
  methods: {
    onLike(data) {
      this.article.likeCounter = data.count;
      this.article.liked = data.liked;
    },
    onSetActiveComment(id) {
      if (this.activeCommentId === id) {
        this.activeCommentId = -1;
        return;
      }
      this.activeCommentId = id;
    },
    async fetchComments() {
      try {
        const payload = await CommentService.all(this.modelType, this.id);
        this.comments = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    },
    async fetchArticle() {
      try {
        const payload = await BlogService.show({
          id: this.id,
          relations: ['images', 'company', 'links', 'comments']
        });
        this.article = payload?.data?.data;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
@use "~@/styles/components/_comments.scss";

.blogMeta {
  margin-top: 2.5rem;

  h3 {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 20px;
    list-style-type: square;

    li {
      padding: 5px 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        color: config.$brown;
      }
    }
  }
}

.video {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  @include config.not_last {
    margin-bottom: 1rem;
  }

  iframe {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.articleDescription {
  line-height: 1.7em;
}
.date {
  font-size: 0.85em;
  margin: 0;
}

.spacer {
  margin-top: 4rem;

  @include config.xl {
    margin-top: 3.5rem;
  }
}

.promotion {
  &__content {
    margin-top: 1rem;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    font-family: config.$font-headline;
    display: block;
  }

  &__img-wrapper {
    display: none;

    @include config.lg {
      display: block;
    }
  }
}
</style>

<style>
.tns-item {
  text-align: center;
}

.articleDescription ol,
.articleDescription ul,
.articleDescription blockquote {
  padding: revert;
  margin: revert;
}

</style>
