export default class Modules {
  static BLOG = 'blog';

  static TRADING = 'trading';

  static COMPANY = 'company';

  static EVENT = 'event';

  static FORUM = 'forum';

  static TASK = 'task';

  static USER = 'user';
}
