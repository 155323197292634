<template>
  <div class="company-details">
    <div class="wrapper-x-padding" :class="contentClass">
      <div class="company-details__info">
        <h4 class="subtitle">Kontakt</h4>
        <ul class="meta">
          <li v-if="company.contact.phone">
            <div class="meta__logo">
              <font-awesome-icon icon="phone"></font-awesome-icon>
            </div>
            <p><a :href="`tel:${company.contact.phone}`">{{ company.contact.phone }}</a></p>
          </li>
          <li v-if="company.location.full_html">
            <div class="meta__logo">
              <font-awesome-icon icon="map-marker"></font-awesome-icon>
            </div>
            <p v-html="company.location.full_html"></p>
          </li>
          <li v-if="company.contact.email">
            <div class="meta__logo">
              <font-awesome-icon icon="at"></font-awesome-icon>
            </div>
            <p>
              <a :href="`mailto:${company.contact.email}`" :title="company.contact.email">
                {{ company.contact.email }}
              </a>
            </p>
          </li>
          <li v-if="company.contact.website">
            <div class="meta__logo">
              <font-awesome-icon icon="link"></font-awesome-icon>
            </div>
            <p><a :href="company.contact.website" target="_blank">{{ company.contact.website }}</a></p>
          </li>
          <li v-if="company.cvr">
            <div class="meta__logo">
              <font-awesome-icon icon="certificate"></font-awesome-icon>
            </div>
            <p>CVR: {{ company.cvr }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div style="width: 100%">
      <GoogleMap class="map" :center="company.location?.geocode?.position" :locations="[{ item: null, location: company.location?.geocode?.position}]"/>
    </div>
  </div>
</template>

<script>
// import GallerySlider from '@/components/GallerySlider.vue';
import MapStyle from '@/helpers/MapStyle.json';
import GoogleMap from '@/components/GoogleMap.vue';

export default {
  components: { GoogleMap },
  // components: { GallerySlider },
  props: {
    contentClass: String,
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapStyle: MapStyle
    };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.map {
  width: 100%;
  height: 300px;

  @include config.lg {
    margin-top: 0;
    height: 100%;
  }
}

.company-details {
  background: config.$green-dark;
  color: config.$white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0 !important;

  @include config.sm {
    border-bottom-left-radius: 10px;
    min-height: 370px;
  }

  @include config.lg {
    flex-direction: row;
  }

  &__info {
    width: 250px;
  }

  &__gallery {
    //overflow: hidden;
    //margin: 50px calc(#{config.$wrapper-padding-mobile} * -1) -50px;
    //
    //img {
    //  display: block;
    //}

    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    width: calc(100% - 250px);
  }
}

.meta {
  list-style: none;
  font-size: 0.95em;
  margin-top: 1em;

  &__logo {
    width: 30px;
    flex-shrink: 0;
  }

  li {
    display: flex;
    align-items: center;

    a {
      color: inherit;
    }

    @include config.not_last {
      margin-bottom: 1em;
    }
  }
}
</style>
