import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

export default [
  {
    path: '/login',
    name: 'auth.login',
    component: Login,
    props: (route) => ({
      email: route.query.email
    }),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/register',
    alias: '/anmod',
    name: 'auth.register',
    component: Register,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/reset',
    name: 'auth.reset',
    component: ResetPassword,
    props: (route) => ({
      token: route.query.token,
      email: route.query.email
    }),
    meta: {
      requireAuth: false
    }
  }
];
