export default {
  markerSvgIcon: '/img/marker.svg',
  markerSvgIconSelected: '/img/marker.svg',
  markerImageSmall: '/img/marker.svg',
  clusterImage: '/img/cluster1.svg',
  clusterStyle: [{
    textColor: 'white',
    url: '/img/cluster1.svg',
    height: 36,
    width: 36
  }]
};
