<template>
  <li class="tradingCard" @click="$router.push(link)">
    <div class="tradingCard__inner">
      <div class="tradingCard__img-wrapper">
        <img :src="trading.primaryImage?.path" alt="" v-if="trading.primaryImage"/>
        <p class="tradingCard__sticker">{{ trading?.type?.name }}</p>
      </div>
      <div class="tradingCard__content">
        <div>
          <p class="tradingCard__label">{{ trading.name }}</p>
          <p v-html="trading.manchet"></p>
        </div>
        <div style="margin-top: 0.5rem;">
          <IconAndLabel
            icon="map-marker"
            class="col-12 col-sm-6 col-md-12 col-xl-12"
            :label="trading.location.full_inline"
          />
        </div>
      </div>
      <div class="interactions">
        <LikeCounter
          type="trading"
          :id="trading.id"
          :liked="trading.liked"
          :count="trading.likeCounter"
          compact
          @liked="onLike"
        />
        <CommentCount :count="trading.totalComments" />
      </div>
    </div>
    <slot/>
  </li>
</template>

<script>
import IconAndLabel from '@/components/IconAndLabel.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  emits: ['liked'],
  components: {
    IconAndLabel,
    LikeCounter,
    CommentCount
  },
  props: {
    trading: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return {
        name: 'trading.show',
        params: {
          id: this.trading?.id
        }
      };
    }
  },
  methods: {
    onLike(data) {
      this.$emit('liked', data);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.tradingCard {
  list-style: none;
  cursor: pointer;
  font-size: 0.9em;

  &__inner {
    display: flex;
  }

  &__content {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__img-wrapper {
    width: 172px;
    height: 138px;
    flex-shrink: 0;
    background: config.$grey;
    border-radius: config.$field-border;
    overflow: hidden;
    position: relative;
    margin-right: 1rem;

    img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__sticker {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
    background-color: config.$brown;
    color: config.$white;
    font-size: 0.8em;
    padding: 0.3em 1em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__label {
    font-weight: 600;
    font-size: 1.2em;
    font-family: "Exo 2", sans-serif;
    text-decoration: none;
    color: inherit;
    margin-bottom: 10px;
    display: block;
    word-break: break-word;
  }
}

.trading {
  position: relative;
  font-size: 16px;
}
.interactions {
  margin-left: auto;
  margin-top: auto;
  display: flex;
  gap: 1rem;
}
</style>
