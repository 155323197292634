<template>
  <BaseSidebar>
    <FilterSection v-if="regions.length > 0" label="Område">
      <BaseField v-for="region in regions" :key="region.id">
        <BaseCheckbox
          v-model="selectedRegionIds"
          :value="region.id"
          :label="region.name"
          @change="onSelectRegion"
        />
      </BaseField>
    </FilterSection>
  </BaseSidebar>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Kalender" class="mb-2" slot-grow>
        <BaseSearch v-model="searchterm"
                    icon="search"
                    placeholder="Søg efter events"
                    @input="onSearch"
        />
      </BasePageHeader>
      <HeroLoader :empty="events.length === 0" :loading="isLoadingPage">
        <div class="grid">
          <EventCard
            v-for="event in events"
            :key="event.id"
            :id="event.id"
            class="col-12 col-sm-6 col-xl-4"
            :image-path="event.image || ''"
            :title="event.title"
            :address="event.location.full_html"
            :timespan="event.meta.timespan"
            :start-at="event.startAt"
            :like-counter="event.likeCounter"
            :liked="event.liked"
            :total-comments="event.totalComments"
            @liked="onLike(event.id, $event)"
          />
        </div>

        <Pagination
          v-if="showPagination"
          v-model="page"
          :pages="meta.lastPage"
          scroll-top
          @update:modelValue="onPageChange"
        />
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import BaseSidebar from '@/components/BaseSidebar.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useIndustries from '@/composables/useIndustries';
import FilterSection from '@/containers/FilterSection.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import useZipcode from '@/composables/useZipcode';
import BaseField from '@/components/BaseField.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import HeroLoader from '@/components/HeroLoader.vue';
import EventService from '@/services/EventService';
import EventCard from '@/components/EventCard.vue';
import Pagination from '@/containers/Pagination.vue';

export default {
  components: {
    Pagination,
    EventCard,
    HeroLoader,
    BaseField,
    BaseCheckbox,
    FilterSection,
    BasePageHeader,
    BaseSearch,
    BaseSidebar
  },
  data() {
    return {
      selectedIndustry: null,
      selectedRegionIds: [],
      searchterm: '',
      events: [],
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      }
    };
  },
  computed: {
    showPagination() {
      return this.meta.total > this.meta.perPage;
    }
  },
  setup() {
    const {
      fetchIndustries,
      industries
    } = useIndustries();

    const {
      fetchRegions,
      regions
    } = useZipcode();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchIndustries,
      industries,
      fetchRegions,
      regions
    };
  },
  async mounted() {
    this.startPageLoading();
    this.startLoading();
    this.syncQuery();
    await Promise.all([this.fetchIndustries(), this.fetchRegions()]);
    await this.fetchEventsByFilters();
    this.stopPageLoading();
  },
  methods: {
    onLike(id, data) {
      const [event] = this.events.filter((item) => item.id === id);
      event.liked = data.liked;
      event.likeCounter = data.count;
    },
    onPageChange() {
      this.fetchEventsByFilters();
      this.setQuery();
    },
    onSelectRegion() {
      // this.searchterm = '';
      this.setQuery();
    },
    async fetchEventsByFilters() {
      this.startLoading();
      try {
        const payload = await EventService.all({
          q: this.searchterm,
          regions: this.selectedRegionIds,
          page: this.page
        });
        this.events = payload?.data?.data;
        this.meta.lastPage = payload?.data?.meta?.last_page;
        this.meta.perPage = payload?.data?.meta?.per_page;
        this.meta.total = payload?.data?.meta?.total;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
        this.stopLoading();
      }
    },
    // eslint-disable-next-line
    onSearch: debounce(function () {
      this.setQuery();
    }, 250),
    syncQuery() {
      const { query } = this.$route;
      if (query.page) {
        this.page = parseInt(query.page.toString(), 10);
      }
      if (query.regions) {
        this.selectedRegionIds = query.regions
          .toString()
          .split(',')
          .map((x) => parseInt(x.toString(), 10));
      }
      if (query.q) {
        this.searchterm = decodeURIComponent(query.q.toString());
      }
    },
    setQuery() {
      const query = {
        page: this.page,
        regions: this.selectedRegionIds.length > 0 ? this.selectedRegionIds.join(',') : null,
        q: this.searchterm.length > 0 ? encodeURIComponent(this.searchterm) : null
      };

      this.$router.replace({ query });

      this.fetchEventsByFilters();
    }
  }
};
</script>
