import useAuth from '@/composables/useAuth';

export default function (to, from, next) {
  const { hasSubscription } = useAuth();
  if (to.meta?.module === null || to.meta?.module === undefined) {
    next();
    return;
  }

  if (hasSubscription(to.meta.module)) {
    next();
    return;
  }

  next('/404');
}
