export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/images`);
  },
  upload(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateOrders(companyId, images) {
    return window.axios.put(`/api/admin/company/${companyId}/images/sort`, { images });
  },
  delete(companyId, id) {
    return window.axios.delete(`/api/admin/company/${companyId}/images/${id}`);
  }
};
