<template>
  <div class="wrapper">
    <HeroLoader :loading="false">
      <div v-if="true">
        <BasePageHeader headline="Virksomhedsbilleder" />

        <BaseField label="Upload billeder" style="max-width: 800px; margin: 0 auto; margin-top: 3rem;">
          <FileUpload @upload="onUpload" multiple></FileUpload>
        </BaseField>
        <BaseField>
          <draggable
            :list="images"
            item-key="path"
            class="list-group"
            :disabled="isLoading"
            ghost-class="ghost"
            :animation="150"
            :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
            @start="dragging = true"
            @end="onUpdateOrder"
          >
            <template #item="{ element, index }">
              <CompanyImage :headline="element.sortOrder.toString()"
                            :src="element.path"
                            alt=""
                            :primary="index === 0"
                            @delete="onDelete(element.id)"
              />
            </template>
          </draggable>
        </BaseField>
      </div>
    </HeroLoader>
  </div>
</template>

<script>
import HeroLoader from '@/components/HeroLoader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import AdminCompanyImageService from '@/services/Admin/AdminCompanyImageService';
import FileUpload from '@/containers/FileUpload.vue';
import draggable from 'vuedraggable';
import CompanyImage from '@/views/Admin/components/CompanyImage.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';

export default {
  components: {
    BasePageHeader,
    CompanyImage,
    FileUpload,
    draggable,
    BaseField,
    HeroLoader
  },
  data() {
    return {
      enabled: true,
      images: [],
      dragging: false
    };
  },
  setup() {
    const {
      user,
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      user,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyImages();
    this.stopPageLoading();
  },
  methods: {
    async onUpdateOrder() {
      this.dragging = false;
      Message.clear();
      this.startLoading('Opdaterer rækkefølge');

      const images = this.images.map((image, index) => ({
        id: image.id,
        order: index
      }));

      try {
        await AdminCompanyImageService.updateOrders(this.company.id, images);
      } catch (e) {
        Message.httpError(e);
        await this.loadCompanyImages();
      } finally {
        this.stopLoading();
      }
    },
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    async onUpload(formData) {
      Message.clear();
      this.startLoading('Uploader billede');
      try {
        const payload = await AdminCompanyImageService.upload(this.company.id, formData);
        await this.loadCompanyImages();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onDelete(id) {
      Message.clear();
      this.startLoading('Sletter billede');

      try {
        const payload = await AdminCompanyImageService.delete(this.company.id, id);
        await this.loadCompanyImages();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async loadCompanyImages() {
      try {
        const payload = await AdminCompanyImageService.all(this.company.id);
        this.images = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3 !important;
}

.list-group {
  min-height: 20px;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;

  > * {
    margin: 10px !important;
  }
}

.list-group-item {
  cursor: move;

}

.list-group-item i {
  cursor: pointer;
}
</style>
