import { serialize } from 'object-to-formdata';

export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/tasks`);
  },
  show(companyId, projectId) {
    return window.axios.get(`/api/admin/company/${companyId}/tasks/${projectId}`);
  },
  store(companyId, data, images) {
    const payload = serialize(data);

    images.forEach((image) => payload.append('images[]', image.file, image.filename));

    return window.axios.post(`/api/admin/company/${companyId}/tasks`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  update(companyId, caseId, data, images) {
    const payload = serialize(data);

    images.forEach((image) => payload.append('images[]', image.file, image.filename));

    return window.axios.post(`/api/admin/company/${companyId}/tasks/${caseId}?_method=PUT`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  destroy(companyId, caseId) {
    return window.axios.delete(`/api/admin/company/${companyId}/tasks/${caseId}`);
  }
};
