<template>
  <div class="singleComment">
    <div class="singleComment__author">
      <p><span>{{ comment?.user?.full_name }}</span> |
        {{ comment?.company?.name }}</p>
      <p>{{ comment.updatedAt }}</p>
    </div>
    <p class="singleComment__body" v-html="comment.comment"/>
    <button class="singleComment__reply"
            @click.prevent="$emit('toggle-form', comment.id)"
            v-if="depth < 1 && comment.comments.length === 0"
    >
      <font-awesome-icon :icon="getIcon"></font-awesome-icon>
      {{ getText }}
    </button>

    <Comment
      v-if="showForm"
      :commentable-id="commentableId"
      :commentable-type="commentableType"
      :parent-comment-id="comment.id"
      @created="success"
      style="margin-top: 10px;"
    />
  </div>
</template>

<script>
import Comment from '@/views/Comments/Comment.vue';

export default {
  components: {
    Comment
  },
  props: {
    commentableId: Number,
    commentableType: String,
    comment: Object,
    showForm: Boolean,
    depth: Number
  },
  emits: ['toggle-form', 'created'],
  computed: {
    getText() {
      if (this.showForm) {
        return 'Luk';
      }
      return 'Svar';
    },
    getIcon() {
      if (this.showForm) {
        return 'times';
      }
      return ['far', 'comment'];
    }
  },
  methods: {
    success() {
      this.$emit('toggle-form', this.comment.id);
      this.$emit('created');
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.singleComment {
  border: 1px solid config.$grey;
  border-radius: 5px;
  padding: 8px;

  &__author {
    font-size: 0.8em;
    color: #8C8C8C;
    display: flex;
    justify-content: space-between;

    p:first-child {
      span {
        font-weight: 600;
      }
    }
  }

  &__body {
    font-size: 0.9em;
    padding-top: 2px;
  }

  &__reply {
    font-size: 0.8em;
    color: #8C8C8C;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
}

</style>
