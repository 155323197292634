<template>
  <Box class="projectCard" light-shadow>
    <div class="projectCard__figure">
      <img :src="image" :alt="title"/>
      <p class="projectCard__figure-title">
        {{ title }}
      </p>
    </div>
    <div class="projectCard__content">
      <div class="projectCard__blocks">
        <div v-if="qualityDescription" class="projectCard__block">
          <p class="title">Særlig kvalitet i løsningen:</p>
          <p>{{ qualityDescription }}</p>
        </div>
        <div v-if="partnerDescription" class="projectCard__block">
          <p class="title">Samarbejdspartnere:</p>
          <p>{{ partnerDescription }}</p>
        </div>
        <div v-if="address" class="projectCard__block">
          <p class="title">Lokation:</p>
          <p>{{ address }}</p>
        </div>
      </div>
      <slot/>
    </div>
  </Box>
</template>

<script>
import Box from '@/components/Box.vue';

export default {
  components: { Box },
  props: {
    image: String,
    title: String,
    address: String,
    videoLink: String,
    description: String,
    qualityDescription: String,
    partnerDescription: String
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

$_paddingSides: 20px;

.projectCard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__figure {
    padding-bottom: 56.25%;
    height: 0;
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      user-select: none;
    }

    &-title {
      background-color: rgba(config.$green, 0.95);
      z-index: 2;
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 17px;
      font-weight: 500;
      padding: 15px $_paddingSides;
      color: config.$white;
      font-family: config.$font-headline;
    }
  }

  &__content {
    padding: 1.7em $_paddingSides;
    font-size: 0.95em;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: space-between;
    gap: 1.5em;
  }

  &__block {
    .title {
      font-weight: 600;
      font-family: config.$font-headline;
    }

    @include config.not_last {
      margin-bottom: 1em;
    }
  }
}
</style>
