<template>
  <button class="comment-count" @click.prevent="onClick">
    <font-awesome-icon icon="comments"></font-awesome-icon>
    {{ count }}
  </button>
</template>

<script>
export default {
  emits: ['clicked'],
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onClick() {
      const comments = document.getElementById('comments');
      if (comments) {
        comments.scrollIntoView();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
.comment-count {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #8C8C8C;
  font-family: config.$font-headline;
}
svg {
  font-size: 1.1rem;
}
</style>
