<template>
  <div class="column" v-bind="$attrs">
    <div class="column__inner">
      <slot/>
    </div>
    <BaseHelp v-if="help" :label="help"/>
  </div>
</template>
<script>
import BaseHelp from '@/components/BaseHelp.vue';

export default {
  components: { BaseHelp },
  props: {
    help: String
  }
};
</script>
<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.column {
  margin-bottom: 0.75em;

  &__inner {
    @include config.sm {
      display: flex;
      gap: 1.4em;
      margin-bottom: 0;
    }
  }
}
</style>
