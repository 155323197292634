<template>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Aktivitetsfeed" class="mb-2" slot-grow />
      <HeroLoader :empty="activities.length === 0" :loading="isLoadingPage">
        <div class="activities">
          <ActivityCard
            v-for="activity in activities"
            :key="activity.id"
            :activity="activity"
          />
        </div>

        <Pagination
          v-if="showPagination"
          v-model="page"
          :pages="meta.lastPage"
          scroll-top
          @update:modelValue="onPageChange"
        />
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import HeroLoader from '@/components/HeroLoader.vue';
import ActivityCard from '@/components/ActivityCard.vue';
import ActivityService from '@/services/ActivityService';
import Pagination from '@/containers/Pagination.vue';

export default {
  components: {
    Pagination,
    HeroLoader,
    BasePageHeader,
    ActivityCard
  },
  data() {
    return {
      activities: [],
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      }
    };
  },
  computed: {
    showPagination() {
      return this.meta.total > this.meta.perPage;
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading
    };
  },
  async mounted() {
    this.startPageLoading();
    this.startLoading();
    await this.fetch();
    this.stopPageLoading();
  },
  methods: {
    onPageChange() {
      this.fetch();
    },
    async fetch() {
      this.startLoading();
      try {
        const payload = await ActivityService.all({
          page: this.page
        });
        this.activities = payload?.data?.data;
        this.meta.lastPage = payload?.data?.meta?.last_page;
        this.meta.perPage = payload?.data?.meta?.per_page;
        this.meta.total = payload?.data?.meta?.total;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
        this.stopLoading();
      }
    },
    setQuery() {
      const query = {
        page: this.page
      };

      this.$router.replace({ query });

      this.fetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.activities {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
</style>
