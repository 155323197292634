<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <Box style="max-width: 800px" v-if="company" :headline="company.name">
        <div class="box-body">
          <BaseField label="Logo">
            <CompanyLogo v-if="company.logo"
                         :src="company.logo"
                         deleteable
                         @delete="onRemoveLogo"
            />
            <FileUpload v-if="company.logo == null" @upload="onUploadLogo">
            </FileUpload>
          </BaseField>

          <form @submit.prevent="onSaveCompany">
            <BaseColumn>
              <BaseField label="CVR" help="Kontakt Byghåndværk for at rette">
                <BaseInput readonly disabled v-model="company.cvr"/>
              </BaseField>
              <BaseField label="Navn på virksomheden" help="Kontakt Byghåndværk for at rette">
                <BaseInput readonly disabled v-model="company.name"/>
              </BaseField>
            </BaseColumn>
            <BaseColumn>
              <BaseField label="Adresse">
                <BaseInput required v-model="company.location.address"/>
              </BaseField>
              <BaseField label="By & Postnr">
                <ZipcodeSelector v-model="company.location.zipcode"/>
              </BaseField>
            </BaseColumn>
            <BaseColumn>
              <BaseField label="Telefon">
                <BaseInput required v-model="company.contact.phone"/>
              </BaseField>
              <BaseField label="E-mail">
                <BaseInput required v-model="company.contact.email"/>
              </BaseField>
            </BaseColumn>
            <BaseField label="Website">
              <BaseInput v-model="company.contact.website"/>
            </BaseField>
            <BaseField label="Beskrivelse">
              <Editor v-model="company.description" />
            </BaseField>
            <BaseButton type="info" :loading="isLoading">Gem ændringer</BaseButton>
          </form>
        </div>
      </Box>
    </HeroLoader>
  </div>
</template>

<script>
import BasePageHeader from '@/containers/BasePageHeader.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import useZipcode from '@/composables/useZipcode';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseButton from '@/components/BaseButton.vue';
import Info from '@/components/Info.vue';
import Box from '@/components/Box.vue';
import CompanyService from '@/services/CompanyService';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import AdminCompanyService from '@/services/Admin/AdminCompanyService';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import FileUpload from '@/containers/FileUpload.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import Editor from '@/containers/Editor.vue';

export default {
  components: {
    Editor,
    CompanyLogo,
    FileUpload,
    ZipcodeSelector,
    // eslint-disable-next-line vue/no-unused-components
    Box,
    // eslint-disable-next-line vue/no-unused-components
    Info,
    BaseButton,
    BaseColumn,
    BaseInput,
    BaseField,
    HeroLoader,
    // eslint-disable-next-line vue/no-unused-components
    BasePageHeader
  },
  data() {
    return {
      savedData: null,
      zipcodes: [],
      company: null
    };
  },
  computed: {
    value: {
      get() {
        return this.company?.location?.zipcode;
      },
      set(value) {
        this.savedData = value;
      }
    }
  },
  setup() {
    const {
      fetchRegions,
      regions
    } = useZipcode();

    const {
      user,
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchRegions,
      regions,
      user,
      userCompany: company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompany();
    this.stopPageLoading();
  },
  methods: {
    async onUploadLogo(formData) {
      Message.clear();
      this.startLoading('Uploader logo');
      try {
        const payload = await AdminCompanyService.uploadLogo(this.userCompany.id, formData);
        await this.loadCompany();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onRemoveLogo() {
      Message.clear();
      this.startLoading('Fjerner logo');
      try {
        const payload = await AdminCompanyService.removeLogo(this.userCompany.id);
        await this.loadCompany();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onSaveCompany() {
      Message.clear();
      this.startLoading();

      try {
        const payload = await AdminCompanyService.update(this.userCompany.id,
          {
            website: this.company.contact.website,
            phone: this.company.contact.phone,
            email: this.company.contact.email,
            address: this.company.location.address,
            zipcode: this.company.location.zipcode,
            description: this.company.description
          });

        // this.company = payload?.data?.data;
        await this.loadCompany();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async loadCompany() {
      try {
        const payload = await CompanyService.show({
          id: this.user.company.id
        });
        this.company = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style scoped>
.box-body {
  padding: 15px;
}
</style>
