<template>
  <li class="singleComment-wrapper">
    <SingleComment
      :comment="comment"
      :commentable-id="commentable.id"
      :commentable-type="commentableType"
      :show-form="activeCommentId === comment.id"
      @toggle-form="toggleForm"
      @created="fetchComments"
      :depth="depth"
    />
    <ul class="subComments" v-if="comments.length > 0">
      <Comments
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :commentable="commentable"
        :commentable-type="commentableType"
        :comments="comment.comments || []"
        :active-comment-id="activeCommentId"
        :fetch-comments="fetchComments"
        :toggle-form="fetchComments"
        :depth="depth + 1"
      />
      <li>
        <BaseButton size="small" type="info" @click.prevent="showForm = true" v-if="!showForm">
          <font-awesome-icon :icon="['far', 'comment']" style="margin-right: 10px;"></font-awesome-icon>
          Deltag
        </BaseButton>
        <Comment
          v-if="showForm"
          :commentable-type="commentableType"
          :commentable-id="commentable.id"
          :parent-comment-id="comment.id"
          @created="fetchComments"
          style="margin-top: 10px;"
        />
      </li>
    </ul>
  </li>
</template>

<script>
/* eslint-disable */
import SingleComment from '@/views/Comments/SingleComment';
import Comment from '@/views/Comments/Comment';
import BaseButton from '@/components/BaseButton';

export default {
  name: 'Comments',
  components: {
    SingleComment,
    Comment,
    BaseButton,
  },
  props: {
    comment: Object,
    commentable: Object,
    commentableType: String,
    comments: Array,
    activeCommentId: Number,
    fetchComments: Function,
    toggleForm: Function,
    depth: Number,
  },
  data() {
    return {
      showForm: false
    };
  }
};
</script>

<style scoped>

</style>
