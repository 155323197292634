// eslint-disable-next-line no-unused-vars
import { computed, ref } from 'vue';

const state = ref({
  searchterm: '',
  active: false
});
export default function useHeaderSearch() {
  return {
    searchterm: computed({
      get() {
        return state.value.searchterm;
      },
      set(value) {
        state.value.searchterm = value;
      }
    }),
    isActive: computed({
      get() {
        return state.value.active === true;
      },
      set(value) {
        state.value.active = !!value;
      }
    })
  };
}
