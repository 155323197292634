import { createRouter, createWebHistory } from 'vue-router';
import redirectToLoginIfNotAuthenticated
  from '@/router/middlewares/redirectToLoginIfNotAuthenticated';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import NotFound from '@/views/NotFound.vue';
import Feedback from '@/views/Feedback.vue';
import redirectIfModuleNotActive from '@/router/middlewares/redirectIfModuleNotActive';
import adminModule from '@/router/modules/admin';
import Activity from '@/views/Activity.vue';
import auth from './modules/auth';
import companyModule from './modules/company';
import eventsModule from './modules/events';
import tasksModule from './modules/tasks';
import blogModule from './modules/blog';
import forumModule from './modules/forum';
import tradingModule from './modules/trading';
import publicModule from './modules/public';

const routes = [
  ...auth,
  ...companyModule,
  ...eventsModule,
  ...adminModule,
  ...tasksModule,
  ...blogModule,
  ...forumModule,
  ...tradingModule,
  ...publicModule,
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      requireAuth: true,
      layout: DashboardLayout
    },
    component: Feedback
  },
  {
    path: '/',
    name: 'activity',
    meta: {
      layout: DashboardLayout
    },
    component: Activity
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      layout: DashboardLayout
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
      behavior: 'smooth'
    };
  }
});

router.beforeEach(redirectToLoginIfNotAuthenticated);
router.beforeEach(redirectIfModuleNotActive);

export default router;
