<template>
  <component :is="tag"
             class="label"
             :class="{'label--clickable': clickable}">
    {{ label }}
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'label'
    },
    label: {
      type: String,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.label {
  font-size: 16px;
  font-weight: 500;
  font-family: config.$font-headline;
  margin-bottom: 0.5rem;
  display: inline-block;

  &--clickable {
    cursor: pointer;
  }
}
</style>
