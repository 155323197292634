<template>
  <BaseControl class="control">
    <div style="flex-grow: 1">
      <textarea class="input"
                v-model="modelState"
                type="text"
                v-bind="$attrs"
                ref="content"
                :style="computedStyles"
                @focus="resize"
      ></textarea>
      <p class="flex space-between counter" v-if="maxLength"
         :class="{'counter--danger': modelValue.length > maxLength }">
        <span v-if="modelValue.length > maxLength">
          Du har overtrådt max antal tegn.
        </span>
        <span class="align-right">
        {{ modelValue.length }} / {{ maxLength }}
        </span>
      </p>
    </div>

    <div v-if="loading" class="icon icon--right icon--right-loading">
      <font-awesome-icon icon="spinner" spin/>
    </div>
    <slot/>
  </BaseControl>
</template>

<script>
import BaseControl from '@/components/BaseControl.vue';
import useVModel from '@/composables/useVModel';

export default {
  props: {
    modelValue: [String, Number],
    loading: Boolean,
    autosize: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: [Number],
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: [Number],
      default: null
    },
    /*
     * Force !important for style properties
     */
    important: {
      type: [Boolean, Array],
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      // data property for v-model binding with real textarea tag
      val: null,
      // works when content height becomes more then value of the maxHeight property
      maxHeightScroll: false,
      height: 'auto'
    };
  },
  components: {
    BaseControl
  },
  computed: {
    computedStyles() {
      if (!this.autosize) return {};
      return {
        resize: !this.isResizeImportant ? 'none' : 'none !important',
        height: this.height,
        // eslint-disable-next-line no-nested-ternary
        overflow: this.maxHeightScroll ? 'auto' : (!this.isOverflowImportant ? 'hidden' : 'hidden !important')
      };
    },
    isResizeImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('resize'));
    },
    isOverflowImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('overflow'));
    },
    isHeightImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes('height'));
    }
  },
  setup(props) {
    return {
      modelState: useVModel(props, 'modelValue')
    };
  },
  mounted() {
    if (this.autosize) {
      this.resize();
    }
  },
  methods: {
    resize() {
      const important = this.isHeightImportant ? 'important' : '';
      this.height = `auto${important ? ' !important' : ''}`;
      setTimeout(() => {
        let contentHeight = this.$refs.content?.scrollHeight + 1 || 0;

        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        const heightVal = `${contentHeight}px`;
        this.height = `${heightVal}${important ? ' !important' : ''}`;
      }, 0);
    }
  },
  watch: {
    minHeight() {
      this.$nextTick(this.resize);
    },
    maxHeight() {
      this.$nextTick(this.resize);
    },
    autosize(modelValue) {
      if (modelValue) this.resize();
    },
    modelValue() {
      this.$nextTick(this.resize);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.counter {
  font-size: 0.9em;
  text-align: right;

  &--danger {
    color: config.$brown-dark;
    font-weight: 500;
  }
}

.input {
  min-height: calc(#{config.$field-height} * 2);
  display: inline-flex;
  align-items: center;
  border: 2px solid config.$grey;
  border-radius: config.$field-border;
  justify-content: flex-start;
  background-color: config.$white;
  flex-grow: 1;
  resize: none;
  padding: 5px calc(.75em - 1px) 0;

  &:focus {
    border-color: config.$green;
  }

  &:read-only {
    opacity: 0.5;
  }

  .control.has-left-icon & {
    padding-left: calc(#{config.$field-height} + 5px);
  }

  .control.has-right-icon & {
    padding-right: calc(#{config.$field-height} + 5px);
  }
}
</style>
