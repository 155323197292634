<template>
  <BaseControl class="control" :has-left-icon="!!icon" :has-right-icon="!!iconRight">
    <input class="input" v-model="modelState"
           type="text"
           v-bind="$attrs"/>

    <div v-if="icon" class="icon icon--left">
      <font-awesome-icon :icon="icon"/>
    </div>
    <div v-if="iconRight || loading" class="icon icon--right" :class="{'icon--right-loading': loading}">
      <font-awesome-icon :icon="iconRight" v-if="iconRight && !loading"/>
      <font-awesome-icon icon="spinner" spin v-if="loading"/>
    </div>
    <slot/>
  </BaseControl>
</template>

<script>
import BaseControl from '@/components/BaseControl.vue';
import useVModel from '@/composables/useVModel';

export default {
  props: {
    modelValue: [String, Number, Object],
    icon: String,
    iconRight: String,
    loading: Boolean
  },
  emits: ['update:modelValue'],
  components: {
    BaseControl
  },
  setup(props) {
    return {
      modelState: useVModel(props, 'modelValue')
    };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.input {
  height: config.$field-height;
  display: inline-flex;
  align-items: center;
  border: 2px solid config.$grey;
  border-radius: config.$field-border;
  justify-content: flex-start;
  background-color: config.$white;
  padding: 0 calc(.75em - 1px);
  white-space: nowrap;
  flex-grow: 1;

  //@media (hover: hover) {
  //  &::-webkit-inner-spin-button,
  //  &::-webkit-calendar-picker-indicator {
  //    display: none;
  //    -webkit-appearance: none;
  //  }
  //}

  &:focus {
    border-color: config.$green;
  }

  &:read-only {
    opacity: 0.5;
  }

  .control.has-left-icon & {
    padding-left: calc(#{config.$field-height} + 5px);
  }

  .control.has-right-icon & {
    padding-right: calc(#{config.$field-height} + 5px);
  }
}
</style>
