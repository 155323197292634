<template>
  <div class="login-layout">
    <Box class="login-layout__inner">
      <img src="../assets/logo.svg" alt="" class="login-layout__logo"/>
      <slot></slot>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box.vue';

export default {
  components: { Box }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.login-layout {
  background-color: config.$green-dark;
  display: grid;
  padding: 0 20px;
  min-height: var(--app-height);

  &__inner {
    margin: auto;
    max-width: 500px;
    width: 100%;
    padding: 55px 15px 35px;

    @include config.sm {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include config.md {
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  &__logo {
    display: block;
    margin: auto auto 2rem;
    max-width: 300px;
    width: 100%;
  }
}
</style>
