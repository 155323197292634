export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/skills`);
  },
  store(companyId, { description, title }) {
    return window.axios.post(`/api/admin/company/${companyId}/skills`, { description, title });
  },
  updateOrders(companyId, skills) {
    return window.axios.put(`/api/admin/company/${companyId}/skills/sort`, { skills });
  },
  delete(companyId, id) {
    return window.axios.delete(`/api/admin/company/${companyId}/skills/${id}`);
  }
};
