<template>
  <div class="wrapper">
    <HeroLoader>
      <div class="grid">
        <div class="col-12 col-md-5">
          <Box headline="Kodeord">
            <div class="box-body">
              <p>Her kan du opdatere dit kodeord.</p>
              <br/><br/>
              <form @submit.prevent="onUpdatePassword">
                <BaseField label="Kodeord" help="Indtast dit nye ønskede kodeord">
                  <BaseInput required v-model="form.password" type="password"/>
                </BaseField>
                <BaseField label="Bekræft kodeord">
                  <BaseInput required v-model="form.passwordConfirmed" type="password"/>
                </BaseField>
                <BaseButton type="info" :loading="isLoading">Opdater kodeord</BaseButton>
              </form>
            </div>
          </Box>
          <a href="#" type="danger" class="logout"
             @click.prevent="onLogout">
            Log af Byghåndværk
          </a>
        </div>
      </div>
    </HeroLoader>
  </div>
</template>

<script>
import HeroLoader from '@/components/HeroLoader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import Box from '@/components/Box.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import AuthService from '@/services/AuthService';

export default {
  components: {
    Box,
    BaseButton,
    BaseInput,
    BaseField,
    HeroLoader
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirmed: ''
      }
    };
  },
  setup() {
    const {
      user,
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      user,
      company
    };
  },
  methods: {
    async onLogout() {
      await AuthService.logout();
      await this.$router.push({ name: 'auth.login' });
    },
    async onUpdatePassword() {
      Message.clear();
      this.startLoading('Opdaterer kodeord');

      try {
        const payload = await AuthService.updatePassword(this.form.password, this.form.passwordConfirmed);
        this.form.password = '';
        this.form.passwordConfirmed = '';
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style scoped>
.box-body {
  padding: 15px;
}

.logout {
  text-align: right;
  display: block;
  margin-top: 1rem;
  font-size: 0.9em;
}
</style>
