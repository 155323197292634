export default {
  all({
    page = 1
  }) {
    const query = {
      page: page.toString()
    };

    const queryFormatted = new URLSearchParams(query);

    return window.axios.get(`/api/activities?${queryFormatted.toString()}`);
  }
};
