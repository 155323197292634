<template>
  <div class="public-layout">
    <div class="public-layout__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.public-layout {
  //background-color: config.$green-dark;
  background-color: #eee;
  display: grid;
  padding: 20px;
  min-height: var(--app-height);

  &__inner {
    margin: auto;
    max-width: 1200px;
    width: 100%;
    padding: 55px 15px 35px;

    @include config.sm {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include config.md {
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  &__logo {
    display: block;
    margin: auto auto 2rem;
    max-width: 300px;
    width: 100%;
  }
}
</style>
