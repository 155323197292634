<template>
  <h2 v-if="successSubmitted" style="text-align: center">
    Tak. Din opgave er nu modtaget og gemt.
  </h2>
  <HeroLoader :loading="isLoadingPage" v-if="successSubmitted === false">
    <form @submit.prevent="onSubmit" style="margin-bottom: 4.5rem;">
      <Box headline="Vejledning" style="margin-bottom: 2.5rem;" v-if="!token">
        <div class="box-body">
          <p>
            Her har du, som husejer, mulighed for at oprette opgaver til det professionelle udbudsportal på Byghåndværk.dk
          </p>
          <br>
          <h3>Følg disse få trin</h3>
          <ol style="padding-left: 30px;">
            <li>Udfyld formularen.</li>
            <li>Sørg for at tilknytte 5 mulige søgeord, så din opgave får mere synlighed.</li>
            <li>Upload et billede der kan understøtte opgavens omfang.</li>
            <li>Oplys kontaktpersonen og eventuelle bemærkninger.</li>
            <li>Indsend til godkendelse.</li>
          </ol>
          <br>
          <p>Så er der blot at afvente henvendelser fra interessede aktører.</p>
        </div>
      </Box>

      <Box headline="Beskriv din opgave">
        <div class="box-body">
          <BaseField label="Udbudstype">
            <BaseSelect v-model="selectedTaskTypeId">
              <option v-for="taskType in taskTypes" :key="taskType.id" :value="taskType.id">
                {{ taskType.name }}
              </option>
            </BaseSelect>
          </BaseField>
          <BaseColumn>
            <BaseField label="Overskrift" help="Kort beskrivelse der opsummerer opgavens omfang.">
              <BaseInput v-model="form.title" required/>
            </BaseField>
            <BaseField label="Svarfrist"
                       help="Hvornår ønsker du senest svar? Opgaven vises ikke længere efter denne dato.">
              <BaseInput type="date"
                         v-model="form.deadlineAt"
                         :min="minDate"
                         :max="maxDate"
                         required
              />
            </BaseField>
          </BaseColumn>
          <BaseColumn>
            <BaseField label="Adresse">
              <BaseInput v-model="form.address" required/>
            </BaseField>
            <BaseField label="Postnr">
              <BaseInput v-model="form.zipcode" required/>
            </BaseField>
            <BaseField label="By">
              <BaseInput v-model="form.city" required/>
            </BaseField>
          </BaseColumn>
          <BaseField label="Opgavebeskrivelse">
            <Editor v-model="form.description"/>
          </BaseField>

          <BaseField label="Søgeord"
                     help="Dette hjælper med at målrette din opgave yderligere. Der kan vælges op til 5 søgeord.">
            <ul class="list">
              <li v-for="subindustry in selectedIndustries" :key="subindustry.id">
                <p>{{ subindustry.parentName }}: {{ subindustry.name }}</p>
                <p @click.prevent="removeSelectedSubindustry(subindustry.id)"
                   v-if="!isLoading">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </p>
              </li>
            </ul>

            <div>
              <select :disabled="isLoading" v-model="tempSecondary" class="select"
                      style="width: 100%"
                      @change="onAddSecondaryIndustry"
                      v-if="selectedIndustries.length < 5"
              >
                <option :value="null" disabled>Tilføj søgeord</option>
                <optgroup v-for="industry in industries"
                          :key="industry.id"
                          :label="industry.name"
                >
                  <option v-for="subindustry in getSubIndustries(industry)"
                          :key="subindustry.id"
                          :value="subindustry"
                  >
                    {{ subindustry.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </BaseField>

          <BaseField label="Upload billede">
            <ImageUpload :initial-images="initialImages" @updated="onImagesUpdated" />
          </BaseField>
        </div>
      </Box>

      <Box headline="Kontaktoplysninger" style="margin-top: 2.5rem;" v-if="!token">
        <div class="box-body">
          <BaseColumn>
            <BaseField label="Fornavn">
              <BaseInput v-model="user.firstName" required/>
            </BaseField>
            <BaseField label="Efternavn">
              <BaseInput v-model="user.lastName" required/>
            </BaseField>
          </BaseColumn>
          <BaseColumn>
            <BaseField label="E-mail">
              <BaseInput v-model="user.email" required/>
            </BaseField>
            <BaseField label="Telefon">
              <BaseInput v-model="user.phone" required/>
            </BaseField>
          </BaseColumn>
          <BaseField label="Bemærkninger"
                     help="Hvilke tidspunkter passer bedst? Er der noget de skal være særlige opmærksomme på?"
          >
            <BaseTextarea v-model="form.contactDescription" :min-height="120"/>
          </BaseField>
        </div>
      </Box>
      <BaseButton full :label="buttonLabel" size="large" style="margin-top: 20px;"/>
    </form>
  </HeroLoader>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import ImageUpload from '@/containers/ImageUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import dayjs from 'dayjs';
import Editor from '@/containers/Editor.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import PublicService from '@/services/PublicService';
import BaseTextarea from '@/components/BaseTextarea.vue';
import _ from 'lodash';

export default {
  props: {
    token: {
      type: String,
      default: null
    }
  },
  components: {
    BaseTextarea,
    BaseSelect,
    Editor,
    HeroLoader,
    BaseButton,
    BaseColumn,
    BaseInput,
    ImageUpload,
    BaseField,
    Box
  },
  data() {
    return {
      successSubmitted: false,
      taskTypes: [],
      industries: [],
      minDate: dayjs()
        .format('YYYY-MM-DD'),
      maxDate: dayjs()
        .add(2, 'year')
        .format('YYYY-MM-DD'),
      selectedTaskTypeId: null,
      selectedIndustries: [],
      tempSecondary: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      initialImages: [],
      form: {
        title: '',
        address: '',
        zipcode: '',
        city: '',
        description: '',
        contactDescription: '',
        deadlineAt: dayjs()
          .add(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
        images: []
      }
    };
  },
  computed: {
    buttonLabel() {
      return this.token ? 'Gem opgave' : 'Indsend opgave til godkendelse';
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading
    };
  },
  async mounted() {
    this.startPageLoading();
    await Promise.all([this.fetchTaskTypes(), this.fetchIndustries()]);
    if (this.token) {
      await this.loadTask();
    } else {
      this.selectedTaskTypeId = _.first(this.taskTypes)?.id;
    }
    this.stopPageLoading();
  },
  methods: {
    async removeSelectedSubindustry(id) {
      this.selectedIndustries = this.selectedIndustries.filter((item) => item.id !== id);
    },
    onImagesUpdated(files) {
      this.form.images = files;
    },
    getSubIndustries(industry) {
      return industry.children.filter((item) => {
        if (this.form.primaryId === item.id) {
          return false;
        }
        return this.selectedIndustries.findIndex((i) => i.id === item.id) === -1;
      });
    },
    async onAddSecondaryIndustry() {
      this.selectedIndustries.push(this.formatSecondaryIndustry(this.tempSecondary));
      this.tempSecondary = null;
    },
    formatSecondaryIndustry(subindustry) {
      return {
        id: subindustry.id,
        name: subindustry.name,
        parentName: subindustry.parent.name
      };
    },
    async fetchTaskTypes() {
      try {
        const payload = await PublicService.fetchAllTaskTypes();
        this.taskTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async fetchIndustries() {
      try {
        const payload = await PublicService.fetchAllIndustries();
        this.industries = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async loadTask() {
      Message.clear();
      this.startLoading('Henter udbud');

      try {
        const payload = await PublicService.taskShow(this.token);
        const task = payload?.data?.data;
        if (task) {
          console.log(task);
          this.form.title = task.title;
          this.form.address = task.location.address;
          this.form.zipcode = task.location.zipcode;
          this.form.city = task.location.city;
          this.form.description = task.description.html;
          this.form.deadlineAt = dayjs(task.deadlineAt)
            .format('YYYY-MM-DD');
          this.selectedTaskTypeId = task.type.id;

          this.selectedIndustries = task.industries.map((item) => this.formatSecondaryIndustry(item));
          this.initialImages = task.images;
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    onSubmit() {
      if (this.selectedIndustries.length === 0) {
        Message.error({ message: 'Du skal minimum vælge ét søgeord.' });
        return;
      }
      if (!this.token) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer udbud');

      try {
        const payload = await PublicService.taskUpdate(this.token, {
          title: this.form.title,
          address: this.form.address,
          zipcode: this.form.zipcode,
          city: this.form.city,
          description: this.form.description,
          deadline_at: this.form.deadlineAt,
          task_type_id: this.selectedTaskTypeId,
          industries: this.selectedIndustries.map((i) => i.id)
        }, this.form.images);
        Message.httpSuccess(payload);
        this.successSubmitted = true;
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter udbud');

      try {
        const payload = await PublicService.taskStore({
          title: this.form.title,
          address: this.form.address,
          zipcode: this.form.zipcode,
          city: this.form.city,
          description: this.form.description,
          contact_description: this.form.contactDescription,
          deadline_at: this.form.deadlineAt,
          task_type_id: this.selectedTaskTypeId,
          industries: this.selectedIndustries.map((i) => i.id),
          user: {
            first_name: this.user.firstName,
            last_name: this.user.lastName,
            phone: this.user.phone,
            email: this.user.email
          }
        }, this.form.images);
        Message.httpSuccess(payload);
        this.successSubmitted = true;
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

:deep(.tabs-component) {
  .tabs-component-tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;

    .tabs-component-tab {
      .tabs-component-tab-a {
        &.is-disabled {
        }

        &.is-active {
        }
      }
    }
  }

  .tabs-component-panels {
    .tabs-component-panel {

    }
  }
}

.list {
  list-style: none;

  li {
    margin: 5px 0;
    padding: 10px;
    font-size: 0.9em;
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}
</style>
