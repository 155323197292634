<template>
  <Multiselect
    v-model="value"
    :minChars="2"
    :valueProp="'zipcode'"
    :trackBy="'searchable'"
    :label="'city'"
    :searchable="true"
    :options="zipcodes"
  >
    <template v-slot:option="{ option }">
      {{ option.zipcode }} - {{ option.city }}
    </template>
  </Multiselect>
</template>

<script>
import ZipcodeService from '@/services/ZipcodeService';
import Message from '@/helpers/Message';
import Multiselect from '@vueform/multiselect';

export default {
  props: {
    modelValue: [Number, String]
  },
  emits: ['update:modelValue', 'updated'],
  components: {
    Multiselect
  },
  data() {
    return {
      zipcodes: []
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('updated', this.zipcodes.find((zip) => zip.zipcode === value));
      }
    }
  },
  async mounted() {
    await this.fetchZipcodes();
  },
  methods: {
    async fetchZipcodes() {
      try {
        const payload = await ZipcodeService.zipcodes();
        this.zipcodes = payload?.data?.data;
        this.zipcodes = this.zipcodes.map((city) => (
          {
            city: city.city,
            zipcode: city.zipcode,
            searchable: `${city.zipcode}${city.city}`
          }
        ));
        return this.zipcodes;
      } catch (e) {
        Message.error({ message: 'Kunne ikke hente postnr.' });
      }

      return [];
    }
  }
};
</script>
