export default {
  forumTypes() {
    return window.axios.get('/api/services/forum-types');
  },
  all({
    q = '',
    relations = [],
    types = [],
    page = 1,
    perPage = 21
  }) {
    let query = {
      page: page.toString(),
      per_page: perPage.toString()
    };
    if (q) {
      query = {
        ...query,
        q
      };
    }
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }
    if (Array.isArray(types) && types.length > 0) {
      query = {
        ...query,
        types: types.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);

    return window.axios.get(`/api/forum-posts?${queryFormatted.toString()}`);
  },
  show({
    id,
    relations = []
  }) {
    let query = {};
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);
    return window.axios.get(`/api/forum-posts/${id}?${queryFormatted.toString()}`);
  },
  store(data) {
    return window.axios.post('/api/forum-posts', data);
  }
};
