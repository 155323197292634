<template>
  <component :is="getComponent" class="base-action" v-bind="formatLink" :class="{'base-action--with-active': showActive}">
    <img v-if="img" :src="img" :alt="label" class="base-action__img">
    <p class="base-action__label">{{ label }}</p>
    <div v-if="icon" class="base-action__right-icon">
      <font-awesome-icon :icon="icon"></font-awesome-icon>
    </div>
  </component>
</template>

<script>
import ValidateURL from '@/helpers/ValidateURL';

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    route: [String, Object],
    img: String,
    icon: String,
    showActive: Boolean,
    tag: String
  },
  computed: {
    getComponent() {
      if (this.tag) {
        return this.tag;
      }
      if (ValidateURL(this.route)) {
        return 'a';
      }
      return 'router-link';
    },
    formatLink() {
      if (ValidateURL(this.route)) {
        return {
          tag: 'a',
          href: this.route
        };
      }

      return {
        to: this.route
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.base-action {
  display: flex;
  align-items: center;
  user-select: none;
  font-family: 'Exo 2', sans-serif;
  text-decoration: none;
  color: config.$black;

  &.base-action--with-active.router-link-active p {
    font-weight: 600;
  }

  &__right-icon {
    svg {
      @include config.sm {
        margin-left: 5px;
      }
    }
  }

  &__img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  &__label {
    font-size: 1em;
    //margin-left: 16px;
    @include config.only-phone {
      display: none;
    }
  }
}

</style>
