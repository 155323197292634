<template>
  <HeroLoader :empty="!company" :loading="isLoading">
    <div class="area-content" v-if="company">
      <BasePageHeader shadow type="light" class="wrapper no-space" :headline="company.name">
        <CompanyLogo :src="company.logo" size="small"/>
      </BasePageHeader>

      <section class="grid grid--no-row-space">
        <div class="col-12 col-sm-6 col-lg-5">
          <div class="wrapper-x-padding paddingCards">
            <h4 class="subtitle">Beskrivelse</h4>
            <p v-html="company.description" class="companyDescription"></p>

            <IndustryTags :industries="company.industries" style="margin-top: 15px;"/>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-7">
          <CompanyContactCard :company="company" content-class="paddingCards"/>
        </div>
      </section>

      <section>
        <GallerySlider v-if="company.images.length > 0" :images="company.images"/>
      </section>

      <BaseSection headline="Kompetencer" class="wrapper-x-padding"
                   v-if="company?.skills.length > 0">
        <div class="grid">
          <InfoBox v-for="skill in company.skills" :key="skill.id" class="col-12 col-sm-6">
            <strong><p v-html="skill.title"></p></strong>
            <p v-html="skill.description"></p>
          </InfoBox>
        </div>
      </BaseSection>

      <BaseSection headline="Cases" class="wrapper-x-padding" v-if="company?.projects.length > 0">
        <div class="grid">
          <ProjectCard v-for="project in company.projects"
                       :key="project.id"
                       class="col-12 col-sm-6"
                       :image="project.images[0].path"
                       :address="project.location.full_inline"
                       :title="project.title"
                       :video-link="project.videoLink"
                       :description="project.description"
                       :partner-description="project.partnerDescription"
                       :quality-description="project.qualityDescription"
          />
        </div>
      </BaseSection>

      <BaseSection headline="Arrangør af disse begivenheder"
                   class="wrapper-x-padding"
                   v-if="company.activeEvents.length > 0"
      >
        <div class="grid">
          <EventCard
            v-for="event in company.activeEvents"
            :key="event.id"
            :id="event.id"
            class="col-12 col-sm-6 col-xl-4"
            :image-path="event.image"
            :title="event.title"
            :address="event.location.full_html"
            :timespan="event.meta.timespan"
            :start-at="event.startAt"
            :like-counter="event.likeCounter"
            :liked="event.liked"
            :total-comments="event.totalComments"
          />
        </div>
      </BaseSection>

      <BaseSection headline="Arrangør af udbud"
                   class="wrapper-x-padding"
                   v-if="company.activeEvents.length > 0"
      >
        <div class="grid">
          <TaskCard v-for="task in company.activeTasks"
                    :key="task.id"
                    :task="task"
                    class="col-12 col-sm-6 col-xl-4"
          />
        </div>
      </BaseSection>
    </div>
  </HeroLoader>
</template>

<script>
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import CompanyService from '@/services/CompanyService';
import Message from '@/helpers/Message';
import CompanyContactCard from '@/containers/CompanyContactCard.vue';
import GallerySlider from '@/components/GallerySlider.vue';
import InfoBox from '@/components/InfoBox.vue';
import ProjectCard from '@/components/ProjectCard.vue';
import BaseSection from '@/components/BaseSection.vue';
import EventCard from '@/components/EventCard.vue';
import CompanyLogo from '@/components/CompanyLogo.vue';
import IndustryTags from '@/components/IndustryTags.vue';
import TaskCard from '@/containers/TaskCard.vue';

export default {
  name: 'CompanyView.vue',
  components: {
    TaskCard,
    IndustryTags,
    CompanyLogo,
    // eslint-disable-next-line vue/no-unused-components
    EventCard,
    BaseSection,
    ProjectCard,
    InfoBox,
    // eslint-disable-next-line vue/no-unused-components
    GallerySlider,
    CompanyContactCard,
    HeroLoader,
    BasePageHeader
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      company: null
    };
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoading,
      startPageLoading,
      stopPageLoading,
      isLoadingPage
    };
  },
  created() {
    this.startPageLoading();
  },
  mounted() {
    this.startLoading('Henter virksomhed');
    this.fetchCompany();
    this.stopPageLoading();
  },
  methods: {
    async fetchCompany() {
      try {
        const payload = await CompanyService.show({
          id: this.id,
          relations: ['skills', 'images', 'industries', 'projects.images', 'geocode', 'activeEvents', 'activeTasks']
        });
        this.company = payload?.data?.data;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.companyDescription {
  line-height: 1.7em;
}
</style>

<style>
.paddingCards {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
