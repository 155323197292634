import Blog from '@/views/Blog/Blog.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import BlogView from '@/views/Blog/BlogView.vue';
import Modules from '@/helpers/Modules';

export default [
  {
    path: '/artikler',
    name: 'blog',
    component: Blog,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.BLOG
    }
  },
  {
    path: '/artikler/:id',
    name: 'blog.show',
    component: BlogView,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.BLOG
    },
    props: true
  }
];
