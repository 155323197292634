<template>
  <div class="wrapper">
    <HeroLoader :loading="isLoadingPage">
      <BasePageHeader headline="Annoncer i ressourceportalen">
        <BaseButton @click="showForm = true" v-if="!showForm">Opret ny annonce</BaseButton>
        <a href="#" @click.prevent="goBackToListView" v-if="showForm">Tilbage til visningen</a>
      </BasePageHeader>

      <AdminTradingForm v-if="showForm" @created="onCreated" @updated="onUpdated" :edit-id="selectedTradingId"/>

      <BaseSection css="" headline="Annoncer" style="margin-top: 3rem;" v-if="!showForm">
        <div class="grid" v-if="tradingList.length > 0">
          <TradingCardHighlight v-for="trading in tradingList"
                    :key="trading.id"
                    :trading="trading"
                    class="col-12 col-sm-6 col-lg-4"
          >
            <div style="display: flex; gap: 1rem;">
              <BaseButton type="info" @click.stop="onEdit(trading)">
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </BaseButton>
              <BaseButton type="danger" @click.stop="onDelete(trading)">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </BaseButton>
            </div>
          </TradingCardHighlight>
        </div>
        <p v-else style="font-size: 0.9em">
          Der er ikke oprettet nogen annoncer.
        </p>
      </BaseSection>
    </HeroLoader>
  </div>
</template>

<script>
import BaseSection from '@/components/BaseSection.vue';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import AdminCompanyTradingService from '@/services/Admin/AdminCompanyTradingService';
import HeroLoader from '@/components/HeroLoader.vue';
import AdminTradingForm from '@/views/Admin/Trading/components/AdminTradingForm.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseButton from '@/components/BaseButton.vue';
import TradingCardHighlight from '@/containers/TradingCardHighlight.vue';

export default {
  components: {
    TradingCardHighlight,
    BaseButton,
    BasePageHeader,
    AdminTradingForm,
    HeroLoader,
    BaseSection
  },
  data() {
    return {
      tradingList: [],
      showForm: false,
      selectedTradingId: null
    };
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.loadCompanyTasks();
    this.stopPageLoading();
  },
  methods: {
    onEdit(trading) {
      this.selectedTradingId = trading.id;
      this.showForm = true;
    },
    async onDelete(trading) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Vil du slette denne annonce?')) {
        return;
      }
      Message.clear();
      this.startLoading('Sletter annonce');

      try {
        const payload = await AdminCompanyTradingService.destroy(this.company.id, trading.id);
        await this.loadCompanyTasks();
        Message.httpSuccess(payload);
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreated() {
      this.tradingList = [];
      this.showForm = false;
      await this.loadCompanyTasks();
    },
    async onUpdated() {
      this.tradingList = [];
      this.showForm = false;
      await this.loadCompanyTasks();
    },
    goBackToListView() {
      this.showForm = false;
      this.selectedTradingId = null;
    },
    async loadCompanyTasks() {
      Message.clear();
      this.startLoading('Henter annonce');

      try {
        const payload = await AdminCompanyTradingService.all(this.company.id);
        this.tradingList = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

</style>
