<template>
  <p class="dateHighlight">
    <span>{{ day }}</span>
    <span>{{ month }}</span>
    <span>{{ year }}</span>
  </p>
</template>

<script>
export default {
  props: {
    date: [Date, String]
  },
  computed: {
    day() {
      return window.dayjs(this.date)
        .format('DD');
    },
    month() {
      return window.dayjs(this.date)
        .format('MMM')
        .toUpperCase();
    },
    year() {
      return window.dayjs(this.date)
        .format('YYYY');
    }
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.dateHighlight {
  font-family: config.$font-headline;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    color: config.$brown-dark;

    &:first-child {
      font-size: 2em;
      margin-bottom: 5px;
      font-weight: 600;
    }

    &:not(&:first-child) {
      font-size: 1.05em;
      font-weight: 500;
    }
  }
}
</style>
