<template>
  <div>
    <transition
      name="fade"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div v-show="modelValue" style="overflow: hidden">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    },
    startTransition(el) {
      // eslint-disable-next-line
      el.style.height = `${el.scrollHeight}px`;
    },
    endTransition(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  will-change: height;
  transition: height 0.3s ease;
}
.fade-leave-to,
.fade-enter-from {
  height: 0 !important;
}
</style>
