<template>
  <HeroLoader :loading="isLoadingPage">
    <form @submit.prevent="onSubmit" class="grid grid--start">
      <Box :headline="headerLabel" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Overskrift">
            <BaseInput v-model="form.title"/>
          </BaseField>
          <BaseColumn>
            <BaseField label="Adresse">
              <BaseInput v-model="form.address"/>
            </BaseField>
            <BaseField label="Postnr & By">
              <ZipcodeSelector v-model="form.zipcode"/>
            </BaseField>
          </BaseColumn>
          <BaseField label="Beskrivelse">
            <Editor v-model="form.description"/>
          </BaseField>
          <BaseField label="Særlig kvalitet i løsningen">
            <BaseTextarea v-model="form.qualityDescription" autosize :max-length="100"/>
          </BaseField>
          <BaseField label="Samarbejdspartnere">
            <BaseTextarea v-model="form.partnerDescription" autosize :max-length="100"/>
          </BaseField>
          <BaseField>
            <BaseButton :label="buttonLabel"/>
          </BaseField>
        </div>
      </Box>
      <div class="col-12 col-xl-6">
        <div class="grid">
          <Box headline="Links (Video + eksterne links)" class="col-12">
            <div class="box-body">
              <div class="single-link" v-for="(link, linkIndex) in links" :key="linkIndex">
                <BaseColumn>
                  <BaseField label="Type" style="max-width: 40%">
                    <BaseSelect v-model="link.type">
                      <option value="youtube">Youtube Video</option>
                      <option value="vimeo">Vimeo Video</option>
                      <option value="link">Eksternt link</option>
                    </BaseSelect>
                  </BaseField>
                  <BaseField label="Overskrift">
                    <BaseInput v-model="link.title"/>
                  </BaseField>
                </BaseColumn>
                <BaseField label="Link" :help="getLinkHelpText(link)">
                  <BaseInput v-model="link.link"/>
                </BaseField>
                <div class="single-link__remove" @click.prevent="onRemoveLink(linkIndex)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </div>
              </div>
              <div style="width: 100%; text-align: right;">
                <BaseButton type="info" size="small" @click.prevent="onAddNewLink">
                  Tilføj nyt link
                </BaseButton>
              </div>
            </div>
          </Box>
          <Box headline="Billeder" class="col-12">
            <div class="box-body">
              <div class="single-link" v-for="(image, uploadIndex) in images" :key="uploadIndex">
                <BaseColumn>
                  <BaseField label="" style="width: 160px;">
                    <BaseImage :src="image.fullImagePath"/>
                  </BaseField>
                  <BaseField label="Kort billedtekst">
                    <BaseInput v-model="image.description"/>
                  </BaseField>
                </BaseColumn>
                <div class="single-link__remove" @click.prevent="onRemoveImage(uploadIndex)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </div>
              </div>
              <BaseField label="Upload billeder">
                <FileUpload @upload="onUpload" multiple></FileUpload>
              </BaseField>
            </div>
          </Box>
        </div>
      </div>
    </form>
  </HeroLoader>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import FileUpload from '@/containers/FileUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import AdminCompanyProjectService from '@/services/Admin/AdminCompanyProjectService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseImage from '@/components/BaseImage.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import Editor from '@/containers/Editor.vue';
import LinkType from '@/helpers/LinkType';
import BaseSelect from '@/components/BaseSelect.vue';

export default {
  props: {
    editId: {
      type: Number,
      default: null
    }
  },
  emits: ['created', 'updated'],
  components: {
    HeroLoader,
    BaseImage,
    BaseButton,
    ZipcodeSelector,
    BaseColumn,
    BaseTextarea,
    BaseInput,
    FileUpload,
    BaseField,
    Box,
    Editor,
    BaseSelect
  },
  data() {
    return {
      links: [],
      images: [],
      form: {
        title: '',
        address: '',
        zipcode: '',
        description: '',
        partnerDescription: '',
        qualityDescription: ''
      }
    };
  },
  computed: {
    buttonLabel() {
      return this.editId ? 'Gem case' : 'Opret case';
    },
    headerLabel() {
      return this.editId ? 'Opdater case' : 'Tilføj ny case';
    }
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    if (this.editId) {
      this.startPageLoading();
      await this.loadProject();
      this.stopPageLoading();
    }
  },
  methods: {
    onRemoveLink(linkIndex) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette linket?')) {
        this.links = this.links.filter((link, i) => i !== linkIndex);
      }
    },
    onRemoveImage(imageIndex) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette billedet?')) {
        this.images = this.images.filter((link, i) => i !== imageIndex);
      }
    },
    getEmptyLink() {
      return {
        type: LinkType.YOUTUBE,
        title: '',
        link: ''
      };
    },
    onAddNewLink() {
      this.links.push(this.getEmptyLink());
    },
    getLinkHelpText(link) {
      switch (link.type) {
        case LinkType.LINK:
          return 'Link vil automatisk åbnes i nyt vindue';
        case LinkType.YOUTUBE:
          return 'Eksempel: https://www.youtube.com/watch?v=peMRaht4Cwc';
        case LinkType.VIMEO:
          return 'Eksempel: https://vimeo.com/237527670';
        default:
          return '';
      }
    },
    async loadProject() {
      Message.clear();
      this.startLoading('Henter case');

      try {
        const payload = await AdminCompanyProjectService.show(this.company.id, this.editId);
        const project = payload?.data?.data;
        if (project) {
          this.form.zipcode = project.location.zipcode;
          this.form.address = project.location.address;
          this.form.title = project.title;
          this.form.partnerDescription = project.partnerDescription;
          this.form.qualityDescription = project.qualityDescription;
          this.form.description = project.description;

          this.images = project?.images?.map((image) => ({
            fullImagePath: image.path,
            imagePath: image.imagePath,
            description: image.description
          }));
          this.links = project?.links?.map((link) => ({
            type: link.type,
            title: link.title,
            link: link.link
          }));
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    onSubmit() {
      if (!this.editId) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    getRequestObject() {
      return {
        title: this.form.title,
        description: this.form.description,
        partner_description: this.form.partnerDescription,
        quality_description: this.form.qualityDescription,
        address: this.form.address,
        zipcode: this.form.zipcode,
        links: this.links.map((link) => ({
          title: link.title,
          type: link.type,
          link: link.link
        })),
        images: this.images.map((image) => ({
          path: image.imagePath,
          description: image.description
        }))
      };
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer case');

      try {
        const payload = await AdminCompanyProjectService.update(this.company.id, this.editId, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('updated');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter ny case');

      try {
        const payload = await AdminCompanyProjectService.store(this.company.id, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('created');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onUpload(formData) {
      Message.clear();
      this.startLoading('Uploader billede');
      try {
        const payload = await AdminCompanyProjectService.upload(this.company.id, formData);
        Message.httpSuccess(payload);

        // eslint-disable-next-line no-unused-expressions
        payload?.data?.data?.forEach((image) => {
          this.images.push({
            fullImagePath: image.fullImagePath,
            imagePath: image.imagePath,
            description: ''
          });
        });
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
.single-link {
  background: #eee;
  padding: 10px;
  border-radius: config.$field-border;
  position: relative;

  @include config.not_last {
    margin-bottom: 10px;
  }

  &__remove {
    background: config.$brown;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
</style>
