<template>
  <div class="product-images">
    <div class="slider" ref="slider">
      <div v-for="image in images" :key="image.id">
        <slot :image="image">
          <div class="product-image">
            <img :src="image.path" alt=""/>
            <p class="product-label" v-if="image.description">{{ image.description }}</p>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    items: {
      type: Number,
      default: 3
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoheight: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    tns({
      container: this.$refs.slider,
      items: window.innerWidth > 1024 ? this.items : 1,
      nav: false,
      controls: true,
      edgePadding: this.edgePadding,
      autoplay: false,
      gutter: this.gutter,
      loop: this.loop,
      mouseDrag: true,
      swipeAngle: false,
      // fixedWidth: 700,
      autoHeight: this.autoheight,
      center: true,
      controlsText: ['&#8592;', '\t&#8594;']
    });
  },
  computed: {
    gutter() {
      if (this.items > 1) {
        return window.innerWidth > 1024 ? 24 : 12;
      }
      return 0;
    },
    edgePadding() {
      if (this.items > 1) {
        return 40;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.gallery {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //max-height: 200px;
  }
}
</style>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

img {
  max-width: 100%;
}

.tns-outer {
  padding: 0 !important
}

.tns-outer [hidden] {
  display: none !important
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%
}

.tns-no-calc {
  position: relative;
  left: 0
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s
}

.tns-autowidth {
  display: inline-block
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6
}

.tns-lazy-img.tns-complete {
  opacity: 1
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s
}

.tns-ovh {
  //overflow: hidden
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em
}

.tns-transparent {
  opacity: 0;
  visibility: hidden
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1
}

.tns-vpfix {
  white-space: nowrap
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left
}

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */

.product-list-details {
  > *:not(:last-child) {
    padding-bottom: 35px;
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
  }
}

.product-images {
  position: relative;
  margin-bottom: 60px;
  grid-area: content;
}

.tns-inner {
  //max-height: 350px;
}

.product-image {
  height: 40vh;
  //overflow: hidden;
  background: #fff;
  position: relative;
  border-radius: 10px;

  //@media screen and (min-width: 992px) {
  //    height: 400px;
  //}
  //
  img {
    user-select: none;
    //padding-top: 10px;
    //padding-bottom: 10px;
    //object-fit: cover;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;
  }
}

.product-label {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  //background: rgba(0,0,0,.75);
  //color: config.$white;
  padding: 3px 15px;
  border-radius: 10px;
  font-size: 14px;
  pointer-events: none;
  width: 100%;
}

.tns-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
  padding: 0 10px;
  z-index: 22;

  button {
    border: none;
    background: rgba(0, 0, 0, .5);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    font-size: 13px;
    color: #fff;
    pointer-events: all;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    line-height: 1;

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
