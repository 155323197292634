<template>
  <Box class="infoBox" light-shadow>
    <div class="infoBox__icon">
      <font-awesome-icon :icon="icon"></font-awesome-icon>
    </div>
    <div class="infoBox__content">
      <slot />
    </div>
  </Box>
</template>

<script>
import Box from '@/components/Box.vue';

export default {
  components: { Box },
  props: {
    icon: {
      type: String,
      default: 'circle'
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.infoBox {
  display: flex;
  background: white;
  flex-grow: 1;
  min-height: 40px;

  &__icon {
    width: 35px;
    flex-shrink: 0;
    background: config.$brown;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: white;
      font-size: 10px;
    }
  }

  &__content {
    padding: 10px;
    font-size: 0.95em;
    position: relative;
    flex-grow: 1;
    word-break: break-word;
  }
}
</style>
