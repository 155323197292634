<template>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Brugere" class="mb-2">
        <BaseButton type="info" label="Vis brugere" @click.prevent="goToList"
                    v-if="!showList"/>
      </BasePageHeader>
      <HeroLoader :loading="isLoadingPage">
        <div class="grid">
          <div class="col-12 col-xl-10" v-if="showList">
            <Box headline="Brugere">
              <div class="box-body">
                <div class="table-wrapper">
                  <table class="table">
                    <thead>
                    <tr>
                      <td>Navn</td>
                      <td>E-mail</td>
                      <td>Telefon</td>
                      <td>Administrator</td>
                      <td>Virksomhedsansvarlig</td>
                      <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users" :key="user.id">
                      <td>
                        <p class="name">{{ user.name.full }}</p>
                        <p>{{ user.position }}</p>
                      </td>
                      <td>
                        <p class="email">{{ user.email }}</p>
                      </td>
                      <td>{{ user.phone }}</td>
                      <td :class="user.isAdministrator ? 'true' : 'false'">
                        <font-awesome-icon
                          :icon="user.isAdministrator ? 'check' : 'times'"></font-awesome-icon>
                      </td>
                      <td :class="user.isAccountHolder ? 'true' : 'false'">
                        <font-awesome-icon
                          :icon="user.isAccountHolder ? 'check' : 'times'"></font-awesome-icon>
                      </td>
                      <td class="actions">
                        <button class="edit" :disabled="isLoading" @click.prevent="onEdit(user)">
                          <font-awesome-icon icon="pen"></font-awesome-icon>
                        </button>
                        <button class="delete" :class="{'not-allowed': authUser.id === user.id}"
                                @click.prevent="onDelete(user)"
                                :disabled="isLoading"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <BaseButton label="Opret ny bruger" style="margin-top: 2rem;"
                            @click.prevent="showCreateForm = true"/>
              </div>
            </Box>
          </div>
          <div class="col-12 col-xl-8" v-if="!showList">
            <Box headline="Opret bruger">
              <div class="box-body">
                <form @submit.prevent="onSubmit" class="grid">
                  <div class="col-12">
                    <BaseColumn>
                      <BaseField label="Fornavn">
                        <BaseInput required v-model="form.firstName"/>
                      </BaseField>
                      <BaseField label="Efternavn">
                        <BaseInput required v-model="form.lastName"/>
                      </BaseField>
                    </BaseColumn>
                    <BaseField label="E-mail">
                      <BaseInput required v-model="form.email" type="email"/>
                    </BaseField>
                    <BaseColumn>
                      <BaseField label="Titel">
                        <BaseInput v-model="form.title"/>
                      </BaseField>
                      <BaseField label="Telefon">
                        <BaseInput v-model="form.phone"/>
                      </BaseField>
                    </BaseColumn>
                    <BaseColumn>
                      <BaseField
                        help="En administrator har mulighed for at handle på vegne af virksomheden. Hvis du ikke sætter flueben vil denne bruger være markeret som medarbejder.">
                        <BaseCheckbox v-model="form.isAdministrator" label="Administrator"/>
                      </BaseField>
                      <BaseField
                        v-if="authUser.isAccountHolder"
                        help="Der kan kun være én virksomhedsansvarlig. Hvis du markerer denne boks vil du overdrage ejerskabet til denne person.">
                        <BaseCheckbox v-model="form.isAccountHolder" label="Virksomhedsansvarlig"/>
                      </BaseField>
                    </BaseColumn>
                    <BaseButton :label="getSubmitButtonText" style="margin-top: 10px;"/>
                  </div>
                </form>
              </div>
            </Box>
          </div>
        </div>
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import HeroLoader from '@/components/HeroLoader.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import Box from '@/components/Box.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';
import AdminCompanyUserService from '@/services/Admin/AdminCompanyUserService';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

export default {
  components: {
    BaseCheckbox,
    BaseColumn,
    BasePageHeader,
    Box,
    BaseButton,
    BaseInput,
    BaseField,
    HeroLoader
  },
  data() {
    return {
      users: [],
      selectedUser: null,
      form: {
        firstName: '',
        lastName: '',
        phone: '',
        title: '',
        email: '',
        isAdministrator: false,
        isAccountHolder: false
      },
      showCreateForm: false
    };
  },
  computed: {
    showList() {
      return this.showCreateForm === false;
    },
    getSubmitButtonText() {
      if (this.selectedUser) {
        return 'Opdater bruger';
      }
      return 'Opret bruger';
    }
  },
  setup() {
    const {
      user,
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      authUser: user,
      company
    };
  },
  async mounted() {
    this.resetForm();
    this.startPageLoading();
    await this.loadUsers();
    this.stopPageLoading();
  },
  methods: {
    onSubmit() {
      if (this.selectedUser !== null) {
        return this.onUpdateUser();
      }

      return this.onCreateUser();
    },
    goToList() {
      this.showCreateForm = false;
      this.resetForm();
      this.selectedUser = null;
    },
    onEdit(user) {
      this.selectedUser = user;

      this.form = {
        firstName: user.name.first,
        lastName: user.name.last,
        phone: user.phone,
        title: user.position,
        email: user.email,
        isAdministrator: user.isAdministrator,
        isAccountHolder: user.isAccountHolder
      };
      this.showCreateForm = true;
    },
    resetForm() {
      this.form = {
        firstName: '',
        lastName: '',
        phone: '',
        title: '',
        email: '',
        isAdministrator: false,
        isAccountHolder: false
      };
    },
    async onCreateUser() {
      Message.clear();
      this.startLoading('Opretter ny bruger');

      const request = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        is_account_holder: this.form.isAccountHolder,
        is_administrator: this.form.isAdministrator,
        phone: this.form.phone,
        title: this.form.title
      };

      try {
        const payload = await AdminCompanyUserService.store(this.company.id, request);
        Message.httpSuccess(payload);
        this.goToList();
        await this.loadUsers();
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onDelete(user) {
      if (this.authUser.id === user.id) {
        return;
      }
      // eslint-disable-next-line no-alert
      if (!window.confirm(`Sikker på at du vil slette ${user?.name?.full}?`)) {
        return;
      }
      Message.clear();
      this.startLoading(`Sletter ${user?.name?.full}`);

      try {
        const payload = await AdminCompanyUserService.destroy(this.company.id, user.id);
        Message.httpSuccess(payload);

        await this.loadUsers();
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async loadUsers() {
      Message.clear();
      this.startLoading('Henter brugere');

      try {
        const payload = await AdminCompanyUserService.all(this.company.id);
        this.users = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onLogout() {
      await AdminCompanyUserService.logout();
      await this.$router.push({ name: 'auth.login' });
    },
    async onUpdateUser() {
      Message.clear();
      this.startLoading('Opdaterer bruger');

      const request = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        is_account_holder: this.form.isAccountHolder,
        is_administrator: this.form.isAdministrator,
        phone: this.form.phone,
        title: this.form.title
      };

      try {
        const payload = await AdminCompanyUserService.update(this.company.id, this.selectedUser?.id, request);
        Message.httpSuccess(payload);
        this.goToList();
        await this.loadUsers();
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.box-body {

}

.table-wrapper {
  border-radius: 10px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 15px;
  background: #f9f9f9;
  font-family: config.$font-headline;

  td {
    white-space: nowrap;

    .name {
      font-weight: 600;
    }
  }

  thead {
    background: #eee;

    td {
      padding: 10px 15px;
    }
  }

  tbody {
    td {
      padding: 5px 15px;
    }

    tr {
      &:not(:last-child) {
        border-bottom: 2px solid #fff;
      }

      &:nth-child(even) {

      }
    }
  }

  .true {
    color: config.$green;
  }

  .false {
    color: config.$brown-dark;
  }

  .actions {
    text-align: right;

    .edit,
    .delete {
      padding: 9px;
      background: #eee;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: #ddd;
      }
    }

    .edit {
      color: config.$green;
    }

    .delete {
      color: config.$brown-dark;
      margin-left: 5px;

      &.not-allowed {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}
</style>
