export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/events`);
  },
  show(companyId, projectId) {
    return window.axios.get(`/api/admin/company/${companyId}/events/${projectId}`);
  },
  store(companyId, data) {
    return window.axios.post(`/api/admin/company/${companyId}/events`, data);
  },
  update(companyId, caseId, data) {
    return window.axios.put(`/api/admin/company/${companyId}/events/${caseId}`, data);
  },
  destroy(companyId, caseId) {
    return window.axios.delete(`/api/admin/company/${companyId}/events/${caseId}`);
  },
  upload(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/events/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
