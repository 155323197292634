<template>
  <Box hover class="boxWithImage"
       @click.stop="$emit('click')"
       :class="{
          'boxWithImage--highlighted': highlighted,
          'boxWithImage--nullable': !showFigure
        }"
  >
    <p class="boxWithImage__sticker-image" v-if="sticker && !stickerTitle">{{ sticker }}</p>
    <router-link :to="link" class="boxWithImage__figure" v-if="showFigure">
      <img v-if="src" :src="src" alt="" class="boxWithImage__figure-img"/>
      <span>{{ label }}</span>
    </router-link>
    <div class="boxWithImage__content">
      <router-link :to="link" class="boxWithImage__label">
        <p class="boxWithImage__sticker-title" v-if="sticker && stickerTitle">{{ sticker }}</p>
        {{ label }}
      </router-link>
      <slot/>
    </div>
  </Box>
</template>

<script>
import Box from '@/components/Box.vue';

export default {
  name: 'BoxWithImage',
  components: { Box },
  emits: ['click'],
  props: {
    link: {
      type: Object,
      required: true
    },
    src: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    sticker: {
      type: String,
      default: ''
    },
    highlighted: Boolean,
    nullableImage: Boolean,
    stickerTitle: Boolean
  },
  computed: {
    showFigure() {
      if (this.nullableImage) {
        return this.src;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.boxWithImage {
  display: grid;
  grid-template-rows: 140px 1fr;
  position: relative;

  &--nullable {
    grid-template-rows: 1fr;
    .boxWithImage__content {
      padding-top: 4rem;
    }
  }

  &--highlighted {
    @include config.lg {
      grid-template-rows: 1fr;
      min-height: 380px;
      align-items: center;
      //grid-template-columns: 340px 1fr;

      .boxWithImage__figure {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .boxWithImage__content {
        grid-column-end: 1;
        grid-row-start: 1;
        padding: 80px 30px 50px;
      }
    }
  }

  &__sticker-title {
    width: fit-content;
    margin-bottom: 10px;
    z-index: 1;
    background-color: config.$brown;
    color: config.$white;
    font-size: 0.8em;
    padding: 0.3em 1em;
    border-radius: 5px;
  }

  &__sticker-image {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
    background-color: config.$brown;
    color: config.$white;
    font-size: 0.8em;
    padding: 0.3em 1em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__label {
    font-weight: 600;
    font-size: 1.2em;
    font-family: config.$font-headline;
    text-decoration: none;
    color: inherit;
    margin-bottom: 10px;
    display: block;
    word-break: break-word;

    .boxWithImage--highlighted & {
      font-size: 1.8em;
    }
  }

  &__figure {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
    background: config.$grey;

    .boxWithImage--highlighted & {
      min-width: 340px;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      //display: none;
    }

    span {
      color: config.$black;
      padding: 0 1em;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 0.9em;
  }
}
</style>
