<template>
  <file-pond
    ref="pond"
    label-idle="Træk og slip dine filer her"
    :allow-multiple="true"
    accepted-file-types="image/*"
    max-files="20"
    @updatefiles="updateFiles"
  />
</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  props: {
    initialImages: {
      type: Array,
      default: () => []
    }
  },
  emits: ['updated'],
  components: {
    FilePond
  },
  methods: {
    updateFiles(files) {
      this.$emit('updated', files);
    },
    urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }))
        );
    }
  },
  mounted() {
    this.initialImages.forEach((image) => {
      this.urltoFile(`data:${image.mime};base64,${image.base64}`, image.filename, image.mime).then((file) => this.$refs.pond.addFile(file));
    });
  }
};
</script>

<style>

</style>
