<template>
  <div class="area-content">
    <NoResults label="Den side du leder efter findes ikke" />
  </div>
</template>

<script>
import NoResults from '@/components/NoResults.vue';

export default {
  components: { NoResults }
};
</script>

<style scoped>

</style>
