<template>
  <div class="iconAndLabel">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
    <slot>
      <p v-html="label"></p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.iconAndLabel {
  display: flex;
  align-items: flex-start;
  color: #410F0F;
  margin: 5px 0;

  p {
    word-break: break-word;
  }

  svg {
    margin-right: 10px;
    margin-top: 3px;
  }

}
</style>
