<template>
  <button
    :class="[
      'likes',
      compact ? 'likes-compact' : 'likes-full',
      {'likes-compact-liked': liked && compact},
      {'likes-full-liked': liked && !compact},
    ]"
    @click.stop="onLike">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
    {{ compact ? count : text }}
  </button>
</template>

<script>
import LikeService from '@/services/LikeService';
import Message from '@/helpers/Message';

export default {
  emits: ['liked'],
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      default: 0
    },
    liked: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      return this.liked
        ? ['fas', 'thumbs-up']
        : ['far', 'thumbs-up'];
    },
    text() {
      switch (this.count) {
        case 0:
          return 'Like';
        case 1:
          return this.liked
            ? 'Du synes godt om'
            : '1 anden synes godt om';
        default:
          return this.liked
            ? `Du og ${this.count - 1} ${this.count === 2 ? 'anden' : 'andre'} synes godt om`
            : `${this.count} andre synes godt om`;
      }
    }
  },
  methods: {
    async onLike() {
      try {
        const payload = await LikeService.store(this.type, this.id);
        this.$emit('liked', payload?.data?.data);
      } catch (e) {
        Message.httpError(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

svg {
  font-size: 1.1rem;
}
.likes {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.9em;
  pointer-events: all;
  color: #8C8C8C;

  &-compact {
    gap: 0.25rem;

    &-liked {
      color: config.$brown;
    }
  }

  &-full {
    gap: 0.5rem;
    color: white;
    background-color: #8C8C8C;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;

    &-liked {
      background-color: config.$brown;
    }
  }
}
</style>
