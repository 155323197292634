export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/articles`);
  },
  show(companyId, projectId) {
    return window.axios.get(`/api/admin/company/${companyId}/articles/${projectId}`);
  },
  store(companyId, data) {
    return window.axios.post(`/api/admin/company/${companyId}/articles`, data);
  },
  update(companyId, caseId, data) {
    return window.axios.put(`/api/admin/company/${companyId}/articles/${caseId}`, data);
  },
  destroy(companyId, caseId) {
    return window.axios.delete(`/api/admin/company/${companyId}/articles/${caseId}`);
  },
  upload(companyId, formData) {
    return window.axios.post(`/api/admin/company/${companyId}/articles/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
