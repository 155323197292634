// eslint-disable-next-line no-unused-vars
import { computed, ref } from 'vue';
import Message from '@/helpers/Message';
import IndustryService from '@/services/IndustryService';
import useShouldFetch from '@/composables/useShouldFetch';

const state = ref({
  industries: []
});

const { fetch } = useShouldFetch(60);

export default function useIndustries() {
  async function fetchIndustries() {
    await fetch(async () => {
      try {
        const payload = await IndustryService.all();
        state.value.industries = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    });
  }

  return {
    fetchIndustries,
    industries: computed(() => state.value.industries)
  };
}
