<template>
  <ul class="filter-nested-list">
    <li>
      <p :class="{selected: selected === null}" @click="onSelectIndustry({ id: null })">Alle</p>
    </li>
    <li v-for="industry in industriesWithCompaniesAssociated" :key="industry.id">
      <p @click="onSelectIndustry(industry)"
         :class="{selected: selected === industry.id}"
      >
        {{ industry.name }}
      </p>
      <ul v-if="industry.children.length > 0" v-show="showChildren(industry)">
        <li v-for="subindustry in industry.children" :key="subindustry.id">
          <p @click="onSelectIndustry(subindustry)"
             :class="{selected: selected === subindustry.id}">
            {{ subindustry.name }}</p>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    selected: {
      type: [Object, String, Number]
    },
    industries: {
      type: Array,
      required: true
    }
  },
  emits: ['update:selected'],
  computed: {
    industriesWithCompaniesAssociated() {
      return this.industries
        .map((industry) => {
          // industry.totalWithChildren > 0
          const children = industry.children.filter((sub) => sub.totalWithChildren > 0);
          return {
            ...industry,
            children
          };
        })
        .filter((industry) => industry.children.length > 0);
    }
  },
  methods: {
    onSelectIndustry({ id }) {
      this.$emit('update:selected', id);
    },
    showChildren(industry) {
      if (this.selected === industry.id) {
        return true;
      }

      const selectedChildIndex = industry?.children?.findIndex((sub) => sub.id === this.selected);

      return selectedChildIndex > -1;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
</style>
