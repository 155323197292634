<template>
  <div :class="{ sticky }">
    <Box headline="Information">
      <GoogleMap :center="trading.location?.geocode?.position"
                 :locations="[{ item: null, location: trading.location?.geocode?.position }]"
                 style="height: 200px;"/>
      <div class="contact">
        <div class="grid">
          <div class="col-12">
            <h4 class="contact__title">{{ tradingTypeTitle }}</h4>
            <IconAndLabel icon="calendar-alt" :label="trading.timespan"/>
          </div>
          <div class="col-12" v-if="trading.priceIndicator">
            <h4 class="contact__title">Prisindikator</h4>
            <IconAndLabel icon="wallet" :label="trading.priceIndicator"/>
          </div>
        </div>
      </div>
      <div class="contact">
        <h4 class="contact__title">Lokation</h4>
        <div class="grid">
          <IconAndLabel icon="map-marker"
                        class="col-12"
                        :label="trading.location.full_inline"
          />
        </div>
      </div>
      <div class="contact" v-if="trading.contactDetails">
        <h4 class="contact__title">Kontaktoplysninger</h4>
        <div class="grid">
          <div class="col-12 col-sm-6 col-xl-6">
            <IconAndLabel icon="phone" v-if="trading.company.contact.phone">
              <p>
                <a :href="`tel:${trading.company.contact.phone}`">{{
                    trading.company.contact.phone
                  }}</a>
              </p>
            </IconAndLabel>
            <IconAndLabel icon="at" v-if="trading.company.contact.email">
              <p>
                <a :href="`mailto:${trading.company.contact.email}`"
                   :title="trading.company.contact.email">Send
                  mail</a>
              </p>
            </IconAndLabel>
          </div>
        </div>
        <div v-html="trading.contactDetails" style="margin-top: 1rem;"></div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import TradingType from '@/helpers/TradingType';

export default {
  components: {
    IconAndLabel,
    GoogleMap,
    Box
  },
  props: {
    trading: Object,
    sticky: Boolean
  },
  data() {
    return {
      tradingType: TradingType
    };
  },
  computed: {
    tradingTypeTitle() {
      switch (this.trading?.type?.key) {
        case TradingType.RENTING:
          return 'Salg / udlejning';
        case TradingType.SEARCHING:
          return 'Søger i perioden';
        default:
          return 'Periode';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.sticky {
  position: sticky;
  top: 20px;
}

.button {
  margin-top: 1rem;
}

.contact {
  margin: 1.5em;

  &__title {
    padding-bottom: 0.7em;
    font-weight: 500;
  }
}
</style>
