import { ref } from 'vue';

/**
 * Timer in seconds
 * @param timer
 * @returns {{fetch: ((function(*): Promise<void>)|*)}}
 */
export default function useShouldFetch(timer) {
  const state = ref({
    last: Date.now(),
    hasFetchedInitial: false
  });
  function shouldFetch() {
    const now = Date.now();
    const difference = now - state.value.last;

    return difference / 1000 > timer;
  }

  async function fetch(cb) {
    if (shouldFetch() || state.value.hasFetchedInitial === false) {
      await cb();
      state.value.last = Date.now();
      state.value.hasFetchedInitial = true;
    }
  }

  return {
    fetch
  };
}
