import axios from 'axios';

window.uptime = {
  baseUrl: process.env.VUE_APP_API_URL
};

window.axios = axios;
window.axios.defaults.withXSRFToken = true;
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
