<template>
  <HeroLoader :loading="isLoadingPage">
    <form @submit.prevent="onSubmit" class="grid grid--start">
      <Box headline="Generelt" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Annoncetype" :help="selectedTradingType?.description">
            <BaseSelect v-model="selectedTradingTypeId">
              <option v-for="type in tradingTypes" :key="type.id" :value="type.id">
                {{ type.name }}
              </option>
            </BaseSelect>
          </BaseField>
          <BaseField label="Kategori" :help="selectedTradingCategory?.description">
            <BaseSelect v-model="selectedTradingCategoryId">
              <option v-for="category in tradingCategories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </BaseSelect>
          </BaseField>
          <BaseField label="Overskrift">
            <BaseInput v-model="form.name"/>
          </BaseField>
          <BaseColumn>
            <BaseField label="Adresse">
              <BaseInput v-model="form.address"/>
            </BaseField>
            <BaseField label="Postnr & By">
              <ZipcodeSelector v-model="form.zipcode"/>
            </BaseField>
          </BaseColumn>
          <BaseColumn>
            <BaseField label="Periode fra">
              <BaseInput type="date"
                         v-model="form.startAt"
                         :min="minDate"
                         :max="maxDate"
              />
            </BaseField>
            <BaseField label="Periode til">
              <BaseInput type="date"
                         v-model="form.endAt"
                         :min="minDate"
                         :max="maxDate"
              />
            </BaseField>
          </BaseColumn>
          <BaseField label="Hvor længe skal annoncen være synlig?">
            <BaseInput type="date" v-model="form.publishedUntilAt" :min="minDate"/>
          </BaseField>
          <BaseField label="Prisindikator">
            <BaseTextarea v-model="form.priceIndicator" :max-length="250"/>
          </BaseField>
          <BaseField label="Upload billede">
            <FileUpload @upload="onUpload" v-if="images.length === 0"></FileUpload>
            <div v-else>
              <BaseImage :src="images[0].fullImagePath"/>
              <p style="text-align: right; font-weight: 600; font-size: 0.9em;">
                <a href="#" @click.prevent="onDeleteImage">Slet billede</a>
              </p>
            </div>
          </BaseField>
        </div>
      </Box>
      <Box headline="Detaljer" class="col-12 col-xl-6">
        <div class="box-body">
          <BaseField label="Manchet / Kort introduktion til annoncen">
            <BaseTextarea v-model="form.manchet" :max-length="250"/>
          </BaseField>
          <BaseField label="Beskrivelse">
            <Editor v-model="form.body"/>
          </BaseField>
          <BaseField label="Kontaktoplysninger">
            <BaseTextarea v-model="form.contactDetails" :max-length="250"/>
          </BaseField>
          <BaseField label="Link til yderligere oplysninger">
            <BaseInput v-model="form.readMoreLink"/>
          </BaseField>
          <BaseField>
            <BaseButton :label="buttonLabel"/>
          </BaseField>
        </div>
      </Box>
    </form>
  </HeroLoader>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import FileUpload from '@/containers/FileUpload.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseColumn from '@/components/BaseColumn.vue';
import ZipcodeSelector from '@/containers/ZipcodeSelector.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import AdminCompanyTradingService from '@/services/Admin/AdminCompanyTradingService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import BaseImage from '@/components/BaseImage.vue';
import HeroLoader from '@/components/HeroLoader.vue';
import dayjs from 'dayjs';
import Editor from '@/containers/Editor.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import TradingService from '@/services/TradingService';
import BaseTextarea from '@/components/BaseTextarea';

export default {
  props: {
    editId: {
      type: Number,
      default: null
    }
  },
  emits: ['created', 'updated'],
  components: {
    BaseTextarea,
    BaseSelect,
    Editor,
    HeroLoader,
    BaseImage,
    BaseButton,
    ZipcodeSelector,
    BaseColumn,
    BaseInput,
    FileUpload,
    BaseField,
    Box
  },
  data() {
    return {
      fullImagePath: '',
      tradingTypes: [],
      tradingCategories: [],
      minDate: dayjs()
        .format('YYYY-MM-DD'),
      maxDate: dayjs()
        .add(10, 'year')
        .format('YYYY-MM-DD'),
      selectedTradingTypeId: null,
      selectedTradingCategoryId: null,
      images: [],
      form: {
        name: '',
        manchet: '',
        body: '',
        address: '',
        zipcode: '',
        contactDetails: '',
        startAt: dayjs()
          .startOf('day').format('YYYY-MM-DD'),
        endAt: dayjs()
          .startOf('day').format('YYYY-MM-DD'),
        publishedUntilAt: dayjs()
          .startOf('day')
          .add(30, 'days').format('YYYY-MM-DD'),
        readMoreLink: '',
        priceIndicator: ''
      }
    };
  },
  computed: {
    buttonLabel() {
      return this.editId ? 'Gem annonce' : 'Opret annonce';
    },
    selectedTradingType() {
      return this.tradingTypes?.find((type) => type.id === this.selectedTradingTypeId);
    },
    selectedTradingCategory() {
      return this.tradingCategories?.find((category) => category.id === this.selectedTradingCategoryId);
    }
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await Promise.all([this.fetchTradingTypes(), this.fetchTradingCategories()]);
    if (this.editId) {
      await this.loadTrading();
    } else {
      this.selectedTradingTypeId = _.first(this.tradingTypes)?.id;
      this.selectedTradingCategoryId = _.first(this.tradingCategories)?.id;
    }
    this.stopPageLoading();
  },
  methods: {
    async fetchTradingTypes() {
      try {
        const payload = await TradingService.tradingTypes();
        this.tradingTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async fetchTradingCategories() {
      try {
        const payload = await TradingService.tradingCategories();
        this.tradingCategories = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    getRequestObject() {
      return {
        name: this.form.name,
        manchet: this.form.manchet,
        body: this.form.body,
        address: this.form.address,
        zipcode: this.form.zipcode,
        start_at: this.form.startAt,
        end_at: this.form.endAt,
        price_indicator: this.form.priceIndicator,
        readmore_link: this.form.readMoreLink,
        contact_details: this.form.contactDetails,
        published_until_at: this.form.publishedUntilAt,
        trading_type_id: this.selectedTradingTypeId,
        trading_category_id: this.selectedTradingCategoryId,
        images: this.images.map((image) => ({
          path: image.imagePath,
          description: image.description
        }))
      };
    },
    async loadTrading() {
      Message.clear();
      this.startLoading('Henter annonce');

      try {
        const payload = await AdminCompanyTradingService.show(this.company.id, this.editId);
        const trading = payload?.data?.data;
        if (trading) {
          this.form.name = trading.name;
          this.form.manchet = trading.manchet || '';
          this.form.address = trading.location.address;
          this.form.zipcode = trading.location.zipcode;
          this.form.body = trading.body;
          this.form.priceIndicator = trading.priceIndicator || '';
          this.form.startAt = dayjs(trading?.startAt?.value).startOf('day').format('YYYY-MM-DD');
          this.form.endAt = dayjs(trading?.endAt?.value).startOf('day').format('YYYY-MM-DD');
          this.form.publishedUntilAt = dayjs(trading?.publishedUntilAt?.value).startOf('day').format('YYYY-MM-DD');
          this.form.readMoreLink = trading?.links?.readMore;
          this.form.contactDetails = trading?.contactDetails || '';

          this.selectedTradingTypeId = trading?.type?.id;
          this.selectedTradingCategoryId = trading?.category?.id;

          this.images = trading?.images?.map((image) => ({
            fullImagePath: image.path,
            imagePath: image.imagePath
          }));
        } else {
          Message.error({ message: 'Noget gik galt' });
        }
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    onDeleteImage() {
      this.images = [];
    },
    onSubmit() {
      if (!this.editId) {
        this.onCreate();
        return;
      }

      this.onUpdate();
    },
    async onUpdate() {
      Message.clear();
      this.startLoading('Opdaterer annonce');

      try {
        const payload = await AdminCompanyTradingService.update(this.company.id, this.editId, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('updated');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter annonce');

      try {
        const payload = await AdminCompanyTradingService.store(this.company.id, this.getRequestObject());
        Message.httpSuccess(payload);
        this.$emit('created');
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    },
    async onUpload(formData) {
      Message.clear();
      this.startLoading('Uploader billede');
      try {
        const payload = await AdminCompanyTradingService.upload(this.company.id, formData);
        Message.httpSuccess(payload);

        // eslint-disable-next-line no-unused-expressions
        payload?.data?.data?.forEach((image) => {
          this.images.push({
            fullImagePath: image.fullImagePath,
            imagePath: image.imagePath,
            description: ''
          });
        });
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.list {
  list-style: none;

  li {
    margin: 5px 0;
    padding: 10px;
    font-size: 0.9em;
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}
</style>
