export default {
  all(companyId) {
    return window.axios.get(`/api/admin/company/${companyId}/users`);
  },
  store(companyId, data) {
    return window.axios.post(`/api/admin/company/${companyId}/users`, data);
  },
  update(companyId, userId, data) {
    return window.axios.put(`/api/admin/company/${companyId}/users/${userId}`, data);
  },
  destroy(companyId, userId) {
    return window.axios.delete(`/api/admin/company/${companyId}/users/${userId}`);
  }
};
