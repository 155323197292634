import toastr from 'toastr';
import _ from 'lodash';

toastr.options.timeOut = 1500;

export default {
  success({ message }, capitalize = true) {
    toastr.success(capitalize ? _.capitalize(message) : message);
  },
  error({ message }, capitalize = true) {
    toastr.error(capitalize ? _.capitalize(message) : message);
  },
  httpSuccess(payload) {
    if (payload?.data?.message !== null) {
      toastr.success(_.capitalize(payload?.data?.message));
    }
  },
  httpError(errorResponse) {
    const error = errorResponse?.response?.data?.message || 'Der skete en fejl';
    toastr.error(_.capitalize(error));
  },
  httpErrors(errorResponse) {
    const errors = errorResponse?.response?.data?.errors;
    if (!errors) {
      this.httpError(errorResponse);
    } else {
      Object.keys(errors)
        .forEach((error) => {
          toastr.error(_.capitalize(errors[error][0]));
        });
    }
  },
  clear() {
    toastr.clear();
  }
};
