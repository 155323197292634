<template>
  <div class="pagination-wrapper">
    <v-pagination
      v-model="state"
      :pages="pages"
      :range-size="1"
    />
  </div>
</template>

<script>
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';

export default {
  props: {
    modelValue: Number,
    pages: Number,
    scrollTop: Boolean
  },
  emits: ['update:modelValue'],
  components: {
    VPagination
  },
  computed: {
    state: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.scrollToTop();
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    scrollToTop() {
      if (this.scrollTop === true) {
        const main = document.getElementById('main');
        if (!main) {
          return;
        }
        main.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style lang="scss">
@use "~@/styles/config/_index.scss" as config;

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.Page {
  font-size: 1rem;
  color: config.$black-light;
  width: 40px;
  height: 30px;
  transition: all 250ms ease;

  &:hover {
    border-color: config.$brown;
  }

  &.Page-active {
    background-color: config.$brown !important;
    color: white;
    border-radius: 5px;
  }
}
</style>
