<template>
  <li class="tag">
    {{ name }}
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.tag {
  background-color: config.$brown;
  display: inline-block;
  color: config.$white;
  padding: 0.5em 1em;
  font-size: 14px;
  margin: 5px;
  user-select: none;
  border-radius: 5px;
}
</style>
