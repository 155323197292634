<template>
  <BaseSidebar open-sidebar-label="Åben navigation">
    <div class="nav">
      <router-link v-for="item in navigation" :key="item.route" tag="li" :to="{ name: item.route }">
        {{ item.title }}
      </router-link>
    </div>
  </BaseSidebar>
  <div class="area-content">
    <router-view></router-view>
  </div>
</template>

<script>
import BaseSidebar from '@/components/BaseSidebar.vue';
import Modules from '@/helpers/Modules';
import useAuth from '@/composables/useAuth';

export default {
  name: 'Admin.vue',
  components: { BaseSidebar },
  setup() {
    const { hasSubscription, user } = useAuth();

    return {
      hasSubscription,
      user
    };
  },
  computed: {
    navigation() {
      return [
        {
          title: 'Virksomhedsprofil',
          route: 'admin.company',
          visible: this.hasSubscription(Modules.COMPANY) && this.user.isCompanyAdmin
        },
        {
          title: 'Virksomhedsbilleder',
          route: 'admin.companyImages',
          visible: this.hasSubscription(Modules.COMPANY) && this.user.isCompanyAdmin
        },
        {
          title: 'Branche',
          route: 'admin.industries',
          visible: this.hasSubscription(Modules.COMPANY) && this.user.isCompanyAdmin
        },
        {
          title: 'Kompetencer',
          route: 'admin.skills',
          visible: this.hasSubscription(Modules.COMPANY) && this.user.isCompanyAdmin
        },
        {
          title: 'Cases',
          route: 'admin.cases',
          visible: this.hasSubscription(Modules.COMPANY) && this.user.isCompanyAdmin
        },
        {
          title: 'Events',
          route: 'admin.events',
          visible: this.hasSubscription(Modules.EVENT) && this.user.isCompanyAdmin
        },
        {
          title: 'Udbud',
          route: 'admin.tasks',
          visible: this.hasSubscription(Modules.TASK) && this.user.isCompanyAdmin
        },
        {
          title: 'Artikler',
          route: 'admin.blog',
          visible: this.hasSubscription(Modules.BLOG) && this.user.isCompanyAdmin
        },
        {
          title: 'Brugere',
          route: 'admin.users',
          visible: this.hasSubscription(Modules.USER) && this.user.isCompanyAdmin
        },
        {
          title: 'Ressourcer',
          route: 'admin.trading',
          visible: this.hasSubscription(Modules.TRADING) && this.user.isCompanyAdmin
        },
        {
          title: 'Sikkerhed',
          route: 'admin.profile',
          visible: true
        }
      ].filter((item) => item.visible === true);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.nav {
  margin-left: calc(#{config.$sidebar-padding} * -1);
  margin-right: calc(#{config.$sidebar-padding} * -1);
  list-style: none;

  a {
    display: block;
    padding: 15px config.$sidebar-padding;
    text-decoration: none;

    color: inherit;

    &.router-link-exact-active {
      font-weight: 700;
    }

    &:nth-child(even) {
      background-color: rgba(#CAE8D6, 0.35);
    }
  }
}
</style>
