<template>
  <BoxWithImage
    class="trading"
    hover
    :label="trading.name"
    :src="trading.primaryImage?.path"
    :sticker="trading?.type?.name"
    :link="link"
    @click="$router.push(link)"
  >
    <div class="grid">
      <IconAndLabel
        icon="map-marker"
        class="col-12 col-sm-6 col-md-12 col-xl-12"
        :label="trading.location.full_html"
      />
    </div>
    <slot/>
    <div class="card-footer">
      <hr />
      <div class="interactions">
        <LikeCounter
          type="trading"
          :id="trading.id"
          :liked="trading.liked"
          :count="trading.likeCounter"
          @liked="onLike"
        />
        <CommentCount :count="trading.totalComments" />
      </div>
    </div>
  </BoxWithImage>
</template>

<script>
import BoxWithImage from '@/containers/BoxWithImage.vue';
import IconAndLabel from '@/components/IconAndLabel.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  emits: ['liked'],
  components: {
    IconAndLabel,
    BoxWithImage,
    LikeCounter,
    CommentCount
  },
  props: {
    trading: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return {
        name: 'trading.show',
        params: {
          id: this.trading?.id
        }
      };
    }
  },
  methods: {
    onLike(data) {
      this.$emit('liked', data);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.trading {
  position: relative;
  font-size: 16px;
  cursor: pointer;
}
.card-footer {
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
hr {
  background-color: #CFD2CF;
  border: none;
  height: 1px;
  margin: 0.75rem 0;
  box-shadow: none;
}
.interactions {
  display: flex;
  justify-content: space-between;
}
</style>
