<template>
  <div class="field">
    <BaseLabel v-if="label" :label="label" />
    <slot />
    <BaseHelp v-if="help" :label="help" />
  </div>
</template>

<script>
import BaseHelp from '@/components/BaseHelp.vue';
import BaseLabel from '@/components/BaseLabel.vue';

export default {
  components: { BaseLabel, BaseHelp },
  props: {
    label: {
      type: String,
      required: false
    },
    help: {
      type: String,
      required: false
    }
  }
};
</script>
