<template>
  <BaseControl>
    <label class="checkbox" :class="{'checkbox--checked': selected}">
      <input v-model="modelState"
             type="checkbox"
             :value="value"
             v-bind="$attrs"/>
      <span class="checkbox__icon">
        <font-awesome-icon v-if="selected" icon="check" size="xs"></font-awesome-icon>
      </span>
      <span class="checkbox__label">{{ label }} <slot /></span>
    </label>
  </BaseControl>
</template>

<script>
import BaseControl from '@/components/BaseControl.vue';
import useVModel from '@/composables/useVModel';

export default {
  props: {
    modelValue: [Array, Boolean],
    value: [String, Number, Boolean],
    label: [String, Number]
  },
  components: {
    BaseControl
  },
  emits: ['update:modelValue'],
  computed: {
    selected() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === true;
    }
  },
  setup(props) {
    return {
      modelState: useVModel(props, 'modelValue')
    };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.checkbox {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    left: -1000000px;
  }

  &__icon {
    min-width: 20px;
    height: 20px;
    width: 20px;
    display: flex;
    border: 2px solid config.$grey;
    border-radius: config.$field-border;
    background-color: config.$white;
    outline: none;
    margin-right: 7px;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-out;

    .checkbox--checked & {
      background: config.$green;
      border-color: config.$green;

      svg {
        color: white;
      }
    }

    &:focus {
      border-color: config.$green;
    }
  }

  &__label {
    font-size: 1em;
    word-break: break-word;
  }
}
</style>
