<template>
  <ul class="tags">
    <slot></slot>
    <IndustryTag v-for="industry in industries"
                 :key="industry.id"
                 :name="industry.name"
    />
  </ul>
</template>

<script>
import IndustryTag from '@/components/IndustryTag.vue';

export default {
  components: { IndustryTag },
  props: {
    industries: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.tags {
  list-style: none;
  margin-left: -5px;
  margin-right: -5px;

  li {
    background-color: config.$brown;
    display: inline-block;
    color: config.$white;
    padding: 0.5em 1em;
    font-size: 14px;
    margin: 5px;
    user-select: none;
    border-radius: 5px;
  }
}
</style>
