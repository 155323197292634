<template>
  <div class="area-content">
    <div class="wrapper">
      <HeroLoader :loading="isLoadingPage">
        <form @submit.prevent="onCreate" class="grid">
          <Box headline="Debatindlæg" class="col-12 col-lg-8 col-xl-6">
            <div class="box-body">
              <BaseField label="Overskrift">
                <BaseInput v-model="form.title"/>
              </BaseField>
              <BaseField label="Kategori">
                <BaseSelect v-model="selectedForumTypeId">
                  <option v-for="taskType in forumTypes" :key="taskType.id" :value="taskType.id">
                    {{ taskType.name }}
                  </option>
                </BaseSelect>
                <p v-if="selectedCategory && selectedCategory?.description" class="categoryDesc" v-html="selectedCategory.description" />
              </BaseField>
              <BaseField label="Indlæg">
                <Editor v-model="form.body"/>
              </BaseField>
              <BaseField>
                <BaseButton label="Opret indlæg" :disabled="isLoading"/>
              </BaseField>
            </div>
          </Box>
        </form>
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import Box from '@/components/Box.vue';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import Message from '@/helpers/Message';
import ForumService from '@/services/ForumService';
import useAuth from '@/composables/useAuth';
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import Editor from '@/containers/Editor.vue';
import BaseSelect from '@/components/BaseSelect.vue';

export default {
  components: {
    BaseSelect,
    Editor,
    HeroLoader,
    BaseButton,
    BaseInput,
    BaseField,
    Box
  },
  data() {
    return {
      forumTypes: [],
      selectedForumTypeId: null,
      form: {
        title: '',
        body: ''
      }
    };
  },
  computed: {
    selectedCategory() {
      return this.forumTypes.find((type) => type.id === this.selectedForumTypeId);
    }
  },
  setup() {
    const {
      company
    } = useAuth();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      company
    };
  },
  async mounted() {
    this.startPageLoading();
    await this.fetchForumTypes();
    this.stopPageLoading();
  },
  methods: {
    async fetchForumTypes() {
      try {
        const payload = await ForumService.forumTypes();
        this.forumTypes = payload?.data?.data;
      } catch (e) {
        Message.httpErrors(e);
      }
    },
    async onCreate() {
      Message.clear();
      this.startLoading('Opretter indlæg');

      try {
        const payload = await ForumService.store({
          title: this.form.title,
          body: this.form.body,
          forum_type_id: this.selectedForumTypeId
        });
        Message.httpSuccess(payload);
        await this.$router.push({
          name: 'forum.show',
          params: { id: payload?.data?.data?.id }
        });
      } catch (e) {
        Message.httpErrors(e);
      } finally {
        this.stopLoading();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.categoryDesc {
  font-size: 0.9em;
  color: #333;
}
</style>
