<template>
  <LoginLayout class="login">
    <p class="resetPassword">Vælg et nyt kodeord der skal benyttes fremover.</p>
    <form class="login__form" @submit.prevent="onResetPassword">
      <BaseField label="E-mail">
        <BaseInput v-model="form.email" type="email" placeholder="mail@eksempel.dk" icon="user-alt" :loading="loading"
                   required/>
      </BaseField>

      <BaseField label="Adgangskode">
        <BaseInput v-model="form.password" placeholder="********" type="password" icon="key"
                   :loading="loading" required/>
      </BaseField>

      <BaseField label="Gentag adgangskode">
        <BaseInput v-model="form.password_confirmation" placeholder="********" type="password" icon="key"
                   :loading="loading" required/>
      </BaseField>

      <BaseButton label="Nulstil kodeord" :loading="loading" full :disabled="loading"/>
    </form>
    <div>
      <router-link :to="{ name: 'auth.login'}">Gå til login</router-link>
    </div>
  </LoginLayout>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import BaseButton from '@/components/BaseButton.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import useAuth from '@/composables/useAuth';
import Message from '@/helpers/Message';

export default {
  components: {
    LoginLayout,
    BaseInput,
    BaseField,
    BaseButton
  },
  props: {
    token: String,
    email: String
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: ''
      },
      passwordOriginalHeight: null,
      showPasswordField: true
    };
  },
  computed: {
    submitButtonText() {
      return this.showPasswordField ? 'Login' : 'Nulstil kodeord';
    }
  },
  setup() {
    const {
      resetPassword,
      login,
      loading
    } = useAuth();
    return {
      resetPassword,
      login,
      loading
    };
  },
  methods: {
    async onResetPassword() {
      if (this.form.password !== this.form.password_confirmation) {
        Message.error({ message: 'Adgangskode er ikke ens' });
        return;
      }
      const result = await this.resetPassword({
        email: this.form.email,
        token: this.token,
        password: this.form.password,
        passwordConfirmation: this.form.password_confirmation
      });

      if (result) {
        await this.$router.push({ name: 'auth.login', query: { email: this.form.email } });
      }
    }
  },
  created() {
    if (this.email) {
      this.form.email = this.email;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.field {
  overflow: hidden;
}

.login {
  &__form {
    margin-bottom: 10px;
  }
}

a {
  color: config.$green-dark;
}

.resetPassword {
  text-align: center;
  margin-bottom: 25px;
  font-size: 14px;
}
</style>
