import Events from '@/views/Events/Events.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import EventView from '@/views/Events/EventView.vue';
import Modules from '@/helpers/Modules';

export default [
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.EVENT
    }
  },
  {
    path: '/events/:id',
    name: 'events.show',
    component: EventView,
    meta: {
      requireAuth: true,
      layout: DashboardLayout,
      module: Modules.EVENT
    },
    props: true
  }
];
