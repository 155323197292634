export default {
  all({
    q = '',
    relations = [],
    industry = '',
    regions = [],
    page = 1
  }) {
    let query = {
      page
    };
    if (q) {
      query = {
        ...query,
        q
      };
    }
    if (industry) {
      query = {
        ...query,
        industry
      };
    }
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }
    if (Array.isArray(regions) && regions.length > 0) {
      query = {
        ...query,
        regions: regions.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);

    return window.axios.get(`/api/companies?${queryFormatted.toString()}`);
  },
  show({
    id,
    relations = []
  }) {
    let query = {};
    if (Array.isArray(relations) && relations.length > 0) {
      query = {
        ...query,
        relations: relations.join(',')
      };
    }

    const queryFormatted = new URLSearchParams(query);
    return window.axios.get(`/api/companies/${id}?${queryFormatted.toString()}`);
  }
};
