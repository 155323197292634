<template>
  <BaseSidebar>
    <p style="text-align: center" v-if="isCompanyAdmin">
      <BaseButton label="Opret indlæg" @click.prevent="$router.push({ name: 'forum.create' })"/>
    </p>
    <FilterSection v-if="forumTypes.length > 0" label="Kategori">
      <BaseField v-for="taskType in activeForumTypes" :key="taskType.id">
        <BaseCheckbox
          v-model="selectedTaskTypesIds"
          :value="taskType.id"
          :label="taskType.name"
          @update:modelValue="fetchForumPostsByFilter"
        />
      </BaseField>
    </FilterSection>
  </BaseSidebar>
  <div class="area-content">
    <div class="wrapper">
      <BasePageHeader headline="Fagligt debatforum" class="mb-2" slot-grow>
        <BaseSearch v-model="searchterm"
                    icon="search"
                    placeholder="Søg efter debatindlæg"
                    @input="onSearch"
        />
      </BasePageHeader>
      <HeroLoader :empty="forumPosts.length === 0" :loading="isLoadingPage">
        <div class="grid">
          <ForumPostCard
            v-for="post in forumPosts"
            :key="post.id"
            :post="post"
            class="col-12 col-xl-8"
            @liked="onLike(post.id, $event)"
          />
        </div>

        <Pagination
          v-if="showPagination"
          v-model="page"
          :pages="meta.lastPage"
          scroll-top
          @update:modelValue="onPageChange"
        />
      </HeroLoader>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import BaseSidebar from '@/components/BaseSidebar.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import BasePageHeader from '@/containers/BasePageHeader.vue';
import useIndustries from '@/composables/useIndustries';
import FilterSection from '@/containers/FilterSection.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import useZipcode from '@/composables/useZipcode';
import BaseField from '@/components/BaseField.vue';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Message from '@/helpers/Message';
import HeroLoader from '@/components/HeroLoader.vue';
import ForumService from '@/services/ForumService';
import Pagination from '@/containers/Pagination.vue';
import BaseButton from '@/components/BaseButton.vue';
import ForumPostCard from '@/containers/ForumPostCard.vue';
import useAuth from '@/composables/useAuth';

export default {
  components: {
    ForumPostCard,
    BaseButton,
    Pagination,
    HeroLoader,
    BaseField,
    BaseCheckbox,
    FilterSection,
    BasePageHeader,
    BaseSearch,
    BaseSidebar
  },
  data() {
    return {
      selectedIndustry: null,
      selectedRegionIds: [],
      searchterm: '',
      forumPosts: [],
      forumTypes: [],
      selectedTaskTypesIds: [],
      page: 1,
      meta: {
        lastPage: 1,
        perPage: 1,
        total: 1
      }
    };
  },
  computed: {
    showPagination() {
      return this.meta.total > this.meta.perPage;
    },
    activeForumTypes() {
      return this.forumTypes.filter((item) => item.totalPosts > 0);
    }
  },
  setup() {
    const { isCompanyAdmin } = useAuth();
    const {
      fetchIndustries,
      industries
    } = useIndustries();

    const {
      fetchRegions,
      regions
    } = useZipcode();

    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    return {
      startLoading,
      stopLoading,
      isLoadingPage,
      isLoading,
      startPageLoading,
      stopPageLoading,
      fetchIndustries,
      industries,
      fetchRegions,
      regions,
      isCompanyAdmin
    };
  },
  async mounted() {
    this.startPageLoading();
    this.startLoading();
    this.syncQuery();
    await this.fetchForumTypes();
    await this.fetchForumPostsByFilter();
    this.stopPageLoading();
  },
  methods: {
    onLike(id, data) {
      const [post] = this.forumPosts.filter((item) => item.id === id);
      post.liked = data.liked;
      post.likeCounter = data.count;
    },
    async fetchForumTypes() {
      try {
        const payload = await ForumService.forumTypes({ hasContent: true });
        this.forumTypes = payload?.data?.data;
      } catch (e) {
        //
      }
    },
    onPageChange() {
      this.fetchForumPostsByFilter();
      this.setQuery();
    },
    onSelectRegion() {
      // this.searchterm = '';
      this.setQuery();
    },
    async fetchForumPostsByFilter() {
      this.startLoading();
      try {
        const payload = await ForumService.all({
          q: this.searchterm,
          types: this.selectedTaskTypesIds,
          relations: ['user'],
          page: this.page
        });
        this.forumPosts = payload?.data?.data;
        this.meta.lastPage = payload?.data?.meta?.last_page;
        this.meta.perPage = payload?.data?.meta?.per_page;
        this.meta.total = payload?.data?.meta?.total;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
        this.stopLoading();
      }
    },
    // eslint-disable-next-line
    onSearch: debounce(function () {
      this.selectedTaskTypesIds = [];
      this.setQuery();
    }, 250),
    syncQuery() {
      const { query } = this.$route;
      if (query.page) {
        this.page = parseInt(query.page.toString(), 10);
      }
      if (query.regions) {
        this.selectedRegionIds = query.regions
          .toString()
          .split(',')
          .map((x) => parseInt(x.toString(), 10));
      }
      if (query.q) {
        this.searchterm = decodeURIComponent(query.q.toString());
      }
    },
    setQuery() {
      const query = {
        page: this.page,
        regions: this.selectedRegionIds.length > 0 ? this.selectedRegionIds.join(',') : null,
        q: this.searchterm.length > 0 ? encodeURIComponent(this.searchterm) : null
      };

      this.$router.replace({ query });

      this.fetchForumPostsByFilter();
    }
  }
};
</script>
