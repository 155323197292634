<template>
  <header class="header wrapper">
    <div class="header__gfx">
      <div class="header__burger" @click="toggleOnMobile">
        <svg data-name="Icon ionic-ios-menu" xmlns="http://www.w3.org/2000/svg" width="16.875" height="9.843"
             viewBox="0 0 16.875 9.843">
          <path id="Path_192" data-name="Path 192"
                d="M20.671,11.531H5.2a.705.705,0,0,1-.7-.7h0a.705.705,0,0,1,.7-.7H20.671a.705.705,0,0,1,.7.7h0A.705.705,0,0,1,20.671,11.531Z"
                transform="translate(-4.5 -10.125)"/>
          <path id="Path_193" data-name="Path 193"
                d="M20.671,18.281H5.2a.705.705,0,0,1-.7-.7h0a.705.705,0,0,1,.7-.7H20.671a.705.705,0,0,1,.7.7h0A.705.705,0,0,1,20.671,18.281Z"
                transform="translate(-4.5 -12.656)"/>
          <path id="Path_194" data-name="Path 194"
                d="M13.387,25.031h-8.5c-.213,0-.386-.316-.386-.7h0c0-.387.174-.7.386-.7h8.5c.213,0,.386.316.386.7h0C13.773,24.715,13.6,25.031,13.387,25.031Z"
                transform="translate(-4.5 -15.188)"/>
        </svg>
      </div>

      <p v-if="isLoading" class="header__spinner">
        <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        <span v-if="isLoadingMessage">{{ isLoadingMessage }}</span>
      </p>
    </div>

    <div class="header__options">
      <BaseAction label="Giv feedback"
                  icon="comment-dots"
                  style="color: #4BBF71; font-weight: 500"
                  :route="{ name: 'feedback' }"
      />
      <BaseActionDivider/>
      <BaseAction :label="user.company.name"
                  icon="certificate"
                  v-if="hasCompanyModule"
                  :route="{ name: 'companies.show', params: { id: user.company.id } }"
      />
      <BaseActionDivider v-if="hasCompanyModule"/>

      <BaseAction v-if="user.isPortalAdmin"
                  label="Portal"
                  icon="sitemap"
                  :route="portalUrl"
      />
      <BaseActionDivider v-if="user.isPortalAdmin"/>

      <BaseAction label="Administration"
                  icon="cogs"
                  :route="adminLink"
                  :show-active="true"
      />
      <BaseActionDivider />

      <!--      <BaseAction :label="user.fullName" img="https://via.placeholder.com/150" :route="{ name: 'admin.cases' }"/>-->
      <BaseAction :label="user.fullName" :route="{ name: 'admin.profile' }"/>
    </div>
  </header>
</template>

<script>
import useMainNavigation from '@/composables/useMainNavigation';
import BaseAction from '@/components/BaseAction.vue';
import BaseActionDivider from '@/components/BaseActionDivider.vue';
import useAuth from '@/composables/useAuth';
import useHeaderSearch from '@/composables/useHeaderSearch';
import useGlobalLoader from '@/composables/useGlobalLoader';
import Modules from '@/helpers/Modules';
// import BaseInput from '@/components/BaseInput.vue';

export default {
  components: {
    // BaseInput,
    BaseActionDivider,
    BaseAction
  },
  computed: {
    hasCompanyModule() {
      return this.hasSubscription(Modules.COMPANY);
    },
    portalUrl() {
      return `${window.uptime.baseUrl}/admin`;
    },
    adminLink() {
      const name = this.hasCompanyModule && this.user.isCompanyAdmin ? 'admin.company' : 'admin.profile';

      return { name };
    }
  },
  setup() {
    const {
      toggleOnMobile,
      isMobileActive
    } = useMainNavigation();

    const {
      isActive,
      searchterm
    } = useHeaderSearch();

    const {
      user,
      hasSubscription
    } = useAuth();

    const {
      isLoading,
      isLoadingMessage
    } = useGlobalLoader();

    return {
      isLoading,
      isLoadingMessage,
      toggleOnMobile,
      isMobileActive,
      user,
      isActive,
      searchterm,
      hasSubscription
    };
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.divider {
  width: 1px;
  height: 31px;
  margin: 0 12px;
  background-color: config.$grey;
}

.header {
  box-shadow: 3px 3px 5px 2px #ccc;
  display: grid;
  grid-template-areas: "gfx topnav";
  grid-template-columns: auto 1fr;
  z-index: config.$headerZIndex;
  padding-top: 0;
  padding-bottom: 0;

  &__gfx {
    grid-area: gfx;
    display: flex;
    align-items: center;
    @include config.lg {
      margin-left: calc(#{config.$wrapper-padding-mobile} * -1);
    }
  }

  &__burger {
    width: config.$wrapper-padding;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include config.md {
      display: none;
    }
  }

  &__spinner {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      color: config.$green-dark;
    }

    span {
      font-size: 12px;
      margin-left: 4px;
    }
  }

  &__options {
    grid-area: topnav;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: flex-end;
  }
}
</style>
