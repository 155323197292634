<template>
  <div class="companyLogo">
    <img :src="src" :alt="alt" class="companyLogo__img"/>
    <div class="companyLogo__deleteable" @click="onDelete">
      <font-awesome-icon icon="times"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    headline: [String, Number]
  },
  emits: ['delete'],
  methods: {
    onDelete() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Vil du slette logo?')) {
        this.$emit('delete');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.companyLogo {
  position: relative;
  cursor: move;
  overflow: visible;
  //background: white;
  width: 100px;
  height: 75px;
  user-select: none;

  @include config.not_last {
    margin-bottom: 5px;
  }

  &__img {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
  }

  &__deleteable {
    position: absolute;
    top: -5px;
    right: -5px;
    background: config.$brown;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    font-size: 9px;
    color: config.$white;
  }
}
</style>
