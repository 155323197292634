<template>
  <div class="info" :class="`info--${type}`">
    <div class="intro__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'success'
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.info {
  font-size: 14px;
  position: relative;
  padding: 10px 0 10px 20px;

  &::before {
    content: '';
    display: block;
    width: 10px;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }

  &--success::before {
    background-color: config.$green-dark;
  }

  &--danger::before {
    background-color: config.$brown-dark;
  }
}
</style>
