<template>
  <HeroLoader :empty="!forumPost" :loading="isLoading" :key="$route.fullPath">
    <div class="area-content postWrapper" v-if="forumPost">
      <div style="width: 100%;">
        <a class="back" href="#" @click.prevent="goBack">Tilbage til forum</a>
      </div>
      <Box class="post" hover :label="forumPost.name">
        <div class="box-body">
          <h2 class="post__title">{{ forumPost.title }}</h2>
          <div class="post__author">
            <p class="post__author-name">Af {{ forumPost.user.full_name }} |
              {{ forumPost?.company?.name }}</p>
            <p class="post__author-date">{{ forumPost.updatedAt }}</p>
            <p class="post__author-count">
              <CommentCount :count="forumPost.totalComments" />
            </p>
          </div>
          <div class="post__hr"/>
          <div class="post__body" v-html="forumPost.body"/>
          <div class="mt-2">
            <LikeCounter
              :type="modelType"
              :id="forumPost.id"
              :count="forumPost.likeCounter"
              :liked="forumPost.liked"
              @liked="onLike"
            />
          </div>
          <div id="comments" class="post__stripe"></div>
          <Comment
            :commentable-type="modelType"
            :commentable-id="forumPost.id"
            @created="fetchComments"
          />

          <ul class="comments" v-if="comments.length > 0">
            <Comments
              v-for="comment in comments"
              :key="comment.id"
              :comment="comment"
              :commentable="forumPost"
              :comments="comment.comments"
              :active-comment-id="activeCommentId"
              :fetch-comments="fetchComments"
              :toggle-form="onSetActiveComment"
              :depth="0"
              :commentable-type="modelType"
            />
          </ul>

          <p v-if="comments.length === 0" class="commentsEmpty">
            Der er endnu ingen kommentarer til dette indlæg.
          </p>
        </div>
      </Box>
    </div>
  </HeroLoader>
</template>

<script>
import useGlobalLoader from '@/composables/useGlobalLoader';
import HeroLoader from '@/components/HeroLoader.vue';
import Message from '@/helpers/Message';
import ForumService from '@/services/ForumService';
import CommentService from '@/services/CommentService';
import useAuth from '@/composables/useAuth';
import Modules from '@/helpers/Modules';
import Box from '@/components/Box.vue';
import Comment from '@/views/Comments/Comment.vue';
import Comments from '@/views/Comments/Comments.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import CommentCount from '@/components/CommentCount.vue';

export default {
  name: 'CompanyView.vue',
  components: {
    Comments,
    Comment,
    Box,
    HeroLoader,
    LikeCounter,
    CommentCount
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      forumPost: null,
      activeCommentId: -1,
      comments: [],
      loadingComments: true,
      allowGoBack: false,
      modelType: 'forum-post'
    };
  },
  computed: {
    showCompanyField() {
      return this.hasSubscription(Modules.COMPANY);
    },
    companyLink() {
      if (!this.showCompanyField) {
        return '';
      }
      return {
        name: 'companies.show',
        params: { id: this.forumPost?.company?.id }
      };
    }
  },
  setup() {
    const {
      startLoading,
      stopLoading,
      startPageLoading,
      stopPageLoading,
      isLoading,
      isLoadingPage
    } = useGlobalLoader();

    const { hasSubscription } = useAuth();

    return {
      startLoading,
      stopLoading,
      isLoading,
      startPageLoading,
      stopPageLoading,
      isLoadingPage,
      hasSubscription
    };
  },
  created() {
    this.startPageLoading();
  },
  async mounted() {
    await this.initLoad();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from?.name === 'forum') {
        // eslint-disable-next-line
        vm.allowGoBack = true;
      }
    });
  },
  methods: {
    onLike(data) {
      this.forumPost.likeCounter = data.count;
      this.forumPost.liked = data.liked;
    },
    onSetActiveComment(id) {
      if (this.activeCommentId === id) {
        this.activeCommentId = -1;
        return;
      }
      this.activeCommentId = id;
    },
    async initLoad() {
      this.startLoading('Henter forumPost');
      await this.fetchForumPost();
      await this.fetchComments();
      this.stopPageLoading();
    },
    async fetchComments() {
      try {
        const payload = await CommentService.all(this.modelType, this.id);
        this.comments = payload?.data?.data;
      } catch (e) {
        Message.httpError(e);
      }
    },
    async goBack() {
      if (this.allowGoBack) {
        await this.$router.go(-1);
        return;
      }

      await this.$router.push({ name: 'forum' });
    },
    async fetchForumPost() {
      try {
        const payload = await ForumService.show({
          id: this.id,
          relations: ['comments.user', 'comments.company', 'user', 'company']
        });
        this.forumPost = payload?.data?.data;
        this.stopLoading();
      } catch (e) {
        Message.httpError(e);
      }
    }
  },
  watch: {
    async $route() {
      this.forumPost = null;
      await this.$nextTick(async () => {
        await this.initLoad();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;
@use "~@/styles/components/_comments.scss";

.postWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .back {
    display: inline-block;
    margin: 15px 0;
    font-size: 0.9rem;
    opacity: 0.6;
  }

  > * {
    max-width: 780px;
  }
}

.post {
  position: relative;
  font-size: 16px;
  width: 100%;

  &__title {
    margin-bottom: 5px;
  }

  &__hr {
    margin: 1rem 0;
    height: 1px;
    background-color: config.$grey;
  }

  &__stripe {
    margin: 2rem 0 0.5rem;
    border-top: 0.15rem dotted config.$grey;
    width: 100%;
  }

  &__author {
    font-size: 0.9em;
    color: #8C8C8C;
    display: grid;
    font-family: config.$font-headline;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    @include config.lg {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    &-name {
      grid-area: 1 / 1 / 2 / 2;
    }

    &-date {
      grid-area: 2 / 1 / 3 / 3;

      @include config.lg {
        grid-area: 1 / 2 / 2 / 3;
        text-align: center;
      }
    }

    &-count {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__body {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    content: '';

    &.post__body--shadow::after {
      content: '';
      width: 100%;
      height: 5ch;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(0, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))
    }
  }

  .box-body {
    @include config.lg {
      padding: 1.5rem 2rem;
    }
  }
}
</style>
