<template>
  <LoginLayout class="login">
    <form class="login__form" @submit.prevent="onSubmit">
      <BaseField label="E-mail">
        <BaseInput v-model="form.email"
                   placeholder="mail@eksempel.dk"
                   icon="user-alt"
                   name="email"
                   :loading="loading"
        />
      </BaseField>
      <BaseField label="Adgangskode" ref="password">
        <BaseInput v-model="form.password"
                   placeholder="********"
                   type="password"
                   name="password"
                   icon="key"
                   :loading="loading"
        />
      </BaseField>
      <BaseButton :label="submitButtonText"
                  :loading="loading"
                  full :disabled="loading"
      />
    </form>
    <div>
      <a href="#"
         v-if="showPasswordField"
         @click.prevent="onGoToForgotPasswordView"
      >
        Glemt kodeord
      </a>
      <a href="#"
         v-if="!showPasswordField"
         @click.prevent="onGoToLoginView"
      >
        Tilbage til login
      </a>
    </div>
  </LoginLayout>
</template>

<script>
import { slideDown, slideUp } from '@/helpers/Animations';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import BaseButton from '@/components/BaseButton.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import AuthService from '@/services/AuthService';
import useAuth from '@/composables/useAuth';

export default {
  components: {
    LoginLayout,
    BaseInput,
    BaseField,
    BaseButton
  },
  props: {
    email: String
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      passwordOriginalHeight: null,
      showPasswordField: true
    };
  },
  computed: {
    submitButtonText() {
      return this.showPasswordField ? 'Login' : 'Nulstil kodeord';
    }
  },
  setup() {
    const {
      sendResetPasswordLink,
      login,
      loading,
      isAuthenticated
    } = useAuth();
    return {
      sendResetPasswordLink,
      login,
      isAuthenticated,
      loading
    };
  },
  methods: {
    async onSubmit() {
      if (this.showPasswordField) {
        const resultLogin = await this.login({
          email: this.form.email,
          password: this.form.password
        });
        if (resultLogin) {
          if (this.$router.currentRoute.value.query.redirect) {
            await this.$router.push(this.$router.currentRoute.value.query.redirect);
          } else {
            await this.$router.push({ name: 'companies' });
          }
        }
        return;
      }

      const resultResetLink = await this.sendResetPasswordLink({ email: this.form.email });
      if (resultResetLink) {
        this.form.email = '';
        this.form.password = '';
        this.onGoToLoginView();
      }
    },
    onGoToForgotPasswordView() {
      if (this.loading) {
        return;
      }
      this.showPasswordField = false;
      slideUp({
        targets: this.$refs.password.$el
      });
    },
    onGoToLoginView() {
      if (this.loading) {
        return;
      }
      this.showPasswordField = true;
      slideDown({
        targets: this.$refs.password.$el,
        height: this.passwordOriginalHeight
      });
    },
    detectPasswordHeight() {
      this.passwordOriginalHeight = this.$refs.password.$el.getBoundingClientRect().height;
    }
  },
  async mounted() {
    this.detectPasswordHeight();
    await AuthService.cookieSetup();
  },
  created() {
    if (this.email) {
      this.form.email = this.email;
    }
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles/config/_index.scss" as config;

.field {
  overflow: hidden;
}

.login {
  &__form {
    margin-bottom: 10px;
  }
}

a {
  color: config.$green-dark;
}
</style>
